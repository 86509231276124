<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();

const emit = defineEmits(["close-modal"]);

const addParams = ref<Record<string, any>>({
	fullname: "",
	phone: "",
	email: "",
	password: "",
});

const canSubmit = computed(() => {
	return (
		addParams.value.fullname !== "" &&
		addParams.value.phone !== "" &&
		addParams.value.email !== "" &&
		addParams.value.password !== ""
	);
});

const handleCreateAdmin = async () => {
	await apiStore.callPostAdmin(addParams.value);
	emit("close-modal");
};
</script>
<template>
	<div class="add-admin-wrapper">
		<div class="inputs-wrap">
			<CustomInput
				type="text"
				label="Ime i prezime *"
				placeholder="Unesite ime i prezime"
				:input-value="addParams.fullname"
				@update:input-value="addParams.fullname = $event"
			/>
			<CustomInput
				type="text"
				label="Kontakt telefon *"
				placeholder="Unesite kontakt telefon"
				:input-value="addParams.phone"
				@update:input-value="addParams.phone = $event"
			/>
			<CustomInput
				type="text"
				label="Email *"
				placeholder="Unesite email"
				:input-value="addParams.email"
				@update:input-value="addParams.email = $event"
			/>
			<CustomInput
				type="text"
				label="Lozinka *"
				placeholder="Unesite lozinku"
				:input-value="addParams.password"
				@update:input-value="addParams.password = $event"
			/>
		</div>
		<div class="btns-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				:disabled="!canSubmit"
				small
				@btn-click="handleCreateAdmin"
			/>
			<CustomButton
				text="Odustani"
				:icon="images.closeImg"
				small
				@btn-click="$emit('close-modal')"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.add-admin-wrapper {
	margin-top: 30px;
	display: flex;

	.inputs-wrap {
		width: 300px;
		display: flex;
		flex-direction: column;
		gap: 10px 0;
		margin-right: 50px;
	}

	.btns-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px 0;
	}
}
</style>
