<script setup lang="ts">
import images from "@/assets/js/images";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import locale_rs from "dayjs/locale/sr";
import router from "@/router";
import { useDefaultStore } from "@/store";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(locale_rs);

const store = useDefaultStore();

const props = defineProps<{
	data: any;
}>();

const returnTitle = computed(() => {
	return props.data.mst_id === 2 ? "Nova rezervacija" : "Promena termina";
});

const returnBody = computed(() => {
	let body = "";
	switch (props.data.mst_id) {
		case 2: {
			body = `${props.data.ordref_ord_fullname}, opština ${props.data.arearef_area_name}, je rezervisao/la uslugu “${props.data.svcref_svc_name}”, ID rezervacije ${props.data.ordref_ord_refid}.`;

			break;
		}
		case 3: {
			body = `Dodeljen vam je pacijent ${
				props.data.ordref_ord_fullname
			} za ${dayjs(props.data.ordref_approved_avl_date).format("DD.MM.YYYY.")}`;

			break;
		}
		case 4: {
			body = `Uklonjen je pacijent ${props.data.ordref_ord_fullname} za ${dayjs(
				props.data.ordref_approved_avl_date,
			).format("DD.MM.YYYY.")}`;

			break;
		}
		// No default
	}
	return body;
});

const returnDate = computed(() => {
	return dayjs
		.utc(props.data.ordref_ord_utccreated)
		.tz("Europe/Belgrade")
		.format("HH:mm[h] DD.MM.YYYY");
});

const handleClick = () => {
	// if (props.isRead) return;
	// this should patch itself
	// and the route to the page based on the ref svc id
	if (store.getPermissions["can-use-admin-panel"]) {
		switch (props.data.svcref_svc_id) {
			case 1: {
				void router.push("/schedule");
				break;
			}
			case 2: {
				void router.push("/packages");
				break;
			}
			case 3: {
				void router.push("/packages");
				break;
			}

			case 4: {
				void router.push("/at-home-services");
				break;
			}
		}
	} else {
		void router.push("/technician-schedule");
	}
	console.log("open");
};

const handleDelete = () => {
	// no clue what this is supposed to do
	console.log("close");
};
</script>
<template>
	<div
		class="system-notification-card-wrapper"
		:class="{ 'is-read': data.umg_utcseen }"
		@click="handleClick"
	>
		<div class="header-wrap">
			<div class="title">
				{{ returnTitle }}
			</div>
			<div v-if="false" class="close" @click.stop="handleDelete">
				<img :src="images.deleteAction" alt="" />
			</div>
		</div>
		<div class="content-wrap">
			<div class="content">
				{{ returnBody }}
			</div>
			<div class="date-time">
				{{ returnDate }}
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.system-notification-card-wrapper {
	width: 100%;
	height: 80px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 10px;
	background: #fff;
	box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
	margin-bottom: 15px;
	cursor: pointer;

	.header-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.title {
			font-size: 15px;
			line-height: 20px;
			font-weight: 500;
			color: $primary-text;
		}

		.close {
			cursor: pointer;
		}
	}

	.content-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.content {
			font-size: 13px;
			font-weight: 500;
			color: $primary-text;
			opacity: 0.5;
		}

		.date-time {
			min-width: 100px;
			font-size: 11px;
			color: $primary-text;
			font-weight: 500;
		}
	}

	&.is-read {
		background: #ed203833;
		cursor: initial;
	}
}
</style>
