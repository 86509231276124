<script setup lang="ts">
import images from "@/assets/js/images";
import type { TLooseObject, TTableUsers } from "@/assets/js/types";
import { type TTableColumn } from "@/components/Shared/modernTable.vue";
import {
	openAddAdminModal,
	openAlertModal,
	openEditAdminModal,
} from "@/components/modals/modalDefinitions";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();

const currentPage = ref<number>(1);
const usersData = ref<TTableUsers[]>([]);
const filteredUsers = ref<TTableUsers[]>([]);
const searchInput = ref<string>("");
const tableColumns = ref<TTableColumn[]>([
	{
		id: "name",
		label: "Ime i prezime",
	},
	{
		id: "phone",
		label: "Kontakt telefon",
	},
	{
		id: "email",
		label: "Email",
	},
	{
		id: "actions",
		label: "",
	},
]);
const base = 20;
// const modalSearchValue = ref<string>("");

watch(searchInput, async () => {
	const params = {
		search: searchInput.value,
	};
	const response = await apiStore.callGetAdmins(params);
	filteredUsers.value = response;
});

onMounted(async () => {
	await getUsers();
	// openEditAdminModal();
	// openAddAdminModal();
	// console.log(
	// 	openAlertModal(
	// 		{
	// 			title: "Brisanje admina",
	// 			text: "Da li želite da obrišete izabranog admina?",
	// 			action: "block",
	// 		},
	// 		doSomething,
	// 	),
	// );
});

const cutFilteredItems = computed(() => {
	return filteredUsers.value.slice(
		base * (currentPage.value - 1),
		base * currentPage.value,
	);
});

const handleEdit = (user: TLooseObject) => {
	openEditAdminModal(
		{
			id: user.id,
			fullname: user.name,
			phone: user.phone,
			email: user.email,
		},
		getUsers,
	);
};

const handleDelete = (id: number) => {
	openAlertModal(
		{
			title: "Brisanje admina",
			text: "Da li želite da obrišete izabranog admina?",
			action: "delete",
		},
		async () => {
			await apiStore.callDeleteUser(id);
			await getUsers();
		},
	);
};

const handleCreateAdmin = () => {
	openAddAdminModal(getUsers);
};

const getUsers = async () => {
	const response = await apiStore.callGetAdmins();

	usersData.value = response;
	filteredUsers.value = response;
};

const changePage = (evt: any) => {
	currentPage.value = evt;
};
</script>
<template>
	<div class="admin-wrapper">
		<div class="inputs-wrap">
			<CustomInput
				type="search"
				placeholder="Pretraga"
				:input-value="searchInput"
				@input="searchInput = $event.target.value"
				@update:input-value="searchInput = $event"
			/>
			<CustomButton
				:icon="images.sendImg"
				text="Dodaj"
				@btn-click="handleCreateAdmin"
			/>
		</div>
		<div class="table-wrap">
			<ModernTable
				:columns="tableColumns"
				:rows="cutFilteredItems"
				:is-infinite-scroll="false"
				:has-border="false"
			>
				<template #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'actions'">
						<div class="actions-wrap">
							<div class="action" @click="handleEdit(slotProps.row)">
								<img :src="images.editAction" alt="edit" />
							</div>
							<div class="action" @click="handleDelete(slotProps.row.id)">
								<img :src="images.deleteAction" alt="delete" />
							</div>
						</div>
					</td>
				</template>
			</ModernTable>
		</div>
		<PaginationComp
			:page="currentPage"
			:pages="Math.ceil(filteredUsers.length / base)"
			prev-text="Nazad"
			next-text="Dalje"
			@update:page="changePage"
		/>
	</div>
</template>
<style scoped lang="scss">
.admin-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.inputs-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		:deep(.button-wrapper) {
			min-width: 8%;
		}

		:deep(.input-wrapper) {
			min-width: 300px;
			width: 300px;

			&:first-child {
				margin-right: 20px;
			}
		}
	}

	.table-wrap {
		width: 100%;
		height: calc(100% - 90px);
		padding-top: 30px;
		margin-bottom: 20px;

		.actions-wrap {
			width: 15px;
			display: flex;
			gap: 15px;

			.action {
				cursor: pointer;
			}
		}
	}

	:deep(.pagination-wrapper) {
		width: 100%;
		justify-content: center;
	}
}
</style>
