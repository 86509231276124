<script setup lang="ts">
import { debounce } from "@/assets/js/helpers";
import images from "@/assets/js/images";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";

const store = useDefaultStore();
const apiStore = useApiStore();
const router = useRouter();

defineProps<{
	pageTitle: string;
	showLogo: boolean;
	hasUnread?: boolean;
}>();

const handleLogout = async () => {
	await apiStore.callLogout();
	// FIXME - idk what the problem is ngl
	// @ts-expect-error
	void router.push({ name: "LoginPage" });
};

const handleNotifications = () => {
	void router.push("/system-notifications");
};

const handleLogoClick = () => {
	void router.push("/technician-schedule");
};
</script>
<template>
	<div class="header-wrapper">
		<div class="left-wrap">
			<div v-if="showLogo" class="page-logo" @click="handleLogoClick">
				<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
			</div>
			<div class="page-title">
				{{ pageTitle }}
			</div>
		</div>
		<div class="name-n-settings">
			<div
				v-if="!store.getPermissions['can-use-admin-panel']"
				class="notifications-wrap"
				@click="handleNotifications"
			>
				<svg
					width="34px"
					height="34px"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g
						id="SVGRepo_tracerCarrier"
						stroke-linecap="round"
						stroke-linejoin="round"
					></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M8.35179 20.2418C9.19288 21.311 10.5142 22 12 22C13.4858 22 14.8071 21.311 15.6482 20.2418C13.2264 20.57 10.7736 20.57 8.35179 20.2418Z"
							fill="#172841"
						></path>
						<path
							d="M18.7491 9V9.7041C18.7491 10.5491 18.9903 11.3752 19.4422 12.0782L20.5496 13.8012C21.5612 15.3749 20.789 17.5139 19.0296 18.0116C14.4273 19.3134 9.57274 19.3134 4.97036 18.0116C3.21105 17.5139 2.43882 15.3749 3.45036 13.8012L4.5578 12.0782C5.00972 11.3752 5.25087 10.5491 5.25087 9.7041V9C5.25087 5.13401 8.27256 2 12 2C15.7274 2 18.7491 5.13401 18.7491 9Z"
							fill="#172841"
						></path>
					</g>
				</svg>
				<div v-if="hasUnread" class="dot"></div>
			</div>
			<div class="name-wrap">
				<span>{{ store.getUser.fullname }}</span>
			</div>
			<div class="logout-wrap">
				<CustomButton
					text="Odjava"
					:icon="images.logoutImg"
					@btn-click="handleLogout"
				/>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.header-wrapper {
	width: 100%;
	height: 110px;
	min-height: 110px;
	background: #f8f7f7;
	box-shadow: 0 1px 3px 0 #dfdfe8;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 68px 0 27px;

	.left-wrap {
		display: flex;
		align-items: center;

		.page-logo {
			margin-right: 50px;
			width: 140px;
			cursor: pointer;

			img {
				width: 100%;
			}
		}

		.page-title {
			color: $alt-dark;
			font-size: 16px;
			font-weight: 700;
		}
	}

	.name-n-settings {
		display: flex;
		align-items: center;

		.notifications-wrap {
			position: relative;
			margin-right: 22px;
			cursor: pointer;

			.dot {
				position: absolute;
				width: 14px;
				height: 14px;
				background: red;
				border-radius: 50%;
				top: 0;
				right: 0;
			}
		}

		.name-wrap {
			color: $primary-text;
			font-size: 18px;
			font-weight: 500;
			margin-right: 22px;
		}

		.logout-wrap {
			:deep(.button-wrapper) {
				min-width: 8%;
			}
		}
	}
}
</style>
