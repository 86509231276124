<script setup lang="ts">
import images from "@/assets/js/images";
import type { TNameIDPair } from "@/assets/js/types";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import { useToast } from "vue-toastification";

const apiStore = useApiStore();
const store = useDefaultStore();

const props = withDefaults(
	defineProps<{
		id: number;
		fullname: string;
		phone: string;
		email: string;
		location: TNameIDPair;
	}>(),
	{
		fullname: "",
		phone: "",
		email: "",
	},
);
const emit = defineEmits(["close-modal"]);

const selectedLocation = ref<TNameIDPair>(props.location);
const editParams = ref<Record<string, any>>({
	fullname: "",
	phone: "",
	email: "",
	location: 0,
});

const handleEdit = async () => {
	if (
		(editParams.value.fullname === "" && props.fullname === "") ||
		(editParams.value.email === "" && props.email === "") ||
		(editParams.value.phone === "" && props.phone === "") ||
		(selectedLocation.value.id === 0 && props.location.id === 0)
	) {
		useToastStore().openToastError("Morate popuniti sva obavezna polja!");
		return;
	}
	// for (const key in editParams.value) {
	// 	if (editParams.value[key] === "") {
	// 		editParams.value[key] = props[key as keyof typeof props];
	// 	}
	// }
	editParams.value.location = selectedLocation.value.id;

	await apiStore.callPatchUser(props.id, editParams.value);
	console.log(editParams.value);

	emit("close-modal");
};
</script>
<template>
	<div class="edit-technician-wrapper">
		<div class="inputs-wrap">
			<CustomInput
				type="text"
				label="Ime i prezime *"
				placeholder="Unesite ime i prezime"
				:input-value="fullname"
				@update:input-value="editParams.fullname = $event"
			/>
			<CustomInput
				type="text"
				label="Kontakt telefon *"
				placeholder="Unesite kontakt telefon *"
				:input-value="phone"
				@update:input-value="editParams.phone = $event"
			/>
			<CustomInput
				type="text"
				label="Email *"
				placeholder="Unesite email"
				:input-value="email"
				@update:input-value="editParams.email = $event"
			/>
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Lokacija *</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedLocation"
						:label="'label'"
						:placeholder="'Odaberite lokaciju *'"
						:options="store.getTechnicianLocations"
						:value-prop="'id'"
					/>
				</div>
			</div>
		</div>
		<div class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				small
				@btn-click="handleEdit"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-technician-wrapper {
	width: 286px;

	.inputs-wrap {
		display: flex;
		flex-direction: column;
		margin: 30px 0;
		gap: 10px 0;

		.dropdown-wrap {
			width: 100%;
		}
	}

	.btn-wrap {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
