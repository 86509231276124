<script setup lang="ts">
import type { TLooseObject, TNameIDPair } from "@/assets/js/types";
import images from "@/assets/js/images";
import type { TTableColumn } from "@/components/Shared/modernTable.vue";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import {
	openAlertModal,
	openEditUserModal,
} from "@/components/modals/modalDefinitions";

const store = useDefaultStore();
const apiStore = useApiStore();

const currentPage = ref<number>(1);
const searchInput = ref<string>("");
const usersData = ref<TLooseObject[]>([]);
const filteredUsers = ref<TLooseObject[]>([]);
const tableColumns = ref<TTableColumn[]>([
	{
		id: "fullname",
		label: "Ime i prezime",
	},
	{
		id: "phone",
		label: "Kontakt telefon",
	},
	{
		id: "dob",
		label: "Datum rođenja",
	},
	{
		id: "genda",
		label: "Pol",
	},
	{
		id: "email",
		label: "Email",
	},
	{
		id: "address",
		label: "Adresa",
	},
	{
		id: "area",
		label: "Opština",
	},
	{
		id: "status",
		label: "Status",
	},
	{
		id: "actions",
		label: "",
	},
]);
const areaInput = ref<TNameIDPair>({ id: 0, label: "Sve opštine" });
const gendaInput = ref<TNameIDPair>({ id: 0, label: "Svi" });
const yearInput = ref<TNameIDPair>({ label: "Sve godine", id: 0 });
const statusInput = ref<TNameIDPair>({ id: 0, label: "Svi" });
const yearOptions = ref<TNameIDPair[]>([]);
const base = 20;

// FIXME - this varaiable is used to prevent the loader edge case
// the loader stopLoading() function is called from this component
// after the API calls of this component are done thus
// stopping the loader before the API calls of the other components are done
// this "hack" solves it by checking if this component is still mounted
// before stopping the loader
const hack = ref<boolean>(false);

onMounted(async () => {
	hack.value = true;
	await getUsers();
	// openEditUserModal({
	// 	name: "Petar",
	// 	phone: "0641234567",
	// 	dob: "1990-01-01",
	// 	genda: "Muški",
	// 	email: "nigga@please.com",
	// 	address: "Knowhere, Ohio",
	// 	area: "Vračar",
	// });
	setYearOptions();
});

onBeforeUnmount(() => {
	hack.value = false;
});

watch(
	[areaInput, gendaInput, yearInput, statusInput, searchInput],
	async () => {
		const params: TLooseObject = {};
		if (areaInput.value.id !== 0) params.area = areaInput.value.id;
		if (gendaInput.value.id !== 0) params.gender = gendaInput.value.id;
		if (yearInput.value.id !== 0) params.birthyear = yearInput.value.id;
		if (statusInput.value.id !== 0) params.userstatus = statusInput.value.id;
		if (searchInput.value !== "") params.search = searchInput.value;
		filteredUsers.value = await apiStore.callGetPatients(
			params,
			!(statusInput.value.id === 3),
		);
		formatUsers();
	},
);

const cutFilteredItems = computed(() => {
	return filteredUsers.value.slice(
		base * (currentPage.value - 1),
		base * currentPage.value,
	);
});

const handleTableAction = (
	action: "block" | "delete" | "hold" | "edit",
	ev: number,
) => {
	const userStatus = usersData.value.find((user) => user.id === ev)?.userstatus;
	switch (action) {
		case "block": {
			if (userStatus === 3) {
				openAlertModal(
					{
						title: "Aktivirajte korisnika",
						text: "Da li želite da aktivirate izabranog korisnika?",
						action: "play",
					},
					handleRestoreUser,
					ev,
				);
			} else {
				openAlertModal(
					{
						title: "Blokiranje korisnika",
						text: "Da li želite da blokirate izabranog korisnika?",
						action: "block",
					},
					handleBlockUser,
					ev,
				);
			}
			break;
		}
		case "delete": {
			openAlertModal(
				{
					title: "Brisanje korisnika",
					text: "Da li želite da obrišete izabranog korisnika?",
					action: "delete",
				},
				handleDeleteUser,
				ev,
			);
			break;
		}
		case "hold": {
			if (userStatus === 2) {
				openAlertModal(
					{
						title: "Aktivirajte korisnika",
						text: "Da li želite da aktivirate izabranog korisnika?",
						action: "play",
					},
					handleRestoreUser,
					ev,
				);
			} else {
				openAlertModal(
					{
						title: "Pauziranje korisnika",
						text: "Da li želite da pauzirate korisnički nalog?",
						action: "pause",
					},
					handlePauseUser,
					ev,
				);
			}
			break;
		}
		case "edit": {
			const selectedUser = usersData.value.find((user) => user.id === ev);
			console.log(selectedUser);

			if (!selectedUser) return;

			const propsObj: TLooseObject = {
				id: ev,
				name: selectedUser.fullname,
				phone: selectedUser.phone,
				dob: selectedUser.dob,
				genda: selectedUser.gender,
				email: selectedUser.email,
				address: selectedUser.address,
			};

			console.log(propsObj);

			if (selectedUser.area) {
				propsObj.area = store.getAreas.find(
					(area) => area.label === selectedUser.area,
				)?.id;
			}

			openEditUserModal(propsObj, getUsers);
		}
	}
};

const handleRestoreUser = async (id: number) => {
	await apiStore.callRestoreUser(id);
	await getUsers();
};

const handleDeleteUser = async (id: number) => {
	await apiStore.callDeleteUser(id);
	await getUsers();
};

const handleBlockUser = async (id: number) => {
	await apiStore.callBlockUser(id);
	await getUsers();
};

const handlePauseUser = async (id: number) => {
	await apiStore.callPauseUser(id);
	await getUsers();
};

const getUsers = async () => {
	store.startLoading();
	const params: TLooseObject = {};
	if (areaInput.value.id !== 0) params.area = areaInput.value.id;
	if (gendaInput.value.id !== 0) params.gender = gendaInput.value.id;
	if (yearInput.value.id !== 0) params.birthyear = yearInput.value.id;
	if (statusInput.value.id !== 0) params.userstatus = statusInput.value.id;
	if (searchInput.value !== "") params.search = searchInput.value;
	const response = await apiStore.callGetPatients(
		params,
		!(statusInput.value.id === 3),
	);

	usersData.value = response;
	filteredUsers.value = usersData.value;
	// NOTE - PITATI ZEKSA ZA OVO
	// NOTE - mutira se sam response apija pre nego sto se dodelio?
	// before usersData.value = response;
	// before filteredUsers.value = response;

	formatUsers();
	if (hack.value) store.stopLoading();
};

const formatUsers = () => {
	for (const user of filteredUsers.value) {
		user.genda = store.getGenda.find((g) => g.id === user.gender)?.label;
		if (user.userstatus !== 1) {
			user._class = ["disabled"];
		}
	}
};

const setYearOptions = () => {
	const currentYear = new Date().getFullYear();
	const years = [{ label: "Sve godine", id: 0 }];
	for (let i = currentYear; i >= 1900; i--) {
		years.push({ label: i.toString(), id: i });
	}
	yearOptions.value = years;
};

const changePage = (evt: any) => {
	currentPage.value = evt;
};
</script>
<template>
	<LoaderComp v-if="store.getIsLoading" />
	<div v-else class="users-wrapper">
		<div class="inputs-wrap">
			<div class="left-selects">
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Opština</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="areaInput"
							label="label"
							:placeholder="'Opština'"
							:options="[{ id: 0, label: 'Sve opštine' }, ...store.getAreas]"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Pol</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="gendaInput"
							label="label"
							placeholder="Pol"
							:options="[{ id: 0, label: 'Svi' }, ...store.getGenda]"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<!-- @option-selected="optionSelected" -->
				<div class="dropdown-wrap wider">
					<div class="dropdown-label">
						<span>Godina rođenja</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="yearInput"
							label="label"
							placeholder="Godina rođenja"
							:options="yearOptions"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<!-- Input za datum rodjenja smisliti nekako -->
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Status</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="statusInput"
							label="label"
							placeholder="Status"
							:options="[{ id: 0, label: 'Svi' }, ...store.getUserStatuses]"
							:value-prop="'id'"
						/>
					</div>
				</div>
			</div>
			<CustomInput
				type="search"
				label="Pretraga"
				placeholder="Pretraga"
				:input-value="searchInput"
				@update:input-value="searchInput = $event"
			/>
		</div>
		<div class="table-wrap">
			<ModernTable
				:columns="tableColumns"
				:rows="cutFilteredItems"
				:is-infinite-scroll="false"
				:has-border="false"
				:displayAdditionalRow="false"
			>
				<template #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'actions'">
						<div class="table-actions">
							<div
								class="action-btn"
								@click="handleTableAction('edit', slotProps.row.id)"
							>
								<img :src="images.editAction" alt="" />
							</div>
							<div
								class="action-btn"
								@click="handleTableAction('block', slotProps.row.id)"
							>
								<img
									:src="
										slotProps.row.userstatus === 3
											? images.playAction
											: images.blockAction
									"
									alt=""
								/>
							</div>
							<div
								class="action-btn"
								@click="handleTableAction('hold', slotProps.row.id)"
							>
								<img
									:src="
										slotProps.row.userstatus === 2
											? images.playAction
											: images.holdAction
									"
									alt=""
								/>
							</div>
							<div
								class="action-btn"
								@click="handleTableAction('delete', slotProps.row.id)"
							>
								<img :src="images.deleteAction" alt="" />
							</div>
						</div>
					</td>
					<td v-else-if="slotProps.column.id === 'status'">
						<div class="user-status">
							<img
								v-if="slotProps.row.userstatus === 1"
								:src="images.playAction"
								alt=""
							/>
							<img
								v-else-if="slotProps.row.userstatus === 2"
								:src="images.holdAction"
								alt=""
							/>
							<img
								v-else-if="slotProps.row.userstatus === 3"
								:src="images.blockAction"
								alt=""
							/>
						</div>
					</td>
				</template>
			</ModernTable>
		</div>
		<PaginationComp
			:page="currentPage"
			:pages="Math.ceil(filteredUsers.length / base)"
			prev-text="Nazad"
			next-text="Dalje"
			@update:page="changePage"
		/>
	</div>
</template>
<style scoped lang="scss">
.users-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.inputs-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.left-selects {
			display: flex;
			gap: 15px;
		}

		:deep(.input-wrapper) {
			min-width: 300px;
			width: 300px;

			&:last-child {
				margin-left: 20px;
			}
		}
	}

	.table-wrap {
		width: 100%;
		height: calc(100% - 100px);
		padding-top: 30px;
		margin-bottom: 20px;

		.table-actions {
			width: 56px;
			display: flex;
			gap: 15px;

			.action-btn {
				cursor: pointer;
			}
		}

		.user-status {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	:deep(.pagination-wrapper) {
		width: 100%;
		justify-content: center;
	}
}
</style>
