<script setup lang="ts">
import images from "@/assets/js/images";
import { type TItem, type TNameIDPair } from "@/assets/js/types";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";

const props = defineProps<{
	listData: TItem[];
	name?: string;
	price?: number;
	description?: string;
	preSelectedGroup?: TNameIDPair;
	itemID?: number;
	preIsEditing?: boolean;
}>();
const emit = defineEmits(["close-modal"]);

const store = useDefaultStore();
const apiStore = useApiStore();

const isAdding = ref<boolean>(true);
const isEditing = ref<boolean>(props.preIsEditing || false);
// FIXME - fix type
const selectedGroup = ref<TNameIDPair | undefined>(props.preSelectedGroup);
const dropdownOptions = ref<TNameIDPair[]>(store.getAllGroups);
const selectedItem = ref<TItem>({} as TItem);
const itemName = ref<string>(props.name || "");
const itemPrice = ref<number | string>("");
const itemDescription = ref<string>(props.description || "");

const hasItemSelected = computed(() => selectedItem.value !== undefined);
const hasFilledAllFields = computed(
	() => itemName.value && itemPrice.value && itemDescription.value,
);

onMounted(() => {
	if (props.itemID) {
		selectedItem.value = props.listData.find(
			(item: TItem) => item.id === props.itemID,
		) as TItem;
	}
	if (props.price) itemPrice.value = cleanPrice(String(props.price));
	if (props.preIsEditing) isAdding.value = false;
});

const handleListSelect = (item: TItem) => {
	selectedItem.value = item;
	itemName.value = item.name;
	itemPrice.value = cleanPrice(String(item.price));
	itemDescription.value = item.description;
};

const handleConfirm = async () => {
	if (isAdding.value) {
		await apiStore.callPostItem({
			service: 1,
			itemgroup: selectedGroup.value?.id,
			name: itemName.value,
			price: parseInt(itemPrice.value as string),
			description: itemDescription.value,
		});
		emit("close-modal");
	} else if (isEditing.value) {
		if (props.itemID === undefined) return;
		await apiStore.callPatchItemActive(props.itemID, {
			name: itemName.value,
			price: itemPrice.value,
			description: itemDescription.value,
		});
		emit("close-modal");
	}
};

const handleDelete = () => {
	// TODO - delete item
	useToastStore().openToastError("Debug: Trenutno ne možete obrisati stavku.");
	emit("close-modal");
};

const cleanPrice = (formattedNumber: string) => {
	const clean = formattedNumber.replaceAll(".", "").replace(",", ".");
	return parseFloat(clean);
};
</script>
<template>
	<div class="edit-items-wrapper">
		<div v-if="false" class="left-wrap">
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Grupa</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedGroup"
						:label="'label'"
						:placeholder="'Odaberite grupu'"
						:options="dropdownOptions"
						:value-prop="'id'"
					/>
				</div>
			</div>
			<CustomModalList
				label-part="analize/pregledi"
				:data="listData"
				:pre-selected-item="selectedItem.id || 0"
				@select-item="handleListSelect"
			/>
		</div>
		<div class="mid-wrap">
			<!-- TODO - handle filling inputs -->
			<!-- by getting the info from the store -->
			<!-- bcs the list only returs a name id pair -->
			<div class="top-inputs">
				<CustomInput
					type="text"
					label="Naziv analize/pregleda"
					placeholder="Analiza/pregled"
					:disabled="!isAdding && !isEditing"
					:input-value="itemName"
					@update:input-value="itemName = $event"
				/>
				<CustomInput
					type="number"
					label="Cena"
					placeholder="Cena"
					:disabled="!isAdding && !isEditing"
					:input-value="itemPrice"
					@update:input-value="itemPrice = $event"
				/>
			</div>
			<div class="textarea-wrap">
				<CustomInput
					type="textarea"
					label="Opis"
					placeholder="Opis analize/pregleda"
					:disabled="!isAdding && !isEditing"
					:input-value="itemDescription"
					@update:input-value="itemDescription = $event"
				/>
			</div>
		</div>
		<div class="btn-wrap">
			<CustomButton
				v-if="!isAdding && !isEditing"
				text="Dodaj"
				:icon="images.addImg"
				@btn-click="isAdding = true"
			/>
			<CustomButton
				v-else
				text="Sačuvaj"
				:icon="images.confirmImg"
				:disabled="!hasFilledAllFields"
				@btn-click="handleConfirm"
			/>
			<!-- FIXME this might be a cook but looks gay idk -->
			<!-- same shit -->
			<!-- <CustomButton
				v-if="!isAdding && !isEditing"
				text="Promeni"
				:icon="images.editImg"
				:disabled="!hasItemSelected"
				@btn-click="isEditing = true"
			/> -->
			<!-- <CustomButton
				text="Obriši"
				:icon="images.deleteImg"
				:disabled="!hasItemSelected && !isAdding"
				@btn-click="handleDelete"
			/> -->
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-items-wrapper {
	display: flex;

	.left-wrap {
		width: 210px;
		display: flex;
		flex-direction: column;
		gap: 15px;

		&:deep(.dropdown-wrap) {
			width: 210px;
		}
	}

	.mid-wrap {
		width: 450px;
		margin: 0 50px 0 10px;

		.top-inputs {
			display: flex;
			gap: 20px;
			margin-bottom: 15px;

			&:deep(.input-wrapper):not(:last-child) {
				width: 300px;
			}

			&:deep(.input-wrapper):not(:first-child) {
				width: 130px;
			}
		}

		// .textarea-wrap {
		// }
	}

	.btn-wrap {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}
</style>
