<script setup lang="ts">
import images from "@/assets/js/images";
import { useDefaultStore } from "@/store";

defineProps<{
	active: string;
	hasUnread: boolean;
}>();

const store = useDefaultStore();
</script>
<template>
	<div class="sidebar-wrapper">
		<div class="logo-wrap">
			<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
		</div>
		<div class="tabs-wrap">
			<SingleTab
				title="Termini"
				:icon="images.scheduleBlack"
				:is-active="active === 'SchedulePage'"
				route-to="/schedule"
			/>
			<SingleTab
				title="Usluge"
				:icon="images.servicesBlack"
				:is-active="active === 'ServicesPage'"
				route-to="/services"
			/>
			<SingleTab
				title="Korisnici"
				:icon="images.usersBlack"
				:is-active="active === 'UsersPage'"
				route-to="/users"
			/>
			<SingleTab
				v-if="store.getPermissions['view-admins']"
				title="Admin"
				:icon="images.adminBlack"
				:is-active="active === 'AdminPage'"
				route-to="/admin"
			/>
			<SingleTab
				title="Tehničari"
				:icon="images.technitiansBlack"
				:is-active="active === 'TechniciansPage'"
				route-to="/technicians"
			/>
			<SingleTab
				v-if="true"
				title="Aktivnosti"
				:icon="images.activitiesBlack"
				:is-active="active === 'ActivitiesPage'"
				route-to="/activities"
			/>
			<SingleTab
				title="Izveštaji"
				:icon="images.reportsBlack"
				:is-active="active === 'ReportsPage'"
				route-to="/reports"
			/>
			<SingleTab
				title="Notifikacije"
				:icon="images.notificationsBlack"
				:is-active="active === 'NotificationsPage'"
				route-to="/notifications"
			/>
			<SingleTab
				title="Sistemske notifikacije"
				:icon="images.systemNotificationsBlack"
				:is-active="active === 'SystemNotificationsPage'"
				route-to="/system-notifications"
				:show-dot="hasUnread"
			/>
			<SingleTab
				title="Paketi"
				:icon="images.packagesBlack"
				:is-active="active === 'PackagesPage'"
				route-to="/packages"
			/>
			<SingleTab
				title="Pregledi u Vašem domu"
				:icon="images.atHomeBlack"
				:is-active="active === 'AtHomeServicesPage'"
				route-to="/at-home-services"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.sidebar-wrapper {
	width: 180px;
	min-width: 180px;
	height: 100vh;
	background: #f8f7f7;
	box-shadow: 0 1px 3px 0 #dfdfe8;
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo-wrap {
		margin-bottom: 30px;
		width: 135px;

		img {
			width: 100%;
		}
	}
}
</style>
