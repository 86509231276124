<script setup lang="ts">
import { returnLabelFromID } from "@/assets/js/helpers";
import images from "@/assets/js/images";
import type { TLooseObject } from "@/assets/js/types";
import { type TTableColumn } from "@/components/Shared/modernTable.vue";
import {
	openAddPromoModal,
	openAlertModal,
	openEdtiAtHomeModal,
	openGroupsModal,
	openItemsModal,
	openPromoModal,
} from "@/components/modals/modalDefinitions";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import { vOnClickOutside } from "@vueuse/components";
import dayjs from "dayjs";

const store = useDefaultStore();
const apiStore = useApiStore();

const searchInput = ref<string>("");
const selectedService = ref<Record<string, any>>({
	id: 1,
	label: "Laboratorijske usluge",
});
const selectedGroup = ref<Record<string, any>>({ id: 0, label: "Sve grupe" });
const originalItems = ref<TLooseObject[]>([]);
const filteredItems = ref<TLooseObject[]>([]);
const showOptions = ref<boolean>(false);
const showRowIndex = ref<number[]>([]);
const currentPage = ref<number>(1);
const base = 20;

const itemColumns = ref<TTableColumn[]>([
	{
		id: "isActive",
		label: "Aktivno",
	},
	{
		id: "itemgroup",
		label: "Grupa",
	},
	{
		id: "name",
		label: "Analiza/pregled",
	},
	{
		id: "price",
		label: "Cena",
	},
	{
		id: "description",
		label: "Opis",
	},
	{
		id: "actions",
		label: "",
	},
]);
const promoColumns = ref<TTableColumn[]>([
	{
		id: "name",
		label: "Naziv promocije",
	},
	{
		id: "price",
		label: "snižena CENA",
	},
	{
		id: "originalPrice",
		label: "prvobitna cena",
	},
	{
		id: "endDate",
		label: "TRAJANJE PROMOCIJE",
	},
	{
		id: "actions",
		label: "",
	},
	{
		id: "expand",
		label: "",
	},
]);
const couponColumns = ref<TTableColumn[]>([
	{
		id: "name",
		label: "Naziv paketa",
	},
	{
		id: "condition",
		label: "uslov",
	},
	{
		id: "price",
		label: "uslov",
	},
	{
		id: "originalPrice",
		label: "prvobitna cena",
	},
	{
		id: "endDate",
		label: "TRAJANJE PROMOCIJE",
	},
	{
		id: "actions",
		label: "",
	},
	{
		id: "expand",
		label: "",
	},
]);
const atHomeColumns = ref<TTableColumn[]>([
	{
		id: "name",
		label: "Naziv pregleda",
	},
	{
		id: "expand",
		label: "",
	},
]);

const cutFilteredItems = computed(() => {
	return filteredItems.value.slice(
		base * (currentPage.value - 1),
		base * currentPage.value,
	);
});

watch(selectedGroup, async () => {
	searchInput.value = "";
	const params: TLooseObject = {
		service: selectedService.value.id,
	};
	if (selectedService.value.id === 1 && selectedGroup.value.id !== 0) {
		params.itemgroup = selectedGroup.value.id;
		originalItems.value = await apiStore.callGetItems(params);
		formatGroupForDisplay();
	} else if (selectedService.value.id === 1 && selectedGroup.value.id === 0) {
		originalItems.value = await apiStore.callGetItems(params);
		formatGroupForDisplay();
	}
});

watch(selectedService, async () => {
	searchInput.value = "";
	originalItems.value = await apiStore.callGetItems({
		service: selectedService.value.id,
	});
	formatGroupForDisplay();
});

watch(searchInput, async () => {
	const params: TLooseObject = {
		service: selectedService.value.id,
		search: searchInput.value,
	};
	if (selectedGroup.value.id !== 0) params.itemgroup = selectedGroup.value.id;
	originalItems.value = await apiStore.callGetItems(params);
	formatGroupForDisplay();
});

watch(
	() => store.getAllGroups,
	async () => {
		await init();
	},
);

onMounted(async () => {
	await init();
});

const getTableColumns = computed(() => {
	switch (selectedService.value.id) {
		case 1: {
			return itemColumns.value;
		}
		case 2: {
			return promoColumns.value;
		}
		case 3: {
			return couponColumns.value;
		}
		case 4: {
			return atHomeColumns.value;
		}
		default: {
			return itemColumns.value;
		}
	}
});

const init = async () => {
	const params: TLooseObject = {
		service: selectedService.value.id,
	};
	if (selectedGroup.value.id !== 0) params.itemgroup = selectedGroup.value.id;
	originalItems.value = await apiStore.callGetItems(params);
	searchInput.value = "";
	// selectedGroup.value = { id: 0, label: "Sve grupe" };
	formatGroupForDisplay();
};

const openOptions = () => {
	switch (selectedService.value.id) {
		case 1: {
			showOptions.value = true;
			break;
		}
		case 2: {
			openPromoModal(
				{ service: selectedService.value.id },
				"Nova promocija",
				init,
			);
			break;
		}
		case 3: {
			openPromoModal({ service: selectedService.value.id }, "Novi paket", init);
			break;
		}
		default: {
			break;
		}
	}
};

const hideOptions = () => {
	showOptions.value = false;
};

// NOTE - item has to be any because of the table component
const handleEdit = (item: any) => {
	switch (selectedService.value.id) {
		case 1: {
			openItemsModal(
				{
					name: item.name,
					price: item.price,
					description: item.description,
					itemID: item.id,
					preIsEditing: true,
					listData: originalItems.value,
				},
				"Izmena analize/pregleda",
				init,
			);
			break;
		}
		case 2: {
			openPromoModal(
				{
					service: selectedService.value.id,
					id: item.id,
					nameProp: item.name,
					lowballProp: item.price,
					actualpriceProp: item.originalPrice,
					dateProp: item.endDate,
					descriptionsProp: item.items,
					isEditing: true,
				},
				"Izmena promocije",
				init,
			);
			break;
		}
		case 3: {
			openPromoModal(
				{
					service: selectedService.value.id,
					id: item.id,
					nameProp: item.name,
					lowballProp: item.price,
					actualpriceProp: item.originalPrice,
					dateProp: item.endDate,
					descriptionsProp: item.items,
					conditionProp: item.condition,
					isEditing: true,
				},
				"Izmena paketa",
				init,
			);
			break;
		}
		case 4: {
			console.log(item);

			openEdtiAtHomeModal(
				{
					id: item.id,
					name: item.name,
					price: item.price,
					description: item.description,
				},
				init,
			);
			break;
		}
		default: {
			break;
		}
	}
};

const handleDelete = (ev: any) => {
	// itemToDelete.value = ev;
	// isVisible.value = true;
	openAlertModal(
		{
			title: "Brisanje usluge",
			text: "Da li želite da obrišete izabranu uslugu?",
			action: "delete",
		},
		handleItemDelete,
		ev,
	);
};

const handleCreate = (type: string) => {
	switch (type) {
		case "group": {
			const tempGroups: any[] = [];
			for (const group of store.getAllGroups) {
				tempGroups.push({
					id: group.id,
					name: group.label,
				});
			}

			openGroupsModal(
				{
					listData: tempGroups,
				},
				refreshGroups,
			);
			break;
		}
		case "item": {
			openItemsModal(
				{
					preSelectedGroup: selectedGroup.value,
					listData: originalItems.value,
				},
				selectedService.value.label + "/" + selectedGroup.value.label,
				init,
			);
			break;
		}
		default: {
			break;
		}
	}
};

const serviceSelected = async () => {
	if (selectedService.value && selectedService.value.id !== 0) {
		await apiStore.callGetGroups({ service: selectedService.value.id });
		// await apiStore.callGetGroups({
		// 	service: returnIDFromLabel(selectedService.value.id, store.getServices),
		// });
	}
};

const handleToggleActive = async (item: any, ignoreFormatPrice?: boolean) => {
	// console.log(item.name);
	// console.log(item);

	await apiStore.callPatchItemActive(item.id, {
		name: item.name,
		price: ignoreFormatPrice ? item.price : cleanPrice(item.price),
		desciption: item.desciption,
		hidden: item.isActive,
	});
	await init();
};

const handleItemDelete = async (itemID: number) => {
	await apiStore.callDeleteItem(itemID);
	await init();
};

const formatPrice = (price: number) => {
	const formattedNumber = price.toLocaleString("de-DE", {
		style: "decimal",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	return formattedNumber;
};

const formatGroupForDisplay = () => {
	filteredItems.value = originalItems.value;
	switch (selectedService.value.id) {
		case 1: {
			for (const item of filteredItems.value) {
				// FIXME - this is retarded, find a better way
				item.price = formatPrice(parseInt(String(item.price)));
				item.itemgroup = returnLabelFromID(item.itemgroup, store.getAllGroups);
				if (!item.isActive) item._class = ["disabled"];
			}

			break;
		}
		case 2: {
			const tempArr: any[] = [];
			for (const item of filteredItems.value) {
				tempArr.push({
					id: item.id,
					name: item.name,
					price: formatPrice(parseInt(String(item.price))),
					originalPrice: formatPrice(parseInt(String(item.originalPrice))),
					endDate: item.endDate,
					items: item.items,
				});
			}

			filteredItems.value = tempArr;
			break;
		}
		case 3: {
			const tempArr: any[] = [];

			for (const item of filteredItems.value) {
				tempArr.push({
					id: item.id,
					name: item.name,
					condition: item.condition,
					price: formatPrice(parseInt(String(item.price))),
					originalPrice: formatPrice(parseInt(String(item.originalPrice))),
					endDate: dayjs(item.endDate).format("DD.MM.YYYY."),
					items: item.items,
				});
			}
			filteredItems.value = tempArr;
			break;
		}
		case 4: {
			const tempArr: any[] = [];
			for (const item of filteredItems.value) {
				const objToPush: TLooseObject = {
					id: item.id,
					name: item.name,
					price: item.price,
					description: item.description,
					items: [item.description],
					isActive: item.isActive,
				};
				if (!objToPush.isActive) objToPush._class = ["disabled"];
				tempArr.push(objToPush);
			}
			filteredItems.value = tempArr;
			break;
		}
		default: {
			break;
		}
	}
};

const cleanPrice = (formattedNumber: string) => {
	const clean = formattedNumber.replaceAll(".", "").replace(",", ".");
	return parseFloat(clean);
};

const changePage = (evt: any) => {
	console.log(evt);
	currentPage.value = evt;
};

const handleSearchInput = (evt: any) => {
	searchInput.value = evt;
};

const refreshGroups = async () => {
	await apiStore.callGetAllGroups();
};

// NOTE THIS IS USED THERE IS A BUG WITH THE LINTER
// NOTE DO NOT DELETE
function asdfg(index: number) {
	console.log(index);

	if (showRowIndex.value.includes(index)) {
		showRowIndex.value = showRowIndex.value.filter((item) => item !== index);
	} else {
		showRowIndex.value.push(index);
	}
}
</script>
<template>
	<div class="services-wrapper">
		<div class="actions-wrap">
			<div class="inputs-wrap">
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Usluga</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedService"
							:label="'label'"
							:placeholder="'Odaberite uslugu'"
							:options="store.getServices"
							:value-prop="'id'"
							@option-selected="serviceSelected"
						/>
					</div>
				</div>
				<div v-if="selectedService.id === 1" class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Grupa</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="selectedGroup"
							:label="'label'"
							:placeholder="'Odaberite grupu'"
							:options="[{ id: 0, label: 'Sve grupe' }, ...store.getAllGroups]"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<CustomInput
					type="search"
					label="Pretraga"
					placeholder="Pretraga"
					:input-value="searchInput"
					@update:input-value="handleSearchInput"
				/>
			</div>
			<div class="button-wrap">
				<CustomButton
					v-if="selectedService.id !== 4"
					:icon="images.addImg"
					text="Dodaj"
					@btn-click="openOptions"
				/>
				<div
					v-if="showOptions"
					v-on-click-outside="hideOptions"
					class="add-options"
				>
					<span
						:class="{
							disabled: selectedService.id !== 1,
						}"
						@click="handleCreate('group')"
					>
						Grupa
					</span>
					<span
						:class="{
							disabled: isNaN(selectedGroup.id) || selectedGroup.id === 0,
						}"
						@click="handleCreate('item')"
					>
						Analiza/pregled
					</span>
				</div>
			</div>
		</div>
		<div class="table-wrap">
			<ModernTable
				:columns="getTableColumns"
				:rows="cutFilteredItems"
				:is-infinite-scroll="false"
				:has-border="false"
				:display-additional-row="selectedService.id !== 1"
			>
				<template v-if="selectedService.id === 1" #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'isActive'">
						<input
							type="checkbox"
							:checked="slotProps.row.isActive"
							@change="handleToggleActive(slotProps.row)"
						/>
					</td>
					<td v-else-if="slotProps.column.id === 'actions'">
						<div class="table-actions-wrap">
							<div class="edit" @click="handleEdit(slotProps.row)">
								<img :src="images.editAction" alt="" />
							</div>
							<div class="delete" @click="handleDelete(slotProps.row.id)">
								<img :src="images.deleteAction" alt="" />
							</div>
						</div>
					</td>
				</template>
				<template v-else #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'actions'">
						<div class="table-actions-wrap">
							<div class="edit" @click="handleEdit(slotProps.row)">
								<img :src="images.editAction" alt="" />
							</div>
							<div class="delete" @click="handleDelete(slotProps.row.id)">
								<img :src="images.deleteAction" alt="" />
							</div>
						</div>
					</td>
					<td v-else-if="slotProps.column.id === 'expand'">
						<!-- <td>  -->
						<div class="chevron-wrap" @click="asdfg(slotProps.row.id)">
							<div v-if="selectedService.id === 4" class="chevron-actions-wrap">
								<div
									class="state"
									@click.prevent.stop="handleToggleActive(slotProps.row, true)"
								>
									<img
										v-if="slotProps.row.isActive"
										:src="images.holdAction"
										alt=""
									/>
									<img v-else :src="images.playAction" alt="" />
								</div>
								<div
									class="edit"
									@click.stop.prevent="handleEdit(slotProps.row)"
								>
									<img :src="images.editAction" alt="" />
								</div>
							</div>
							<img :src="images.downArrow" alt="arrowdown" />
						</div>
					</td>
				</template>
				<template #table-body-extra-row-content="extraProps">
					<transition name="show-row">
						<td
							v-show="showRowIndex.includes(extraProps.row.id)"
							:colspan="getTableColumns.length"
							class="info-row"
						>
							<div
								v-for="(item, index) of extraProps.row.items"
								:key="index"
								class="info-row-item"
							>
								<img :src="images.tableCheck" alt="" />
								<span>{{ item }}</span>
							</div>
						</td>
					</transition>
				</template>
			</ModernTable>
		</div>
		<PaginationComp
			:page="currentPage"
			:pages="Math.ceil(filteredItems.length / base)"
			prev-text="Nazad"
			next-text="Dalje"
			@update:page="changePage"
		/>
	</div>
</template>
<style scoped lang="scss">
.services-wrapper {
	width: 100%;

	// height: 100%;
	padding: 17px 68px 35px 27px;

	.actions-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		.inputs-wrap {
			display: flex;
			gap: 15px;

			.dropdown-wrap {
				width: 300px;
			}

			:deep(.input-wrapper) {
				min-width: 300px;
				width: 300px;
				max-width: 300px;
				margin-right: 20px;
			}
		}

		.button-wrap {
			position: relative;

			:deep(.button-wrapper) {
				min-width: 150px;
				width: 150px;
			}

			.add-options {
				width: 150px;

				// height: 80px;
				position: absolute;
				top: 48px;
				display: flex;
				flex-direction: column;
				z-index: 10;

				// justify-content: space-between;
				gap: 10px;
				align-items: flex-start;
				border-radius: 20px;
				background: #fff;
				box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
				padding: 12px 0 12px 9px;

				span {
					color: $primary-text;
					text-align: center;
					font-family: Poppins, sans-serif;
					font-style: normal;
					font-size: 15px;
					line-height: 20px; /* 133.333% */
					letter-spacing: 0.45px;
					cursor: pointer;

					&.disabled {
						color: $alt-gray;
						pointer-events: none;
					}
				}

				span:first-child {
					font-weight: 700;
				}

				span:not(:first-child) {
					font-weight: 400;
				}
			}
		}
	}

	.table-wrap {
		width: 100%;
		padding-top: 30px;

		// height: calc(100% - 100px);
		min-height: 660px;
		margin-bottom: 20px;

		.table-actions-wrap {
			width: 40px;
			display: flex;
			justify-content: space-between;

			.edit,
			.delete {
				cursor: pointer;
			}
		}

		.chevron-wrap {
			display: flex;
			justify-content: flex-end;
			padding-right: 20px;
			cursor: pointer;

			.chevron-actions-wrap {
				display: flex;
				margin-right: 20px;

				.state {
					margin-right: 20px;
				}
			}
		}

		.info-row {
			// display: flex;
			// flex-direction: column;
			padding-left: 6px;
			padding-bottom: 5px;

			.info-row-item {
				display: flex;
				align-items: center;
				margin: 5px 0;

				img {
					margin-right: 5px;
				}
			}
		}
	}

	:deep(.pagination-wrapper) {
		width: 100%;
		justify-content: center;
	}
}
</style>
