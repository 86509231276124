<script setup lang="ts">
import { useInfiniteScroll } from "@vueuse/core";

export type TTableColumn = {
	id: string; // Unique id - used when combining column with rows data
	label: string;
	style?: Record<string, any>;
	class?: Record<string, any>;
};
// export type TTableRow = Record<string, any>;
export type TTableRow = {
	_class?: string[];
	_style?: Record<string, string>;
	[key: string]: any;
};

const props = withDefaults(
	defineProps<{
		columns?: TTableColumn[];
		rows?: TTableRow[];
		hasBorder?: boolean;
		isSticky?: boolean;
		isInfiniteScroll?: boolean;
		displayAdditionalRow?: boolean;
	}>(),
	{
		columns: () => [],
		rows: () => [],
		isSticky: true,
		isInfiniteScroll: true,
		displayAdditionalRow: false,
	},
);
const emit = defineEmits(["click-header", "click-cell", "scroll-more"]);
const tableWrapperRef = ref<HTMLElement | null>(null);

const tableBorder = computed(() => {
	return {
		border: props.hasBorder,
		sticky: props.isSticky,
	};
});

function onTableScrollInfi() {
	if (props.isInfiniteScroll) {
		emit("scroll-more");
	}
}

onMounted(() => {
	if (tableWrapperRef.value) {
		useInfiniteScroll(tableWrapperRef, () => onTableScrollInfi(), {
			distance: 10,
		});
	}
});
</script>

<template lang="pug">
.table-wrapper(ref="tableWrapperRef")
	table(:class="tableBorder")
		slot(name="table-content")
			thead
				slot(name="table-header-columns", :columns="columns")
					tr
						template(v-for="column in columns", :key="column.id")
							slot(name="table-header-cell", :column="column")
								slot(:name="'table-header-cell-' + column.id", :column="column")
									th(
										:style="column.style",
										:class="column.class",
										@click="$emit('click-header', column)"
									)
										span {{ column.label }}
			tbody(:class="{ 'has-extra-row': displayAdditionalRow }")
				slot(name="table-body-rows", :rows="rows")
					template(v-for="(row, _index) in rows", :key="_index")
						tr(:class="row._class", :style="row._style")
							template(v-for="column in columns", :key="column.id")
								slot(name="table-body-cell", :row="row", :column="column")
									slot(
										:name="'table-body-cell-' + _index + '-' + column.id",
										:row="row",
										:column="column"
									)
										td(@click="$emit('click-cell', { row, column })")
											span {{ row[column.id] }}
						slot(
							v-if="displayAdditionalRow",
							name="table-body-extra-row",
							:row="row"
						)
							tr.extra-row
								slot(name="table-body-extra-row-content", :row="row")
									template(v-for="column in columns", :key="column.id")
										slot(name="table-body-cell", :row="row", :column="column")
											slot(
												:name="'table-body-cell-' + _index + '-' + column.id",
												:row="row",
												:column="column"
											)
												td(@click="$emit('click-cell', { row, column })")
													span {{ row[column.id] }}
</template>

<style lang="scss" scoped>
.table-wrapper {
	--row-back: white;
	--row-back-odd: #f8f8f8;

	display: flex;
	gap: 10px;

	// overflow: auto;
	width: 100%;

	// scrollbar-color: $scrollbar-color $scrollbar-color-thumb;
	// scrollbar-width: thin;

	// &::-webkit-scrollbar {
	// 	background-color: $scrollbar-color;
	// 	width: 5px;
	// 	height: 5px;
	// }

	// &::-webkit-scrollbar-thumb {
	// 	background-color: $scrollbar-color-thumb;
	// 	border-radius: 5px;
	// }

	table {
		width: 100%;
		border-collapse: collapse;

		td {
			font-size: 13px;
			font-weight: 600;
			color: $table-text;
			padding-left: 5px;
			padding-right: 5px;

			&:not(.extra-row) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 300px;
			}
		}

		thead {
			th {
				color: $alt-gray;
				background: var(--row-back);
				font-weight: 700;
				font-size: 11px;
				text-align: left;
				padding-left: 5px;
				padding-right: 5px;
				z-index: 1;
				text-transform: uppercase;
				padding-bottom: 20px;
			}
		}

		&.border {
			border: 1px solid gray;

			th,
			td {
				border: 1px solid gray;
			}
		}

		tbody {
			tr {
				height: 30px;

				&.disabled {
					td {
						color: $table-text;
						opacity: 0.5;
					}
				}
			}

			&.has-extra-row {
				tr {
					&:nth-child(4n + 1),
					&:nth-child(4n + 2) {
						background-color: var(--row-back-odd);
					}
				}
			}

			&:not(&.has-extra-row) {
				tr {
					&:nth-child(2n + 1) {
						background-color: var(--row-back-odd);
					}
				}
			}

			.extra-row {
				height: unset;
			}
		}

		&.sticky {
			th {
				position: sticky;
				top: 0;
			}
		}
	}
}
</style>
