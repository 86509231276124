<script setup lang="ts">
import images from "@/assets/js/images";
import router from "@/router";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();

const email = ref<string>("");

const handleResetPassword = async () => {
	const result = await apiStore.callResetPassword(email.value);
	if (result) {
		void router.replace({ name: "LoginPage" });
	}
};
</script>
<template>
	<div class="forgot-password-wrapper">
		<div class="forgot-password-modal">
			<div class="head-logo">
				<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
			</div>
			<div class="forgot-password-title">Zaboravljena lozinka</div>
			<div class="forgot-password-subtitle">
				Unesite email adresu na koju želite da vam posaljemo link za resetovanje
				lozinke.
			</div>
			<div class="inputs-wrap">
				<CustomInput
					type="text"
					label="Email"
					placeholder="Email"
					:input-value="email"
					@input="email = $event.target.value"
				/>
			</div>
			<div class="btn-wrap">
				<CustomButton
					text="Prijavi se"
					:icon="images.sendImg"
					@btn-click="handleResetPassword"
				/>
			</div>
			<div class="login-wrap">
				Imate nalog?
				<router-link class="login-link" to="/auth/login">
					Prijavite se
				</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.forgot-password-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background:
		no-repeat url("@/assets/image/login-bg.png"),
		lightgray 50%;
	background-size: cover;

	.forgot-password-modal {
		height: 600px;
		width: 380px;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 0 40px 0 rgb(118 148 255 / 5%);
		padding: 70px 50px;
		display: flex;
		flex-direction: column;

		// justify-content: center;
		align-items: center;

		.head-logo {
			width: 140px;

			img {
				width: 100%;
			}
		}

		.forgot-password-title {
			color: $primary-text;
			font-size: 18px;
			font-weight: 600;
			margin: 40px 0;
		}

		.forgot-password-subtitle {
			color: $primary-text;
			font-family: Poppins, sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 26px;
		}

		.inputs-wrap {
			width: 100%;
			margin: 40px 0;
		}

		.login-wrap {
			margin-top: 40px;
			color: $primary-text;
			font-size: 14px;
			font-family: Poppins, sans-serif;
			font-weight: 500;

			.login-link {
				font-size: 14px;
				font-family: Poppins, sans-serif;
				font-weight: 500;
				color: $main-highlight;
				text-decoration: none;
			}
		}
	}
}
</style>
