<script setup lang="ts">
import images from "@/assets/js/images";

const props = defineProps<{
	title: string;
	text: string;
	action: "delete" | "block" | "pause" | "play";
	callback?: (index: number) => void;
}>();
defineEmits(["close-modal"]);

const returnBtnText = computed(() => {
	switch (props.action) {
		case "delete": {
			return "Obriši";
		}
		case "block": {
			return "Blokiraj";
		}
		case "pause": {
			return "Pauziraj";
		}
		case "play": {
			return "Aktiviraj";
		}
		default: {
			return "Potvrdi";
		}
	}
});

const returnBtnIcon = computed(() => {
	switch (props.action) {
		case "delete": {
			return images.deleteImg;
		}
		case "block": {
			return images.blockImg;
		}
		case "pause": {
			return images.pauseImg;
		}
		case "play": {
			return images.playAction;
		}
		default: {
			return images.confirmImg;
		}
	}
});
</script>
<template>
	<div class="alert-wrapper">
		<div class="alert-title">
			{{ title }}
		</div>
		<div class="alert-message">
			{{ text }}
		</div>
		<div class="alert-btns">
			<CustomButton
				text="Odustani"
				:icon="images.closeImg"
				small
				alt-color
				@btn-click="$emit('close-modal', false)"
			/>
			<CustomButton
				:text="returnBtnText"
				:icon="returnBtnIcon"
				small
				inverted
				@btn-click="$emit('close-modal', true)"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.alert-wrapper {
	display: flex;
	flex-direction: column;

	.alert-title {
		color: $alt-dark;
		font-size: 17px;
		font-weight: 700;
		line-height: 20px;
		text-align: center;
	}

	.alert-message {
		color: $alt-dark;
		min-width: 269px;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-align: center;
		margin: 20px 0;
	}

	.alert-btns {
		width: 100%;
		display: flex;
		gap: 0 20px;
		justify-content: space-between;
	}
}
</style>
