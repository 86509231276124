<script setup lang="ts">
import images from "@/assets/js/images";
import type { TLooseObject } from "@/assets/js/types";
import { useApiStore } from "@/store/api";
import { vOnClickOutside } from "@vueuse/components";
import dayjs from "dayjs";
import locale_rs from "dayjs/locale/sr";

dayjs.locale(locale_rs);

const apiStore = useApiStore();

const props = defineProps<{
	id?: number;
	avlID?: number;
	otmID?: number;
	name?: string;
	phone?: string;
	locked?: boolean;
	free?: boolean;
	unsorted?: boolean;
	technician?: string;
	cardType?: number;
	showBtn?: boolean;
	isCompleted?: boolean;
	cardTag?: string;
	serviceID?: number;
}>();

const showDebug = ref<boolean>(false);
const clickedEl = ref<any>();
const heightOffset = ref<boolean>(false);
const widthOffset = ref<boolean>(false);
const availableTechnicians = ref<any[]>([]);

const debug = async (evt: any) => {
	const result = await apiStore.callGetAvailableTechnicians(props.avlID || 0);
	const tempArr = [];
	for (const el of result) {
		tempArr.push({
			id: el.usr_id,
			name: el.usr_fullname,
		});
	}
	availableTechnicians.value = tempArr;
	showDebug.value = true;
	// const parentRect = document.querySelectorAll(".table-wrap");
	// console.log(evt.target.getBoundingClientRect());
	clickedEl.value = evt.target.getBoundingClientRect();
	setPosition();

	// console.log(parentRect[0].getBoundingClientRect());
};

const setPosition = () => {
	const parentRect = document.querySelectorAll(".table-wrap");
	const objToReturn: any = {};
	if (
		parentRect[0].getBoundingClientRect().height <
		clickedEl.value.top + 137
	) {
		// objToReturn.top = "unset";
		// objToReturn.bottom = "25px";
		// return { "height-offset": true };
		heightOffset.value = true;
	}
	if (
		parentRect[0].getBoundingClientRect().width <
		clickedEl.value.left + 230
	) {
		// objToReturn.left = "unset";
		// objToReturn.right = "25px";
		// return { "width-offset": true };
		widthOffset.value = true;
	}

	// return objToReturn;
};

const emit = defineEmits([
	"delete",
	"openModal",
	"addTechnician",
	"approveOrder",
]);

const showMoreInfo = ref<boolean>(false);
const orderInfo = ref<TLooseObject>({});

const handleModalOpen = () => {
	if (!props.free) {
		emit("openModal");
	}
};

const handleOpen = async () => {
	if (!showMoreInfo.value) {
		const result = await apiStore.callGetOrder(props.id || 0);
		orderInfo.value = result;
		orderInfo.value = { ...orderInfo.value, datetime: { date: "", time: [] } };
		if (orderInfo.value.ord_old_ordertimes) {
			orderInfo.value.datetime.date = dayjs(
				orderInfo.value.ord_old_ordertimes[0]?.avl_date,
			).format("dddd DD. MMMM");
			for (const avl of orderInfo.value.ord_old_ordertimes) {
				orderInfo.value.datetime.time.push(
					`${avl.avl_timefrom.slice(0, 2)}h - ${avl.avl_timeto.slice(0, 2)}h`,
				);
			}
		}
		// console.log(orderInfo.value);
	}
	// const result = await apiStore.callGetOrder(props.id || 0);
	// console.log(result);
	// orderInfo.value = result;

	showMoreInfo.value = !showMoreInfo.value;
};

const formatName = (name: string) => {
	const splitName = name.split(" ");
	return splitName.length === 1
		? `${splitName[0][0]}${splitName[0][1]}`
		: `${splitName[0][0]}${splitName[1][0]}`;
};

const handleSelectTechnician = (element: any) => {
	console.log(element);
	emit("addTechnician", element);
	showDebug.value = false;
};

const approveOrder = async () => {
	emit("approveOrder");
	// await apiStore.callPatchOrderApprove
};
</script>
<template>
	<div class="slot-card-wrapper">
		<div
			class="slot-wrapper"
			:class="{ unsorted: unsorted, 'show-info': showMoreInfo }"
			@click="handleModalOpen"
		>
			<div v-if="!unsorted || !showMoreInfo" class="left-wrap">
				<div
					v-if="!unsorted"
					class="indicator"
					:class="{
						'not-confirmed': !locked,
						'free-slot': free,
						pause: cardType === 1,
						delivery: cardType === 2,
					}"
				></div>
				<div v-if="(!free && !cardType) || cardType === 3" class="info">
					<div class="name">{{ name }}</div>
					<div class="number">{{ phone }}</div>
					<div
						class="tag"
						:class="{ blue: cardTag === 'LAB', green: cardTag !== 'LAB' }"
					>
						{{ cardTag }}
					</div>
				</div>
				<div v-else class="free-slot-text">
					{{
						cardType
							? cardType === 1
								? "Pauza"
								: "Isporuka uzoraka"
							: serviceID === 1
								? "Slobodan laboratorijski termin"
								: serviceID === 4
									? "Slobodan termin za pregled"
									: "Slobodan termin"
					}}
				</div>
			</div>
			<div v-else class="left-wrap big">
				<div class="big-info">
					<div class="name">{{ name }}</div>
					<div class="tests">
						<div class="title">Analize</div>
						<div
							v-for="(item, index) in orderInfo.orderitems"
							:key="index"
							class="test item"
						>
							{{ item.itm_name }}
						</div>
					</div>
					<div class="personal-info">
						<div class="title">Lični podaci pacijenta</div>
						<div class="item">
							{{ orderInfo.ord_fullname }}
						</div>
						<div class="item">
							{{ orderInfo.ord_phone }}
						</div>
						<div class="item">
							{{ orderInfo.ord_email }}
						</div>
						<div class="item">
							{{ orderInfo.ord_address }}, {{ orderInfo.area_name }}
						</div>
					</div>
					<div class="time-slot">
						<div class="title">Izabrani termin</div>
						<div class="date item">{{ orderInfo.datetime.date }}</div>
						<div class="time item">
							<!-- TODO - after db reset -->
							<!-- vfor this shit -->
							<span
								v-for="(time, index) in orderInfo.datetime.time"
								:key="index"
							>
								{{ time }}
							</span>
							<!-- <span>07h - 09h,</span>
							<span>11h - 13h</span> -->
						</div>
					</div>
				</div>
			</div>
			<div class="additional-info">
				<div v-if="free" class="delete" @click="$emit('delete')">
					<img :src="images.deleteAction" alt="" />
				</div>
				<!-- FIXME - rename this after get info -->
				<div v-if="unsorted" class="no-clue" @click="handleOpen">
					<img :src="images.downArrow" alt="" />
				</div>
				<div class="confirmation">
					<input
						v-if="!locked && !free && !unsorted && !cardType"
						type="checkbox"
						@click.stop.prevent="approveOrder"
					/>
				</div>
				<div v-if="locked && showBtn" class="technician">
					<div v-if="!technician" class="btn-wrap" @click.stop="debug">
						<!-- @click="$emit('addTechnician')" -->
						<img :src="images.addWhite" alt="" />
					</div>
					<div v-else class="name-wrap">
						{{ formatName(technician) }}
					</div>
				</div>
				<div v-else-if="isCompleted" class="completed-wrap">
					<img :src="images.confirmImg" alt="" />
				</div>
			</div>
		</div>
		<div
			v-if="locked && showDebug"
			v-on-click-outside="
				() => {
					showDebug = false;
				}
			"
			class="options-wrap"
			:class="{ 'height-offset': heightOffset, 'width-offset': widthOffset }"
		>
			<div class="title-wrap">
				<div class="title">Izaberi tehničara</div>
				<div class="close">
					<img :src="images.modalClose" alt="" @click="showDebug = false" />
				</div>
			</div>
			<CustomModalList
				v-if="availableTechnicians.length > 0"
				:data="availableTechnicians"
				:is-in-card="true"
				@select-item="handleSelectTechnician"
			/>
			<div v-else>Nema dostupnih tehničara</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.slot-card-wrapper {
	position: relative;

	.slot-wrapper {
		width: 235px;
		height: 90px;
		min-height: 90px;
		border-radius: 5px;
		box-shadow: 0 4px 48px 0 rgb(0 0 0 / 11%);
		display: flex;
		justify-content: space-between;
		background: #fff;

		// margin-bottom: 50px;

		.left-wrap {
			display: flex;

			.indicator {
				height: 100%;
				width: 10px;
				background: $primary-text;
				border-radius: 5px 0 0 5px;
			}

			.not-confirmed {
				background: $main-highlight;
			}

			.free-slot {
				background: #c3c3c3;
			}

			.pause {
				background: $main-highlight;
			}

			.delivery {
				background: $primary-text;
			}

			.info {
				height: 100%;
				padding: 0 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.name {
					color: $primary-text;
					font-size: 14px;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
				}

				.number {
					color: $primary-text;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
				}

				.tag {
					margin-top: 7px;

					&.green {
						color: #00af46;
					}

					&.blue {
						color: #08a6c9;
					}
				}
			}

			.free-slot-text {
				height: 100%;
				padding-left: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $alt-dark;
				font-size: 15px;
				font-weight: 500;
			}
		}

		.big {
			.big-info {
				display: flex;
				flex-direction: column;
				max-width: 200px;
				padding-left: 20px;

				.name {
					color: $primary-text;
					font-family: Archivo, sans-serif;
					font-size: 14px;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
					margin-top: 25px;
					margin-bottom: 25px;
				}

				.tests > .title,
				.personal-info > .title,
				.time-slot > .title {
					color: $primary-text;
					font-family: Archivo, sans-serif;
					font-size: 14px;
					font-weight: 600;
					line-height: 18px; /* 128.571% */
					margin-bottom: 10px;
				}

				.tests > .item,
				.personal-info > .item,
				.time-slot > .item {
					color: $primary-text;
					font-family: Archivo, sans-serif;
					font-size: 14px;
					font-weight: 400;
					line-height: 20px; /* 142.857% */
					margin-bottom: 3px;
				}

				.tests,
				.personal-info,
				.time-slot {
					margin-bottom: 10px;
				}
			}
		}

		.additional-info {
			height: 100%;
			padding: 10px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;

			.delete,
			.no-clue {
				cursor: pointer;
			}

			.technician {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: flex-end;

				.btn-wrap {
					height: 19px;
					width: 19px;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $primary-text;
					cursor: pointer;
				}
			}

			.completed-wrap {
				height: 19px;
				width: 19px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $primary-text;

				img {
					width: 70%;
				}
			}
		}
	}

	.options-wrap {
		// background: red;
		background: #fff;
		z-index: 999;
		padding: 10px;
		width: 230px;
		height: 137px;
		border-radius: 5px;
		position: absolute;
		box-shadow: 0 4px 48px 0 rgb(0 0 0 / 11%);

		// right: 25px;
		left: 206px;
		top: 61px;

		.title-wrap {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 6px;

			.close {
				cursor: pointer;
			}
		}

		// bottom: 25px;

		// &.bottom {
		// 	bottom: 25px;
		// }

		// &.top {
		// 	top: 61px;
		// }

		&.height-offset {
			top: unset;
			bottom: 25px;
		}

		&.width-offset {
			left: unset;
			right: 25px;
		}
	}

	.show-info {
		height: unset;
		max-width: 235px;
	}

	.unsorted {
		background: rgba(237 32 56 / 19%);
		box-shadow: none;
		margin-bottom: 20px;
	}
}
</style>
