<script setup lang="ts">
import dayjs from "dayjs";

const props = defineProps<{
	label?: string;
	placeholder: string;
	disabled?: boolean;
	canClear?: boolean;
	maxDate?: boolean;
	minDate?: boolean;
	inputValue: any;
}>();
const emit = defineEmits(["update:modelValue"]);

const date = ref();

const handleMinDate = computed(() => {
	return props.minDate ? new Date() : null;
});

const handleMaxDate = computed(() => {
	return props.maxDate ? new Date() : null;
});

const handleDate = (inputVal: any) => {
	console.log(inputVal);

	emit("update:modelValue", inputVal ? dayjs(inputVal) : "");
};
</script>
<template>
	<div class="datepicker-wrapper">
		<div class="label-wrap">
			{{ label }}
		</div>
		<div class="datepicker-wrap">
			<VueDatePicker
				:model-value="inputValue"
				:placeholder="placeholder"
				:disabled="disabled"
				:hide-navigation="['time']"
				:enable-time-picker="false"
				:clearable="canClear"
				:format="`dd.MM.yyyy.`"
				:teleport="true"
				:min-date="handleMinDate"
				:max-date="handleMaxDate"
				locale="sr-Latn-RS"
				auto-apply
				@update:model-value="handleDate"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss"></style>
<style lang="scss">
:root {
	--dp-font-family: Archivo, sans-serif !important;
	--dp-border-radius: 7px !important;
	--dp-font-size: 14px !important;
}

.datepicker-wrapper {
	width: 300px;

	// height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	.label-wrap {
		color: $subtitle-text;
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 5px;
	}

	.datepicker-wrap {
		height: 48px;
		width: 300px;

		// border: 1px solid $default-gray;
		// border-radius: 7px;
		// outline: none;
		// // background: transparent;
		// font-size: 14px;
		// font-weight: 500;
		// color: $primary-text;
		// padding-left: 17px;

		.dp__theme_light {
			--dp-text-color: #172841;
			--dp-border-color: #dce0e8;
			--dp-border-color-hover: #dce0e8;
			--dp-primary-color: #172841;

			.dp__input {
				height: 48px;
				font-weight: 500;

				&::placeholder {
					font-family: Poppins, sans-serif;
					color: #9ea4af;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					opacity: 1;
				}
			}
		}
	}
}
</style>
