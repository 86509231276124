<script setup lang="ts">
import { useDefaultStore } from "./store";
import ModernModal from "./components/Shared/modal/ModernModal.vue";
import { useApiStore } from "./store/api";
import { useModalStore } from "./store/modal";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";
import locale_rs from "dayjs/locale/sr";
import { useToastStore } from "./store/toast";
import { openAlertModal } from "./components/modals/modalDefinitions";
import router from "./router";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);
dayjs.locale(locale_rs);

const appMetaTitle = "Vizim Web";
const modernModalRef = ref<null | InstanceType<typeof ModernModal>>(null);
const store = useDefaultStore();
const apiStore = useApiStore();
const modalStore = useModalStore();

const currentNumOfUnseenNotifications = ref(0);

const isGlobalModalVisible = computed({
	get() {
		return modalStore.getModalData("comp");
	},
	set(val) {
		if (val) {
			modalStore.updateModalData(val, "comp");
		} else {
			modalStore.closeGlobalModal();
		}
	},
});

const checkSession = async () => {
	await apiStore.callCheckSession();
};

onMounted(async () => {
	await checkSession();
	if (
		!store.getHasSoundPermission &&
		router.currentRoute.value.name !== "VerifyEmailPage" &&
		router.currentRoute.value.name !== "DeleteAccountForm"
	) {
		openAlertModal(
			{
				title: "Dozvola za notifikacije",
				text: "Potrebna je potvrda za zvučne notifikacije",
			},
			playSound,
		);
	}
	await checkForUnseenNotifications();
	setInterval(
		() => {
			void checkSession();
		},
		1000 * 60 * 15,
	);
	setInterval(
		() => {
			void checkForUnseenNotifications();
		},
		1000 * 60 * 3,
	);
});

const onCloseModalComp = () => {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null);
	} else {
		console.error("No modal instance");
	}
};

const checkForUnseenNotifications = async () => {
	const result = await apiStore.callGetSystemNotifications(1);

	if (result.length === 0) return;
	const now = dayjs.utc().subtract(4, "minute");
	const dateToCheck = dayjs
		.utc(result[0]?.msg_utccreated)
		.tz("Europe/Belgrade");

	if (dateToCheck.isSameOrAfter(now) && !result[0]?.umg_utcseen) {
		console.log("usao sam u if od checkForUnseenNotifications");

		useToastStore().openToastInfo("Imate novo obaveštenje");
		store.setShouldRefresh(true);
		const audio = new Audio(
			// FIXME: make this dynamic
			"https://dev.aplikacija.vizim.co.rs/api/assets/front/audio/vizim-notification-sound.mp3",
		);
		await audio.play();
	}
};

const playSound = async () => {
	const audio = new Audio(
		// FIXME: make this dynamic
		"https://dev.aplikacija.vizim.co.rs/api/assets/front/audio/vizim-notification-sound.mp3",
	);
	await audio.play();
	store.setHasSoundPermission(true);
};
</script>

<template>
	<!-- teleport(to="head title")
	| {{ appMetaTitle }}
router-view(:class="{ 'is-mobile': store.isMobileToggled }")
router-view(:class="{ 'is-mobile': store.isMobileToggled }") -->
	<teleport to="head title">
		{{ appMetaTitle }}
	</teleport>
	<router-view />
	<modern-modal
		v-if="isGlobalModalVisible"
		ref="modernModalRef"
		:config="modalStore.getModalData('config', {})"
		:form-style="modalStore.getModalData('formStyle', {})"
		@close-modal="isGlobalModalVisible = null"
	>
		<template #content="{ slotProps }">
			<component
				:is="modalStore.getModalData('comp')"
				v-bind="slotProps"
				@close-modal="onCloseModalComp"
			></component>
		</template>
	</modern-modal>
</template>

<style lang="scss">
@import "./assets/css/base.css";

body {
	&.is-mobile {
		overflow-y: hidden;
	}
}

#app {
	width: 100vw;
	height: 100%;
}
</style>
