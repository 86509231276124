import { type IGlobalModal } from "@/components/Shared/modal/ModernModal.vue";
import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", () => {
	const globalModal = ref<IGlobalModal>({
		comp: null,
		config: {} as IGlobalModal["config"],
		formStyle: {},
	});

	const getModalData = computed(() => {
		return (key?: keyof typeof globalModal.value, defaultValue?: any) => {
			return key ? globalModal.value[key] || defaultValue : globalModal.value;
		};
	});

	function updateModalData(data: any, key?: keyof typeof globalModal.value) {
		if (key) {
			globalModal.value[key] = data;
		} else {
			globalModal.value = {
				...data,
				comp: markRaw(data.comp),
			};
		}
	}
	function closeGlobalModal() {
		updateModalData(null, "comp");
	}

	return {
		globalModal,
		getModalData,
		updateModalData,
		closeGlobalModal,
	};
});
