import { baseURL } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { type Ref, type UnwrapRef } from "vue";
import type { TLooseObject, TNameIDPair } from "./types";
import { useDefaultStore } from "@/store";

// @ts-expect-error
export function debounce(func, waitTimer, immediate): () => void {
	// @ts-expect-error
	let timeout = null;
	return function () {
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;

		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		// @ts-expect-error
		const callNow = immediate && !timeout;
		// @ts-expect-error
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

// @ts-expect-error
export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		const args = arguments;
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]",
			"*[tabindex]:not(:disabled):not(.hidden)",
		].join(",");

		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						// @ts-expect-error - zex code
						focusEl(evt, nodeList.at(-1));
					}
				});

				// Last el
				// @ts-expect-error - zex code
				nodeList.at(-1).addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && !evt.shiftKey) {
						focusEl(evt, nodeList[0]);
					}
				});
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

export function getCurrentDomain() {
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}

function getUseStorage<T>(
	key: string,
	shouldParse = false,
	storage: Storage,
	defaultVal?: T,
): Ref<T | UnwrapRef<T> | string | null | undefined> {
	const state = useStorage(key, defaultVal, storage);
	if (!shouldParse) return state;

	try {
		return ref(JSON.parse(state.value as string));
	} catch {
		console.error(`Error loading key`, key);
		return ref(defaultVal);
	}
}

export function getLocalStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, localStorage, defaultVal);
}

export function getSessionStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, sessionStorage, defaultVal);
}

export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (localStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (sessionStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function returnIDFromLabel(label: string, array: any[]) {
	return array.find((obj: TNameIDPair) => obj.label === label)?.id ?? 0;
}

export function returnLabelFromID(id: number, array: any[]) {
	return array.find((obj: TNameIDPair) => obj.id === id)?.label ?? "";
}

export function isAuthenticated(): boolean {
	return !!useDefaultStore().getUser;
}

export function downloadBlob(blob: Blob, fileName: string) {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.style.display = "none";
	a.href = url;
	a.download = fileName;
	document.body.append(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export function downloadFile(url: any) {
	const base = baseURL;
	const a = document.createElement("a");
	a.style.display = "none";
	a.href = `${base}${url}`;
	a.target = "_blank";
	a.click();
	a.remove();
}

export function urlB64ToUint8Array(base64String: string) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		// .replace(/\-/g, "+")
		.replace(/-/g, "+")
		.replace(/_/g, "/");
	const rawData = atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		// outputArray[i] = rawData.charCodeAt(i);
		// @ts-expect-error
		outputArray[i] = rawData.codePointAt(i);
	}
	return outputArray;
}

export function mergePairsByKey(arrayOne: any, arrayTwo: any, key: string) {
	const newObjects = [];
	const keysHandled = new Set();

	const array1 = JSON.parse(JSON.stringify(arrayOne));
	const array2 = JSON.parse(JSON.stringify(arrayTwo));

	// Check each object in array1 against each object in array2
	// eslint-disable-next-line unicorn/no-for-loop
	for (let i = 0; i < array1.length; i++) {
		let foundPair = false;
		for (let j = 0; j < array2.length; j++) {
			if (
				array1[i][key] === array2[j][key] &&
				!keysHandled.has(array1[i][key])
			) {
				// Merge the objects and add to newObjects
				const mergedObject = mergeTwoObjects(array1[i], array2[j]);
				newObjects.push(mergedObject);
				keysHandled.add(array1[i][key]);
				array2.splice(j, 1); // Remove the paired object from array2
				foundPair = true;
				break;
			}
		}
		if (!foundPair) {
			newObjects.push(array1[i]); // Add the unpaired object from array1 to newObjects
		}
	}

	// Add remaining unpaired objects from array2 to newObjects
	newObjects.push(...array2);

	// Clean up the array of objects
	const result = cleanupArray(newObjects);

	for (const obj of result) {
		if (obj.maxcapacity.length > 1) {
			obj.maxcapacity = obj.maxcapacity[0] + obj.maxcapacity[1];
		}
	}

	return result;
	// return newObjects;
}

// A helper function to merge two objects
function mergeTwoObjects(obj1: any, obj2: any) {
	const mergedObject: TLooseObject = {};
	// Merge keys from both objects
	for (const k in obj1) {
		mergedObject[k] = Array.isArray(obj1[k]) ? obj1[k].slice() : [obj1[k]];
		if (obj2[k] !== undefined && !mergedObject[k].includes(obj2[k])) {
			mergedObject[k].push(obj2[k]);
		}
	}
	for (const k in obj2) {
		if (mergedObject[k] === undefined) {
			mergedObject[k] = Array.isArray(obj2[k]) ? obj2[k].slice() : [obj2[k]];
		}
	}
	return mergedObject;
}

// Helper function to clean up an array of objects
function cleanupArray(objects: any) {
	// Iterate over each object in the array
	return objects.map((obj: any) => {
		// Create a new object to hold the cleaned values
		const cleanedObj: TLooseObject = {};

		// Iterate over each key in the object
		for (const key in obj) {
			cleanedObj[key] =
				Array.isArray(obj[key]) && obj[key].length === 1
					? obj[key][0]
					: obj[key];
		}

		return cleanedObj;
	});
}

export function splitObjectsByKey(objects: any, key: string) {
	const groups: TLooseObject = {}; // An object to hold arrays keyed by the job value

	// Iterate through each object in the input array
	for (const obj of objects) {
		// Check if the key value already has an array in groups; if not, create it
		if (!groups[obj[key]]) {
			groups[obj[key]] = [];
		}
		// Push the current object into the appropriate group
		groups[obj[key]].push(obj);
	}

	const nesto = Object.values(groups);
	if (nesto.length === 1) {
		return nesto[0][0].svc_id === 1 ? [nesto[0], []] : [[], nesto[0]];
	}
	// Return the values of the groups object, which are arrays of objects grouped by the key value
	return Object.values(groups);
}

export function flattenLastNestedArray(objects: any) {
	// Check if the last element of the array is actually an array of objects
	if (Array.isArray(objects.at(-1))) {
		// Retrieve the nested array
		const nestedArray = objects.pop();

		// Ensure that the extracted element is indeed an array of objects
		if (
			nestedArray.every(
				(item: any) => typeof item === "object" && !Array.isArray(item),
			)
		) {
			// Append each object from the nested array to the parent array
			objects.push(...nestedArray);
		} else {
			// If the extracted element is not an array of objects, add it back
			objects.push(nestedArray);
		}
	}

	return objects;
}

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};
