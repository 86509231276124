<script setup lang="ts">
import api from "@/api";
import images from "@/assets/js/images";
import { type TLooseObject } from "@/assets/js/types";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();

const props = defineProps<{
	id: number;
	name: string;
	price: string;
	description: string;
}>();
const emit = defineEmits(["close-modal"]);

const apiParams = ref<TLooseObject>({
	name: "",
	price: "",
	description: "",
});

onMounted(() => {
	if (props.name) apiParams.value.name = props.name;
	if (props.price) apiParams.value.price = props.price;
	if (props.description) apiParams.value.description = props.description;
});

const canSubmit = computed(() => {
	return apiParams.value.name !== "" && apiParams.value.price !== "";
});

const handleEdit = async () => {
	console.log(apiParams.value);
	// return;
	await apiStore.callPatchItemActive(props.id, apiParams.value);
	emit("close-modal");
};
</script>
<template>
	<div class="edit-at-home-wrapper">
		<CustomInput
			type="text"
			label="Naziv pregleda"
			placeholder="Naziv pregleda"
			:disabled="true"
			:input-value="name"
			@update:input-value="apiParams.name = $event"
		/>
		<CustomInput
			type="text"
			label="Cena"
			placeholder="Cena"
			:input-value="price"
			@update:input-value="apiParams.price = $event"
		/>
		<CustomInput
			type="textarea"
			label="Opis pregleda"
			placeholder="Opis pregleda"
			:input-value="description"
			@update:input-value="apiParams.description = $event"
		/>
		<div class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				small
				:disabled="!canSubmit"
				:icon="images.confirmImg"
				@click="handleEdit"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-at-home-wrapper {
	width: 300px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.btn-wrap {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
