<script setup lang="ts">
import LeftArrow from "@/assets/image/leftArrow.png";
import LeftArrowDisabled from "@/assets/image/leftArrowDisabled.png";
import RightArrow from "@/assets/image/rightArrow.png";
import RightArrowDisabled from "@/assets/image/rightArrowDisabled.png";
import { type TLooseObject } from "@/assets/js/types";

const props = defineProps<{
	page: number;
	prevText: string;
	nextText: string;
	count?: number;
	offset?: number;
	perPage?: number;
	pages: number;
}>();

const emit = defineEmits(["update:page", "pageChanged"]);

// NOTE - check if this works if not idk look at omni
onMounted(() => {
	if (props.page === 0 && props.offset && props.perPage) {
		const page = Math.ceil(props.offset / props.perPage);
		emit("update:page", page);
	}
});

const isPrevDisabled = computed(() => !(props.page - 1 >= 1));

const isNextDisabled = computed(() => {
	// NOTE - if this works funky, change back to null and handle from there
	// let pages = 0;
	// if (props.pages) {
	// 	pages = props.pages;
	// } else if (props.count && props.perPage) {
	// 	pages = Math.ceil(props.count / props.perPage);
	// }
	// if (props.page + 1 <= pages) {
	// 	return false;
	// }
	// return true;
	return !(props.page + 1 <= props.pages);
});

const getPages = computed(() => {
	let pages = 0;
	const res = [];
	if (props.pages) {
		pages = props.pages;
	} else if (props.count && props.perPage) {
		pages = Math.ceil(props.count / props.perPage);
	}
	if (pages < 7) {
		for (let i = 1; i <= pages; i++) {
			res.push({ page: i });
		}
		return res;
	} else if (props.page < 4) {
		for (let i = 1; i <= 5; i++) {
			res.push({ page: i });
		}
		res.push({}, { page: pages });
		return res;
	} else if (props.page > pages - 3) {
		res.push({ page: 1 }, {});
		for (let i = pages - 4; i <= pages; i++) {
			res.push({ page: i });
		}
		return res;
	} else {
		res.push({ page: 1 }, {});
		for (let i = props.page - 1; i <= props.page + 1; i++) {
			res.push({ page: i });
		}
		res.push({}, { page: pages });
		return res;
	}
});

// FIXME - change to actual type after finding out what it is
const emitChange = (page: any) => {
	if (!page) {
		return;
	}
	emit("update:page", page);
	const data: TLooseObject = {
		page,
	};
	if (props.count && props.offset && props.perPage) {
		data.count = props.count;
		data.perPage = props.perPage;
		data.offset = props.perPage * (page - 1) + 1;
	}
	emit("pageChanged", data);
};

const navigatePrev = () => {
	if (props.page - 1 >= 1) {
		emit("update:page", props.page - 1);
		// const data: LooseObject = {
		// 	page: props.page - 1,
		// };
		// if (props.count && props.offset && props.perPage) {
		// 	data.count = props.count;
		// 	data.perPage = props.perPage;
		// 	data.offset = props.perPage * (props.page - 2) + 1;
		// }
	}
};

const navigateNext = () => {
	let pages = 0;
	if (props.pages) {
		pages = props.pages;
	} else if (props.count && props.perPage) {
		pages = Math.ceil(props.count / props.perPage);
	}
	if (props.page + 1 <= pages) {
		emit("update:page", props.page + 1);
		const data: TLooseObject = {
			page: props.page + 1,
		};
		if (props.count && props.offset && props.perPage) {
			data.count = props.count;
			data.perPage = props.perPage;
			data.offset = props.perPage * props.page + 1;
		}
		emit("pageChanged", data);
	}
};
</script>
<template>
	<div class="pagination-wrapper">
		<div
			:class="{ disabled: isPrevDisabled }"
			class="prev-wrapper mr-2"
			@click="navigatePrev()"
		>
			<img v-if="isPrevDisabled" class="mr-2" :src="LeftArrowDisabled" alt="" />
			<img v-else class="mr-2" :src="LeftArrow" alt="" />
			<div v-if="prevText" class="prev-text">
				{{ prevText }}
			</div>
		</div>
		<div
			v-for="(arrpage, index) in getPages"
			:key="index"
			class="page"
			:class="{ active: arrpage.page === page, dots: !arrpage.page }"
			@click="emitChange(arrpage.page)"
		>
			{{ arrpage.page ? arrpage.page : "..." }}
		</div>
		<div
			:class="{ disabled: isNextDisabled }"
			class="next-wrapper"
			@click="navigateNext()"
		>
			<div v-if="nextText" class="next-text">
				{{ nextText }}
			</div>
			<img
				v-if="isNextDisabled"
				class="ml-2"
				:src="RightArrowDisabled"
				alt="aaa"
			/>
			<img v-else class="ml-2" :src="RightArrow" alt="bbb" />
		</div>
	</div>
</template>
<style scoped lang="scss">
.pagination-wrapper {
	display: flex;
	flex-direction: row;

	.next-wrapper,
	.prev-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		text-align: center;
		color: #2c3e50;
		user-select: none;

		.prev-text,
		.next-text {
			font-family: Lato, sans-serif;
		}
	}

	.disabled {
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		color: #434446;
		pointer-events: none;
		opacity: 0.6;
	}

	.page {
		height: 28px;
		width: 28px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: #39383c;
		transition-duration: 0.3s;
		cursor: pointer;
		font-family: Lato, sans-serif;
	}

	.page.dots {
		cursor: default;
	}

	.page.active {
		color: $main-blue;
		background: #d1e1ff;
		border-radius: 7px;
	}

	.page:hover {
		// color: #658cd8;
		color: #d1e1ff;
	}

	.page.dots:hover {
		color: #39383c;
	}

	.page:last-child {
		margin-right: 0;
	}
}

.mr-2 {
	margin-right: 8px;
}

.ml-2 {
	margin-left: 8px;
}
</style>
