<script setup lang="ts">
defineProps<{
	icon: string;
	text: string;
	small?: boolean;
	inverted?: boolean;
	altColor?: boolean;
	smallerIcon?: boolean;
	route?: string;
	disabled?: boolean;
}>();

const emit = defineEmits(["btnClick"]);

const handleClick = (route?: string) => {
	if (route) {
		// ionRouter.navigate(route, "back", "push");
	} else {
		emit("btnClick");
	}
};
</script>
<template>
	<div
		class="button-wrapper"
		:class="{ 'reverse-style': inverted, disabled: disabled, small: small }"
		@click="handleClick(route)"
	>
		<div class="button-icon" :class="{ 'alt-color': altColor }">
			<div class="img-wrap">
				<img :class="{ smaller: smallerIcon }" :src="icon" alt="" />
			</div>
		</div>
		<div class="button-text">
			{{ text }}
		</div>
	</div>
</template>
<style scoped lang="scss">
.button-wrapper {
	min-width: 70%;
	height: 41px;
	background: #fff;
	border-radius: 20px;
	box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
	display: flex;

	// flex-direction: ;
	padding-right: 23px;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	.button-icon {
		height: 47px;
		width: 47px;
		min-width: 47px;
		min-height: 47px;
		background: $primary-text;
		border-radius: 44px;
		display: flex;
		justify-content: center;
		align-items: center;

		// margin-right: 5px;

		.img-wrap {
			height: 43px;
			width: 43px;
			border-radius: 50%;
			border: 1px solid #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 50%;

				// height: 35%;
			}

			.smaller {
				width: unset;
				height: 35%;
			}
		}
	}

	.alt-color {
		background-color: #5888b7;
	}

	.button-text {
		margin: 0 auto;
		color: $primary-text;
		font-size: 15px;
		font-weight: 500;
		font-family: Poppins, sans-serif;
		letter-spacing: 0.45px;
		padding: 0 5px;
	}
}

.reverse-style {
	flex-direction: row-reverse;
	padding-right: 0;
	padding-left: 23px;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.small {
	min-width: 137px;
	width: 137px;
}
</style>
