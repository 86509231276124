<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";

const apiStore = useApiStore();

const name = ref("");
const email = ref("");
const phone = ref("");
const message = ref("");

const areFieldsFilled = computed(() => {
	return (
		name.value !== "" &&
		email.value !== "" &&
		phone.value !== "" &&
		message.value !== ""
	);
});

const handleDeleteForm = async () => {
	// console.log(name, email, phone, message);
	await apiStore.callPostDeleteAccountForm({
		name: name.value,
		email: email.value,
		phone: phone.value,
		message: message.value,
	});
	useToastStore().openToastSuccess(
		"Uspesno ste poslali zahtev za brisanje naloga",
	);
	name.value = "";
	email.value = "";
	phone.value = "";
	message.value = "";
};
</script>
<template>
	<div class="delete-account-form-wrapper">
		<div class="delete-account-form-modal">
			<div class="head-logo">
				<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
			</div>
			<div class="delete-account-form-title">Zahtev za brisanje naloga</div>
			<div class="delete-account-form-subtitle">
				Ako ne želite da obrišete svoj nalog, zatvorite stranicu, ukoliko
				želite, popunite kontakt formu i kolege će vam odgovoriti na mail u roku
				od 2 radna dana.
			</div>
			<div class="delete-account-form-wrap">
				<CustomInput
					type="text"
					label="Ime i prezime"
					placeholder="Ime i prezime"
					:input-value="name"
					@update:input-value="name = $event"
				/>
				<CustomInput
					type="text"
					label="Email"
					placeholder="Email"
					:input-value="email"
					@update:input-value="email = $event"
				/>
				<CustomInput
					type="text"
					label="Telefon"
					placeholder="Telefon"
					:input-value="phone"
					@update:input-value="phone = $event"
				/>
				<CustomInput
					type="text"
					label="Tekst poruke"
					placeholder="Tekst poruke"
					:input-value="message"
					@update:input-value="message = $event"
				/>
			</div>
			<div class="btn-wrap">
				<CustomButton
					text="Pošalji"
					:icon="images.sendImg"
					small
					inverted
					:disabled="!areFieldsFilled"
					@btn-click="handleDeleteForm"
				/>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.delete-account-form-wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background:
		no-repeat url("@/assets/image/login-bg.png"),
		lightgray 50%;
	background-size: cover;

	.delete-account-form-modal {
		height: 738px;
		width: 656px;
		padding: 72px 48px 45px;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 0 40px 0 rgb(118 148 255 / 5%);
		display: flex;
		flex-direction: column;
		align-items: center;

		.head-logo {
			width: 140px;

			img {
				width: 100%;
			}
		}

		.delete-account-form-title {
			color: $primary-text;
			font-size: 18px;
			font-weight: 600;
			margin: 40px 0;
		}

		.delete-account-form-subtitle {
			color: $primary-text;
			font-size: 14px;
			line-height: 26px;
			font-weight: 500;
			margin-bottom: 20px;
			text-align: center;
		}

		.delete-account-form-wrap {
			width: 286px;
			height: 310px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.btn-wrap {
			width: 286px;
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;
		}
	}
}

@media only screen and (width <= 500px) {
	.delete-account-form-wrapper {
		width: 100%;

		.delete-account-form-modal {
			width: 400px;
			padding: 60px 10px 30px;

			.delete-account-form-subtitle {
				width: 300px;
			}
		}
	}
}
</style>
