<script setup lang="ts">
import { usePoints } from "vue3-charts";
const props = defineProps<{
	dataKeys: string[];
}>();

const { points } = usePoints(props.dataKeys);
</script>
<template>
	<g class="layer-labels">
		<text
			v-for="(p, i) in points"
			:key="i"
			:x="p.x"
			:y="p.y - 10"
			text-anchor="middle"
			font-size="12"
			fill="#172841"
		>
			{{ p.props.data[dataKeys[1]] }}
		</text>
	</g>
</template>
<style scoped lang="scss"></style>
