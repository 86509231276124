<script setup lang="ts">
import { debounce } from "@/assets/js/helpers";
import images from "@/assets/js/images";

defineProps<{
	type: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	inputValue: string | number;
}>();

const emit = defineEmits(["update:inputValue"]);

// const debounce = (func: any, timeout = 300) => {
// 	console.log("i was called");

// 	let timer: any;
// 	return (...args: any) => {
// 		clearTimeout(timer);
// 		timer = setTimeout(() => {
// 			console.log("usao sam ovde");

// 			func.apply(this, args);
// 		}, timeout);
// 	};
// };

// const emitValue = (value: string) => {
// 	console.log("i was called also");

// 	emit("update:inputValue", value);
// };

// NOTE - DO NO TOUCH
const baa = debounce(
	function (evt: Event) {
		// console.log((evt.target as HTMLInputElement).value);
		emit("update:inputValue", (evt.target as HTMLInputElement).value);
	},
	500,
	false,
);
</script>
<template>
	<div class="input-wrapper">
		<div class="floating-label">
			{{ label }}
		</div>
		<div class="input-wrap" :class="{ disabled: disabled }">
			<div v-if="type === 'search'" class="img-wrap">
				<img :src="images.searchImg" alt="" />
			</div>
			<input
				v-if="type != `textarea`"
				:type="type === 'search' ? 'text' : type"
				:placeholder="placeholder"
				:value="inputValue"
				:class="{ 'with-img': type === 'search' }"
				:disabled="disabled"
				@input="baa"
			/>
			<textarea
				v-else
				:placeholder="placeholder"
				:value="inputValue"
				:disabled="disabled"
				@input="baa"
			></textarea>
		</div>
	</div>
</template>
<style scoped lang="scss">
.input-wrapper {
	.floating-label {
		color: $subtitle-text;
		font-size: 12px;
		font-weight: 500;

		// line-height: 20px;
		margin-bottom: 5px;
	}

	.input-wrap {
		position: relative;

		.img-wrap {
			position: absolute;
			top: 50%;
			left: 17px;
			transform: translateY(-50%);
		}

		input {
			width: 100%;
			height: 48px;
			border: 1px solid $default-gray;
			border-radius: 7px;
			outline: none;
			background: transparent;
			font-size: 14px;
			font-weight: 500;
			color: $primary-text;
			padding-left: 17px;

			&::placeholder {
				font-family: Poppins, sans-serif;
				color: $subtitle-text;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
			}
		}

		.with-img {
			padding-left: 40px;
		}

		textarea {
			width: 100%;
			height: 160px;
			border: 1px solid $default-gray;
			border-radius: 7px;
			outline: none;
			background: transparent;
			font-size: 14px;
			font-weight: 500;
			color: $primary-text;
			padding: 14px 17px;
			resize: none;

			&::placeholder {
				color: $subtitle-text;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
			}
		}

		&.disabled {
			input,
			textarea {
				cursor: not-allowed;
				color: $subtitle-text;
			}
		}
	}
}
</style>
