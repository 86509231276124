<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();

const props = withDefaults(
	defineProps<{
		id: number;
		fullname: string;
		phone: string;
		email: string;
	}>(),
	{
		fullname: "",
		phone: "",
		email: "",
	},
);
const emit = defineEmits(["close-modal"]);

const editParams = ref<Record<string, any>>({
	fullname: "",
	phone: "",
	email: "",
});

const canSubmit = computed(() => {
	return (
		editParams.value.fullname !== "" &&
		editParams.value.phone !== "" &&
		editParams.value.email !== ""
	);
});

onMounted(() => {
	if (props.fullname) editParams.value.fullname = props.fullname;
	if (props.phone) editParams.value.phone = props.phone;
	if (props.email) editParams.value.email = props.email;
});

const handleEdit = async () => {
	if (
		editParams.value.fullname === "" &&
		editParams.value.email === "" &&
		editParams.value.phone === ""
	) {
		return;
	}
	for (const key in editParams.value) {
		if (editParams.value[key] === "") {
			editParams.value[key] = props[key as keyof typeof props];
		}
	}
	await apiStore.callPatchUser(props.id, editParams.value);
	emit("close-modal");
};
</script>
<template>
	<div class="edit-admin-wrapper">
		<div class="inputs-wrap">
			<CustomInput
				type="text"
				label="Ime i prezime *"
				placeholder="Unesite ime i prezime"
				:input-value="fullname"
				@update:input-value="editParams.fullname = $event"
			/>
			<CustomInput
				type="text"
				label="Kontakt telefon"
				placeholder="Unesite kontakt telefon"
				:input-value="phone"
				@update:input-value="editParams.phone = $event"
			/>
			<CustomInput
				type="text"
				label="Email *"
				placeholder="Unesite email"
				:input-value="email"
				@update:input-value="editParams.email = $event"
			/>
		</div>
		<div class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				:disabled="!canSubmit"
				small
				@btn-click="handleEdit"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-admin-wrapper {
	width: 286px;

	.inputs-wrap {
		display: flex;
		flex-direction: column;
		margin: 30px 0;
		gap: 10px 0;
	}

	.btn-wrap {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
