import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import SchedulePage from "@/views/SchedulePage.vue";
import ServicesPage from "@/views/ServicesPage.vue";
import UsersPage from "@/views/UsersPage.vue";
import AdminPage from "@/views/AdminPage.vue";
import AuthPage from "../views/Auth/AuthPage.vue";
import LoginPage from "../views/Auth/Sub/LoginPage.vue";
import PageNotFound from "../views/PageNotFound.vue";
import TechnitiansPage from "@/views/TechnitiansPage.vue";
import ActivitiesPage from "@/views/ActivitiesPage.vue";
import ReportsPage from "@/views/ReportsPage.vue";
import NotificationsPage from "@/views/NotificationsPage.vue";
import PackagesPage from "@/views/PackagesPage.vue";
import ForgotPasswordPage from "@/views/Auth/Sub/ForgotPasswordPage.vue";
import { isAuthenticated } from "@/assets/js/helpers";
import ChangePasswordPage from "@/views/Auth/Sub/ChangePasswordPage.vue";
import VerifyEmailPage from "@/views/Auth/Sub/VerifyEmailPage.vue";
import TechnicianViewPageVue from "@/views/TechnicianViewPage.vue";
import SystemNotificationsPage from "@/views/SystemNotificationsPage.vue";
import AtHomeServicesPage from "@/views/AtHomeServicesPage.vue";
import DeleteAccountPage from "@/views/DeleteAccountPage.vue";
// import { useDefaultStore } from "@/store";

const routes = [
	{
		path: "/",
		name: "HomePage",
		component: HomePage,
		children: [
			{
				path: "schedule",
				name: "SchedulePage",
				component: SchedulePage,
			},
			{
				path: "services",
				name: "ServicesPage",
				component: ServicesPage,
			},
			{
				path: "users",
				name: "UsersPage",
				component: UsersPage,
			},
			{
				path: "admin",
				name: "AdminPage",
				component: AdminPage,
			},
			{
				path: "technicians",
				name: "TechniciansPage",
				component: TechnitiansPage,
			},
			{
				path: "activities",
				name: "ActivitiesPage",
				component: ActivitiesPage,
			},
			{
				path: "reports",
				name: "ReportsPage",
				component: ReportsPage,
			},
			{
				path: "notifications",
				name: "NotificationsPage",
				component: NotificationsPage,
			},
			{
				path: "packages",
				name: "PackagesPage",
				component: PackagesPage,
			},
			{
				path: "system-notifications",
				name: "SystemNotificationsPage",
				component: SystemNotificationsPage,
			},
			{
				path: "at-home-services",
				name: "AtHomeServicesPage",
				component: AtHomeServicesPage,
			},
		],
	},
	{
		path: "/auth",
		name: "AuthPage",
		component: AuthPage,
		children: [
			{
				path: "login",
				name: "LoginPage",
				component: LoginPage,
			},
			{
				path: "forgot-password",
				name: "ForgotPasswordPage",
				component: ForgotPasswordPage,
			},
		],
	},
	{
		path: "/mail/password/:id?",
		name: "ChangePasswordPage",
		component: ChangePasswordPage,
	},
	{
		path: "/mail/verify/:id?",
		name: "VerifyEmailPage",
		component: VerifyEmailPage,
	},
	{
		path: "/technician-schedule",
		name: "TechnicianViewPageVue",
		component: TechnicianViewPageVue,
	},
	{
		path: "/obrisi-nalog",
		name: "DeleteAccountForm",
		component: DeleteAccountPage,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from) => {
	console.log("usao sam ovde");

	if (
		!isAuthenticated &&
		to.name !== "LoginPage" &&
		to.name !== "ForgotPasswordPage" &&
		to.name !== "ChangePasswordPage" &&
		to.name !== "VerifyEmailPage" &&
		to.name !== "DeleteAccountForm"
	) {
		console.log("true je", to.name);

		return { name: "LoginPage" };
	} else {
		console.log("false je", to.name);
	}
	// useDefaultStore().stopLoading();
});

// router.beforeEach((to, from) => {});

export default router;
