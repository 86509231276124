<script setup lang="ts">
import { downloadFile, getLocalStorageReac } from "@/assets/js/helpers";
import images from "@/assets/js/images";
import type { IChartAxis, IMargin, TLooseObject } from "@/assets/js/types";
import { useApiStore } from "@/store/api";
import { Chart, Grid, Line } from "vue3-charts";
import locale_rs from "dayjs/locale/sr";
import dayjs from "dayjs";

dayjs.locale(locale_rs);

const apiStore = useApiStore();

const loyaltyId = ref<string>("");
const selectedDateFrom = ref<any>(dayjs());
const selectedDateTo = ref<any>(dayjs());
const graphData = ref<TLooseObject[] | any>([]);
const date = ref(new Date("2021-09-12"));
const graphMargin = ref<IMargin>({
	top: 20,
	right: 20,
	bottom: 20,
	left: 20,
});
const axis = ref<IChartAxis>({
	// @ts-expect-error
	primary: {
		type: "band",
		format: (val: any) => dayjs().month(val).format("MMM"),
	},
	secondary: {
		domain: ["dataMin", "dataMax + 50"],
		type: "linear",
		ticks: 8,
	},
});

onMounted(async () => {
	await getInfo();
});

watch([loyaltyId, selectedDateFrom, selectedDateTo], async () => {
	console.log("watched");

	await getInfo();
});

const getInfo = async () => {
	const params: TLooseObject = {
		dateFrom: selectedDateFrom.value.format("YYYY-MM-DD"),
		dateTo: selectedDateTo.value.format("YYYY-MM-DD"),
	};

	if (loyaltyId.value !== "") params.loyaltyid = loyaltyId.value;

	const result = await apiStore.callGetLoyalty(params);
	if (result) graphData.value = result;
	console.log(graphData.value);
};

const downloadReport = async () => {
	const params: TLooseObject = {
		sid: getLocalStorageReac("sid")?.value as string,
		dateFrom: selectedDateFrom.value.format("YYYY-MM-DD"),
		dateTo: selectedDateTo.value.format("YYYY-MM-DD"),
		isdownload: true,
	};

	if (loyaltyId.value !== "") params.loyaltyid = loyaltyId.value;

	const stringToSend = `/loyalty/spent-data?${new URLSearchParams(
		params,
	).toString()}`;

	downloadFile(stringToSend);
};

function findMaxTotal(arr: any[]) {
	if (arr.length === 0) {
		return null; // Return null for an empty array
	}

	// let maxTotal = arr[0].total; // Initialize maxTotal with the first object's total

	// for (let i = 1; i < arr.length; i++) {
	// 	const currentTotal = arr[i].total;

	// 	if (currentTotal > maxTotal) {
	// 		maxTotal = currentTotal; // Update maxTotal if we find a larger total
	// 	}
	// }

	// return maxTotal;
	return arr.at(-1).total;
}
</script>
<template>
	<div class="reports-page-wrapper">
		<div class="inputs-header">
			<div class="inputs-wrap">
				<CustomInput
					type="text"
					label="Loyalty ID"
					placeholder="Loyalty ID"
					:input-value="loyaltyId"
					@update:input-value="loyaltyId = $event"
				/>
				<!-- <CustomDatepicker v-model="selectedDateFrom" label="Datum" /> -->
				<ModernDatepicker
					label="Datum"
					placeholder="Datum od"
					:input-value="selectedDateFrom"
					@update:model-value="selectedDateFrom = $event"
				/>
				<span>_</span>
				<!-- NOTE - this is an empty character -->
				<!-- its here because if the label is not present -->
				<!-- the style is fucked a lil -->
				<!-- so this works : ) -->
				<!-- <CustomDatepicker v-model="selectedDateTo" label="‎" /> -->
				<ModernDatepicker
					label="‎"
					placeholder="Datum do"
					:input-value="selectedDateTo"
					@update:model-value="selectedDateTo = $event"
				/>
				<!-- <VueDatePicker v-model="date"></VueDatePicker> -->
			</div>
			<CustomButton
				text="Izvezi"
				:icon="images.exportImg"
				small
				@btn-click="downloadReport"
			/>
		</div>
		<div class="graph-wrapper">
			<Chart
				v-if="graphData.length > 0"
				:size="{ width: 1200, height: 600 }"
				direction="horizontal"
				:margin="graphMargin"
				:data="graphData"
				:axis="axis"
			>
				<template #layers>
					<Grid stroke-dasharray="1,1" :hide-y="false" />
					<Line
						:data-keys="['month', 'val']"
						type="natural"
						:line-style="{ stroke: '#172841' }"
					/>
					<LabelsLayer :data-keys="['month', 'val']" />
				</template>
				<template #widgets>
					<div class="graph-footer">
						Potrošen broj poena: {{ findMaxTotal(graphData) }}
					</div>
				</template>
			</Chart>
			<div v-else class="no-data">Nema podataka za prikaz</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.reports-page-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.inputs-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		.inputs-wrap {
			display: flex;
			align-items: center;
			gap: 20px;
			width: 100%;

			span {
				height: 100%;
				text-align: center;
			}

			:deep(.input-wrapper) {
				width: 150px;
			}
		}
	}

	.graph-wrapper {
		.no-data {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			font-weight: 500;
			color: $primary-text;
		}
	}
}
</style>
