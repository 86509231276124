<script setup lang="ts">
import type { TLooseObject } from "@/assets/js/types";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import dayjs from "dayjs";

const store = useDefaultStore();
const apiStore = useApiStore();

const schedule = ref<any[]>([]);
const showInfo = ref<boolean>(false);
const orderToShow = ref<any>({});
const date = ref<any>(dayjs());

onMounted(async () => {
	await init();
	setInterval(
		() => {
			void getUnseen();
		},
		1000 * 60 * 3,
	);
});

watch(date, async () => {
	await init();
});

watch(
	() => store.getShouldRefresh,
	async () => {
		if (store.getShouldRefresh) {
			await init();
			store.setShouldRefresh(false);
		}
	},
);

const init = async () => {
	const paramObj: TLooseObject = {
		defaultTimes: false,
		dateFrom: date.value.format("YYYY-MM-DD"),
		dateTo: date.value.format("YYYY-MM-DD"),
	};

	const result = await apiStore.callGetAvailabilities(paramObj, false);

	schedule.value = result;
};

const getUnseen = async () => {
	const result = await apiStore.callGetUnseenNotificationCount("2,3,4");
};

const toggleInfo = (order: any) => {
	if (order.utt_id !== 3) return;
	orderToShow.value = order;
	showInfo.value = true;
};

const handleModalClose = async (reload?: boolean) => {
	showInfo.value = false;
	if (reload) await init();
};
</script>
<template>
	<div class="technician-schedule-page-wrapper">
		<CardInfoModal
			v-if="showInfo"
			status="locked"
			:order-id="orderToShow.ord_id"
			:ticket-id="orderToShow.utk_id"
			:can-complete="true"
			@close-modal="handleModalClose"
		/>
		<HeaderComp
			page-title="Laboratorijski termini"
			:show-logo="true"
			:has-unread="store.getHasUnreadMessages"
		/>
		<div class="datepicker-wrap">
			<ModernDatepicker
				placeholder="Datum"
				:input-value="date"
				min-date
				@update:model-value="date = $event"
			/>
		</div>
		<div v-if="schedule.length > 0" class="schedule-wrap">
			<div v-for="(avl, index) in schedule" :key="index" class="drop-zone-wrap">
				<span>{{ avl.from.slice(0, 2) + "-" + avl.to.slice(0, 2) + "h" }}</span>
				<SlotCard
					v-for="(ticket, jindex) in avl.usertickets"
					:key="jindex"
					:name="ticket.ord_fullname"
					:phone="ticket.ord_phone"
					:locked="ticket.utt_id === 3"
					:card-type="ticket.utt_id"
					:show-btn="false"
					:service-i-d="2"
					:card-tag="ticket.orderitems[0]?.itm_tag"
					:is-completed="ticket.utk_status === 'CMPL' && ticket.utt_id === 3"
					@open-modal="toggleInfo(ticket)"
				/>
			</div>
		</div>
		<div v-else class="nothing">
			<div class="nothing-text">Nema dodeljenih termina</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.technician-schedule-page-wrapper {
	.datepicker-wrap {
		width: 100%;
		padding: 20px;
		padding-left: 25px;
		border-bottom: 1px solid #dfdfe8;
	}

	.schedule-wrap {
		.drop-zone-wrap {
			padding: 30px 40px;
			width: 100%;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #dfdfe8;
			gap: 20px;

			span {
				margin-right: 20px;
			}
		}
	}

	.nothing {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.nothing-text {
			font-size: 20px;
			color: #a0a0a0;
			margin-top: 50px;
		}
	}
}
</style>
