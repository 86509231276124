<script setup lang="ts">
import { type TNameIDPair } from "@/assets/js/types";
import { type TTableColumn } from "@/components/Shared/modernTable.vue";
import { useDefaultStore } from "@/store";
import images from "@/assets/js/images";
import dayjs from "dayjs";
import { useApiStore } from "@/store/api";
import { openEditPackageModal } from "@/components/modals/modalDefinitions";

const store = useDefaultStore();
const apiStore = useApiStore();

const serviceInput = ref<TNameIDPair>({
	id: 1,
	label: "Svi pregledi",
});
const selectedDate = ref<any>();
const searchInput = ref<string>("");
const currentPage = ref<number>(1);
const orders = ref<any[]>([]);
const extraRowOrders = ref<any[]>([]);
const filteredOrders = ref<any[]>([]);
const tableColumns = ref<TTableColumn[]>([
	{
		id: "service",
		label: "Usluga",
	},
	{
		id: "name",
		label: "Ime i prezime korisnika",
	},
	{
		id: "nameOfPatient",
		label: "Ime i prezime pacijenta",
	},
	{
		id: "date",
		label: "Datum rezervisanja",
	},
	{
		id: "phoneOfPatient",
		label: "Kontakt telefon pacijenta",
	},
	{
		id: "emailOfPatient",
		label: "Email pacijenta",
	},
	{
		id: "dob",
		label: "Datum rođenja pacijenta",
	},
	{
		id: "isCouponUsed",
		label: "Iskorišćen kupon",
	},
	{
		id: "isPaid",
		label: "Plaćeno online",
	},
	{
		id: "expand",
		label: "",
	},
]);
const showRowIndex = ref<number[]>([]);
const servicesOptions = ref<TNameIDPair[]>([
	{
		id: 1,
		label: "Svi pregledi",
	},
]);
const meta = ref<any>({});
const base = 20;

// FIXME - this varaiable is used to prevent the loader edge case
// the loader stopLoading() function is called from this component
// after the API calls of this component are done thus
// stopping the loader before the API calls of the other components are done
// this "hack" solves it by checking if this component is still mounted
// before stopping the loader
const hack = ref<boolean>(false);

watch(
	() => servicesOptions.value,
	() => {
		if (servicesOptions.value.length > 1) {
			// console.log("odradio sam nesto jebes ga");

			for (const el of filteredOrders.value) {
				el.service = servicesOptions.value.find(
					(item) => item.id === el.service,
				)?.label;
			}
		}
	},
);

watch([searchInput, serviceInput, selectedDate], async () => {
	let result: any = {};
	result = await (serviceInput.value.id === 1
		? apiStore.callGetPackages(
				[4],
				1,
				searchInput.value,
				selectedDate.value
					? dayjs(selectedDate.value).format("YYYY-MM-DD")
					: "",
			)
		: apiStore.callGetPackages(
				[4],
				1,
				searchInput.value,
				selectedDate.value
					? dayjs(selectedDate.value).format("YYYY-MM-DD")
					: "",
				serviceInput.value.id,
			));
	orders.value = result;
	filteredOrders.value = orders.value;
	currentPage.value = 1;
	if (store.getServices.length > 0) {
		// for (const el of filteredOrders.value) {
		// 	el.service = store.getServices.find((item) => item.id === el.service)
		// 		?.label;
		// }
		for (const el of filteredOrders.value) {
			el.service = servicesOptions.value.find((item) => item.id === el.service)
				?.label;
		}
	}
});

onMounted(async () => {
	hack.value = true;
	await init();
});

onBeforeUnmount(() => {
	hack.value = false;
});

const init = async () => {
	store.startLoading();
	showRowIndex.value = [];
	extraRowOrders.value = [];
	meta.value = await apiStore.callGetOrdersMetaData("4");
	const result = await apiStore.callGetPackages([4], currentPage.value);
	// console.log(result);

	orders.value = result;
	filteredOrders.value = orders.value;

	// console.log(filteredOrders.value);

	if (servicesOptions.value.length > 1) {
		for (const el of filteredOrders.value) {
			el.service = servicesOptions.value.find((item) => item.id === el.service)
				?.label;
			// console.log(el.service);
		}
	}
	const tempItems: TNameIDPair[] = await apiStore.callGetAtHomeServiceNames();
	servicesOptions.value = [servicesOptions.value[0], ...tempItems];
	// console.log(servicesOptions.value);

	if (hack.value) store.stopLoading();
};

const cutFilteredOrders = computed(() => {
	return filteredOrders.value.slice(
		base * (currentPage.value - 1),
		base * currentPage.value,
	);
});

const toggleShowExtraRow = async (index: number) => {
	if (showRowIndex.value.includes(index)) {
		extraRowOrders.value = extraRowOrders.value.filter(
			(item) => item.id !== index,
		);
		showRowIndex.value = showRowIndex.value.filter((item) => item !== index);
	} else {
		const result = await apiStore.callGetOrder(index);
		extraRowOrders.value.push(result);
		showRowIndex.value.push(index);
	}
};

const returnOrder = (id: number) => {
	return extraRowOrders.value.find((item) => item.ord_id === id);
};

const changePage = async (evt: any) => {
	currentPage.value = evt;
	await init();
};

const handleUsed = async (id: number, isUsed: boolean) => {
	if (isUsed) return;
	const result = await apiStore.callPatchOrderCoupon(id);
	await init();
};

const handleReservation = (order: any, location: string) => {
	console.log("Reservation button clicked");
	const modalParams = {
		name: order.ord_fullname,
		serviceName: order.orderitems[0].itm_name,
		areaID: store.getAreas.find((item) => item.label === location)?.id,
		orderID: order.ord_id,
	};
	openEditPackageModal(modalParams, init);
};
</script>
<template>
	<LoaderComp v-if="store.getIsLoading" />
	<div v-else class="packages-wrapper">
		<div class="inputs-header">
			<div class="left-inputs">
				<div class="dropdown-wrap">
					<div class="dropdown-label">
						<span>Naziv pregleda</span>
					</div>
					<div class="dropdown">
						<CustomDropdown
							v-model="serviceInput"
							label="label"
							placeholder="Usluga"
							:options="servicesOptions"
							:value-prop="'id'"
						/>
					</div>
				</div>
				<ModernDatepicker
					label="Datum rezervacije usluge"
					placeholder="Datum"
					:input-value="selectedDate"
					:can-clear="true"
					@update:model-value="selectedDate = $event"
				/>
			</div>
			<CustomInput
				type="search"
				label="‎"
				placeholder="Pretraga pacijenata..."
				:input-value="searchInput"
				@update:input-value="searchInput = $event"
			/>
		</div>
		<div class="table-wrap">
			<ModernTable
				:columns="tableColumns"
				:rows="cutFilteredOrders"
				:is-infinite-scroll="false"
				:has-border="false"
				:display-additional-row="true"
			>
				<template #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'isCouponUsed'">
						<div class="checkmark-wrap">
							<div
								class="checkmark"
								:class="{ checked: slotProps.row.isCouponUsed }"
								@click="
									handleUsed(slotProps.row.id, slotProps.row.isCouponUsed)
								"
							>
								<img :src="images.checkSmall" alt="" />
							</div>
						</div>
					</td>
					<td v-else-if="slotProps.column.id === 'isPaid'">
						<div class="checkmark-wrap">
							<div
								class="checkmark nuh-uh"
								:class="{ checked: slotProps.row.isPaid }"
							>
								<img :src="images.checkSmall" alt="" />
							</div>
						</div>
					</td>
					<td v-else-if="slotProps.column.id === 'expand'">
						<div
							class="chevron-wrap"
							@click="toggleShowExtraRow(slotProps.row.id)"
						>
							<img :src="images.downArrow" alt="kekes" />
						</div>
					</td>
				</template>
				<template #table-body-extra-row-content="extraProps">
					<transition name="show-row">
						<td
							v-if="showRowIndex.includes(extraProps.row.id)"
							:colspan="tableColumns.length"
							class="info-row"
						>
							<PackageExtraInfo
								:gender="extraProps.row.gender"
								:address="extraProps.row.address"
								:dates="extraProps.row.description"
								:email="extraProps.row.email"
								:location="extraProps.row.location"
								:phone="extraProps.row.phone"
								:note="extraProps.row.note"
								:price="returnOrder(extraProps.row.id).orderitems[0].itm_price"
								:package-name="
									returnOrder(extraProps.row.id).orderitems[0].itm_name
								"
								:payment="extraProps.row.payment"
								:order-i-d="extraProps.row.orderID"
								:order-booked="returnOrder(extraProps.row.id)"
								:show-reservation-btn="true"
								@reservation="
									handleReservation(
										returnOrder(extraProps.row.id),
										extraProps.row.location,
									)
								"
							/>
						</td>
					</transition>
				</template>
			</ModernTable>
		</div>
		<PaginationComp
			:page="currentPage"
			:pages="meta.total_pages"
			prev-text="Nazad"
			next-text="Dalje"
			@update:page="changePage"
		/>
		<!-- @update:page="updatePage($event)" -->
	</div>
</template>
<style scoped lang="scss">
.packages-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.inputs-header {
		display: flex;
		justify-content: space-between;

		.left-inputs {
			display: flex;
			gap: 15px;

			.dropdown-wrap {
				width: 300px;
			}
		}

		:deep(.input-wrapper) {
			width: 300px;
		}
	}

	.table-wrap {
		width: 100%;
		height: calc(100% - 100px);

		// max-height: calc(100% - 100px);
		// overflow: auto;
		padding-top: 30px;
		margin-bottom: 20px;

		.checkmark-wrap {
			display: flex;
			justify-content: center;

			.checkmark {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				border: 1px solid $main-green;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&.checked {
					background-color: $main-green;
				}

				&.nuh-uh {
					cursor: initial;
				}
			}
		}

		.chevron-wrap {
			padding-right: 10px;
			cursor: pointer;
		}
	}

	:deep(.pagination-wrapper) {
		width: 100%;
		justify-content: center;
	}
}

.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
