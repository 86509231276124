<script setup lang="ts">
import images from "@/assets/js/images";
import type { TNameIDPair } from "@/assets/js/types";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";

const store = useDefaultStore();
const apiStore = useApiStore();

const emit = defineEmits(["close-modal"]);

const selectedLocation = ref<TNameIDPair>({ id: 0, label: "" });
const addParams = ref<Record<string, any>>({
	fullname: "",
	phone: "",
	email: "",
	password: "",
	location: 0,
});

const areAllFieldsFilled = computed(
	() =>
		addParams.value.fullname !== "" &&
		addParams.value.phone !== "" &&
		addParams.value.email !== "" &&
		addParams.value.password !== "" &&
		selectedLocation.value.id > 0,
);

const handleCreateTechnician = async () => {
	addParams.value.location = selectedLocation.value.id;
	await apiStore.callPostTechnician(addParams.value);
	emit("close-modal");
};
</script>
<template>
	<div class="add-technician-wrapper">
		<div class="inputs-wrap">
			<CustomInput
				type="text"
				label="Ime i prezime *"
				placeholder="Unesite ime i prezime"
				:input-value="addParams.fullname"
				@update:input-value="addParams.fullname = $event"
			/>
			<CustomInput
				type="text"
				label="Kontakt telefon *"
				placeholder="Unesite kontakt telefon"
				:input-value="addParams.phone"
				@update:input-value="addParams.phone = $event"
			/>
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Lokacija</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedLocation"
						:label="'label'"
						:placeholder="'Odaberite lokaciju'"
						:options="store.getTechnicianLocations"
						:value-prop="'id'"
					/>
				</div>
			</div>
			<CustomInput
				type="text"
				label="Email *"
				placeholder="Unesite email"
				:input-value="addParams.email"
				@update:input-value="addParams.email = $event"
			/>
			<CustomInput
				type="text"
				label="Lozinka *"
				placeholder="Unesite lozinku"
				:input-value="addParams.password"
				@update:input-value="addParams.password = $event"
			/>
		</div>
		<div class="btns-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				small
				:disabled="!areAllFieldsFilled"
				@btn-click="handleCreateTechnician"
			/>
			<CustomButton
				text="Odustani"
				:icon="images.closeImg"
				small
				@btn-click="$emit('close-modal')"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.add-technician-wrapper {
	margin-top: 30px;
	display: flex;

	.inputs-wrap {
		width: 300px;
		display: flex;
		flex-direction: column;
		gap: 10px 0;
		margin-right: 50px;

		.dropdown-wrap {
			width: 100%;
		}
	}

	.btns-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px 0;
	}
}
</style>
