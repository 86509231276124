import type { IAuthParams } from "@/assets/js/types";
import type { AxiosInstance } from "axios";
import { axios } from "./config.js";

interface IApi {
	login: (params: IAuthParams) => Promise<any>;
	checkSession: () => Promise<any>;
	logoutApi: () => Promise<any>;
	resetPassword: (params: { email: string }) => Promise<any>;
	verifyEmail: (t: string) => Promise<any>;
	submitResetPasword: (params: { t: string; password: string }) => Promise<any>;
	getAreas: () => Promise<any>;
	getServices: () => Promise<any>;
	getAllGroups: () => Promise<any>;
	getGroups: (params: { service: number }) => Promise<any>;
	getUserStatuses: () => Promise<any>;
	getTechnicianLocations: () => Promise<any>;
	getPaymentTypes: () => Promise<any>;
	getAvailabilities: (params: any) => Promise<any>;
	patchAvailability: (id: number, params: any) => Promise<any>;
	getUnsortedOrders: () => Promise<any>;
	patchOrderAvailability: (id: number, params: any) => Promise<any>;
	patchOrderCoupon: (id: number, isUsed: boolean) => Promise<any>;
	getOrder: (id: number) => Promise<any>;
	getAvailableTechnicians: (id: number) => Promise<any>;
	postUserTicket: (params: any) => Promise<any>;
	patchOrderApprove: (id: number) => Promise<any>;
	getOrdersMetaData: (service: string | number) => Promise<any>;
	getItems: (params: any) => Promise<any>;
	postItem: (param: any) => Promise<any>;
	getPackages: (
		service: string,
		page: number,
		search?: string,
		date?: string,
		items?: number,
	) => Promise<any>;
	patchItemActive: (id: number, params: any) => Promise<any>;
	deleteItem: (id: number) => Promise<any>;
	postService: (params: { name: string }) => Promise<any>;
	patchService: (id: number, params: { name: string }) => Promise<any>;
	deleteService: (id: number) => Promise<any>;
	postItemGroup: (params: { name: string; service: number }) => Promise<any>;
	patchItemGroup: (id: number, params: { name: string }) => Promise<any>;
	deleteItemGroup: (id: number) => Promise<any>;
	getAtHomeServiceNames: () => Promise<any>;
	getAllUsers: () => Promise<any>;
	getUsers: () => Promise<any>;
	getAdmins: (options?: any) => Promise<any>;
	getTechnicians: (options?: any) => Promise<any>;
	getPatients: (options?: any, isActive?: boolean) => Promise<any>;
	patchUserStatus: (id: number, statusNumber: number) => Promise<any>;
	patchUser: (id: number, params: any) => Promise<any>;
	postUser: (params: any) => Promise<any>;
	deleteUser: (id: number) => Promise<any>;
	getNotifications: (page: number) => Promise<any>;
	postNotification: (params: any) => Promise<any>;
	patchNotification: (id: number, params: any) => Promise<any>;
	deleteNotification: (id: number) => Promise<any>;
	getSystemNotifications: (page: number) => Promise<any>;
	getUnseenNotificationCount: (messageType: string) => Promise<any>;
	postMarkAllAsRead: (messageType: number[]) => Promise<any>;
	getLoyalty: (params?: any) => Promise<any>;
	getReport: (params: any) => Promise<any>;
	getUserTicketTypes: () => Promise<any>;
	postUserTicketType: (params: {
		user: number;
		usertickettype: 1 | 2;
		availability: number;
	}) => Promise<any>;
	deleteUserTicket: (id: number) => Promise<any>;
	patchSortOrder: (params: any) => Promise<any>;
	patchUserTicketStatus: (id: number) => Promise<any>;
	test: (params: any) => Promise<any>;
	postDeleteAccountForm: (params: any) => Promise<any>;
}

const api: IApi = {
	// Auth
	login: (params: IAuthParams) => axios.post("/auth/login", params),
	checkSession: () => axios.get("/auth/check-session"),
	logoutApi: () => axios.post("/auth/logout"),
	resetPassword: (params: { email: string }) =>
		axios.get("/auth/reset-password", { params }),
	verifyEmail: (t: any) => axios.post("/auth/verify-email", t),
	submitResetPasword: (params: { t: string; password: string }) =>
		axios.post("/auth/reset-password", params),
	// Get Info
	getAreas: () => axios.get("/area"),
	getServices: () => axios.get("/services"),
	getAllGroups: () => axios.get("/itemgroups"),
	getGroups: (params: { service: number }) =>
		axios.get("/itemgroups", { params }),
	getUserStatuses: () => axios.get("/userstatuses"),
	getTechnicianLocations: () => axios.get("/locations/for-technician"),
	getPaymentTypes: () => axios.get("/paymenttypes"),
	// Schedule
	getAvailabilities: (params: any): any =>
		axios.get("/availabilities", { params }),
	patchAvailability: (id: number, params: any): any =>
		axios.patch(`/availabilities/${id}`, params),
	getUnsortedOrders: (): any =>
		axios.get("/orders", { params: { getUnsorted: true, service: "1,4" } }),
	patchOrderAvailability: (id: number, params: any): any =>
		axios.patch(`/orders/${id}`, params),
	patchOrderCoupon: (id: number, isUsed: boolean) =>
		axios.patch(`/orders/${id}`, { confirm: isUsed }),
	getOrder: (id: number): any => axios.get(`/orders/${id}`),
	getAvailableTechnicians: (id: number) =>
		axios.get(`/availabilities/${id}/get-available-users`),
	postUserTicket: (params: any) => axios.post("/usertickets", params),
	patchOrderApprove: (id: number) =>
		axios.post("/ordertime/approve", { ordertime: id }),
	getOrdersMetaData: (service: string | number) =>
		axios.get("/orders", { params: { service, metaOnly: 1 } }),
	// Services
	getItems: (params: any) => axios.get("/items", { params }),
	postItem: (param: any) => axios.post("/items", param),
	getPackages: (
		service: string,
		page: number,
		search?: string,
		date?: string,
		items?: number,
	) => {
		return axios.get("/orders", {
			params: {
				service,
				page,
				search,
				items,
				orderCreatedDate: date,
				per_page: 20,
			},
		});
	},
	patchItemActive: (id: number, params: any) =>
		axios.patch(`/items/${id}`, params),
	deleteItem: (id: number) => axios.delete(`/items/${id}`),
	postService: (params: { name: string }) => axios.post("/services", params),
	patchService: (id: number, params: { name: string }) =>
		axios.patch(`/services/${id}`, params),
	deleteService: (id: number) => axios.delete(`/services/${id}`),
	postItemGroup: (params: { name: string; service: number }) =>
		axios.post("/itemgroups", params),
	patchItemGroup: (id: number, params: { name: string }) =>
		axios.patch(`/itemgroups/${id}`, params),
	deleteItemGroup: (id: number) => axios.delete(`/itemgroups/${id}`),
	getAtHomeServiceNames: () => axios.get("/items", { params: { service: 4 } }),
	// Users
	getAllUsers: () => axios.get("/users", { params: { active: false } }),
	getUsers: () => axios.get("/users", { params: { active: false } }),
	getAdmins: (options?: any) =>
		axios.get("/users", { params: { ...options, rol_id: 900, active: true } }),
	getTechnicians: (options?: any) =>
		axios.get("/users", { params: { ...options, rol_id: 800, active: true } }),
	getPatients: (options?: any, isActive?: boolean) =>
		axios.get("/users", {
			params: { ...options, rol_id: 10, active: isActive },
		}),
	patchUserStatus: (id: number, statusNumber: number) =>
		axios.patch(`/users/${id}`, { userstatus: statusNumber }),
	patchUser: (id: number, params: any) => axios.patch(`/users/${id}`, params),
	postUser: (params: any) => axios.post("/users", params),
	deleteUser: (id: number) => axios.delete(`/users/${id}`),
	// Notifications
	getNotifications: (page: number) =>
		axios.get("/messages", { params: { page } }),
	postNotification: (params: any) => axios.post("/messages", params),
	patchNotification: (id: number, params: any) =>
		axios.patch(`/messages/${id}`, params),
	// NOTE - don't know if this is temporary, but here goes nothign
	deleteNotification: (id: number) =>
		axios.patch(`/messages/${id}`, { delete: true }),
	getSystemNotifications: (page: number) =>
		axios.get("/messages", { params: { messagetypes: "2,3,4", page } }),
	getUnseenNotificationCount: (messageType: string) =>
		axios.get("/messages/get-unseen-count", {
			params: { messagetypes: messageType },
		}),
	postMarkAllAsRead: (messageType: number[]) =>
		axios.post("/messages/set-all-as-seen", { messagetypes: messageType }),
	// Loyalty
	getLoyalty: (params?: any) =>
		axios.get("/loyalty/spent-data", params ? { params } : {}),
	getReport: (params: any) => axios.get("/loyalty/spent-data", { params }),
	// Tehnicians
	getUserTicketTypes: () => axios.get(`/usertickettypes`),
	postUserTicketType: (params: {
		user: number;
		usertickettype: 1 | 2;
		availability: number;
	}) => axios.post(`/usertickets`, params),
	deleteUserTicket: (id: number) => axios.delete(`/usertickets/${id}`),
	patchSortOrder: (params: any) => axios.patch(`/usertickets`, params),
	patchUserTicketStatus: (id: number) =>
		axios.patch(`/usertickets/${id}`, { status: "CMPL" }),
	test: (params: any) => axios.post("/availabilities", params),
	// Delete Account
	postDeleteAccountForm: (params: any) =>
		axios.post("/email/delete-account-form", params),
};

export default api;
