<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";
import { useInfiniteScroll } from "@vueuse/core";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const apiStore = useApiStore();

const notifications = ref<HTMLElement | null>(null);

const notificationID = ref<number>(0);
const notificationTitle = ref<string>("");
const notificationBody = ref<string>("");
const notificationsList = ref<any[]>([]);
const currentPage = ref<number>(1);
const shouldLoadMore = ref<boolean>(false);
const isEditing = ref<boolean>(false);

// FIXME - at this point i just lost all hope
const hack = ref<boolean>(true);

onMounted(async () => {
	await getMessages();
});

useInfiniteScroll(
	notifications,
	async () => {
		if (hack.value) {
			hack.value = false;
			return;
		}
		currentPage.value++;
		await getNextMessages();
	},
	{
		distance: 10,
		interval: 500,
		canLoadMore: () => shouldLoadMore.value,
	},
);

const areFieldsFilled = computed(() => {
	return notificationTitle.value !== "" && notificationBody.value !== "";
});

const getNextMessages = async () => {
	console.log(currentPage.value);

	const result = await apiStore.callGetNotifications(currentPage.value);
	notificationsList.value = [...notificationsList.value, ...result.data];
	// shouldLoadMore.value =
	// 	result.meta.pagination.pagination.total_pages > currentPage.value;
	shouldLoadMore.value = result.data.length > 0;
};

const getMessages = async () => {
	const result = await apiStore.callGetNotifications(1);
	notificationsList.value = result.data;
	// shouldLoadMore.value =
	// 	result.meta.pagination.pagination.total_pages > currentPage.value;
	shouldLoadMore.value = result.data.length > 0;
};

const handleEdit = (item: any) => {
	notificationTitle.value = item.msg_title;
	notificationBody.value = item.msg_text;
	notificationID.value = item.msg_id;
	isEditing.value = true;
};

const handleSendNotif = async () => {
	const paramsToSend = {
		title: notificationTitle.value,
		text: notificationBody.value,
		send: true,
	};

	await (isEditing.value
		? apiStore.callPatchNotification(notificationID.value, paramsToSend)
		: apiStore.callPostNotification(paramsToSend));

	resetValues();
	await getMessages();
};

const handleSaveDraft = async () => {
	const paramsToSend = {
		title: notificationTitle.value,
		text: notificationBody.value,
		send: false,
	};

	if (isEditing.value) {
		console.log("usao sam ovde");

		// paramsToSend["msg_id"] = notificationID.value;
		await apiStore.callPatchNotification(notificationID.value, paramsToSend);
	} else {
		await apiStore.callPostNotification(paramsToSend);
	}

	resetValues();
	await getMessages();
};

const handleDelete = async (id: number) => {
	await apiStore.callDeleteNotification(id);
	await getMessages();
};

const resetValues = () => {
	notificationTitle.value = "";
	notificationBody.value = "";
	notificationID.value = 0;
	isEditing.value = false;
};
</script>
<template>
	<div class="notification-page-wrapper">
		<div class="notification-list">
			<div class="list-title">Najnovije notifikacije</div>
			<div ref="notifications" class="list-wrap">
				<NotificationCard
					v-for="(item, index) in notificationsList"
					:key="index"
					:title="item.msg_title"
					:body="item.msg_text"
					:date="item.msg_utccreated"
					:is-draft="item.msg_status === 'DRFT'"
					@edit="handleEdit(item)"
					@delete="handleDelete(item.msg_id)"
				/>
				<!-- <NotificationCard
					title="Naslov notifikacije"
					body="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum "
					date="2021-08-12T12:00:00.000Z"
					:is-draft="false"
					@delete="handleDelete"
				/> -->
			</div>
		</div>
		<div class="create-notification">
			<div class="create-title">Napravi novu notifikaciju</div>
			<div class="inputs-wrap">
				<CustomInput
					type="text"
					label="Naslov"
					placeholder="Naslov notifikacije"
					:input-value="notificationTitle"
					@update:input-value="notificationTitle = $event"
				/>
				<CustomInput
					type="textarea"
					placeholder="Notifikacija..."
					:input-value="notificationBody"
					@update:input-value="notificationBody = $event"
				/>
			</div>
			<div class="btns-wrap">
				<CustomButton
					text="Objavi"
					:icon="images.addImg"
					small
					:disabled="!areFieldsFilled"
					@btn-click="handleSendNotif"
				/>
				<CustomButton
					text="Draft"
					:icon="images.saveImg"
					small
					:disabled="!areFieldsFilled"
					@btn-click="handleSaveDraft"
				/>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.notification-page-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;
	display: flex;
	gap: 230px;

	.notification-list {
		.list-title {
			font-size: 16px;
			font-weight: 700;
			color: $alt-dark;
			margin-bottom: 30px;
		}

		.list-wrap {
			max-height: 740px;
			overflow: hidden auto;

			&::-webkit-scrollbar,
			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				width: 6px;
				border: none;
				background: transparent;
			}

			&::-webkit-scrollbar-button,
			&::-webkit-scrollbar-track-piece,
			&::-webkit-scrollbar-corner,
			&::-webkit-resizer {
				display: none;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #c0c0c0;
			}

			&::-webkit-scrollbar-track {
				background-image: url("@/assets/image/scrolltrack.png");
				background-repeat: repeat-y;
				background-size: contain;
			}
		}
	}

	.create-notification {
		.create-title {
			font-size: 16px;
			font-weight: 700;
			color: $alt-dark;
			margin-bottom: 30px;
		}

		.inputs-wrap {
			width: 620px;
		}

		.btns-wrap {
			display: flex;
			gap: 40px;
			margin-top: 25px;
		}
	}
}
</style>
