<script setup lang="ts">
import { useDefaultStore } from "@/store";
import dayjs from "dayjs";

const props = defineProps<{
	gender: string;
	packageName: string;
	price: string;
	payment: number;
	phone: string;
	email: string;
	location: string;
	address: string;
	orderID: string;
	dates: string;
	note: string;
	showReservationBtn: boolean;
	orderBooked?: Record<string, any>;
}>();

const emit = defineEmits(["reservation"]);

const store = useDefaultStore();

const handleReservation = () => {
	emit("reservation");
};

const getBookedText = () => {
	if (!props.orderBooked) return "";
	if (!props.orderBooked.ord_ordertimes) return "";
	return `Zakazano za ${dayjs(
		props.orderBooked.ord_ordertimes[0].avl_date,
	).format(
		"DD.MM.YYYY",
	)}, ${props.orderBooked.ord_ordertimes[0].avl_timefrom.slice(
		0,
		2,
	)}h - ${props.orderBooked.ord_ordertimes[0].avl_timeto.slice(0, 2)}h`;
};
</script>
<template>
	<div class="extra-info-wrapper">
		<div class="column">
			<div class="segment-wrap">
				<div class="segment-title">pol pacijenta</div>
				<div class="segment-info">
					{{ store.getGenda.find((item) => item.id === gender)?.label }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">naziv paketa</div>
				<div class="segment-info">
					{{ packageName }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">snizena cena</div>
				<div class="segment-info">
					<!-- @vue-expect-error -->
					{{ price.toLocaleString("de-DE") }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">placeno</div>
				<div class="segment-info">
					{{ store.getPaymentTypes.find((item) => item.id === payment)?.label }}
				</div>
			</div>
		</div>
		<div class="column">
			<div class="segment-wrap">
				<div class="segment-title">kontakt telefon korisnika</div>
				<div class="segment-info">
					{{ phone }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">email korisnika</div>
				<div class="segment-info">
					{{ email }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">lokacija</div>
				<div class="segment-info">
					{{ location }}
				</div>
			</div>
			<div class="segment-wrap">
				<div class="segment-title">adresa</div>
				<div class="segment-info">
					{{ address }}
				</div>
			</div>
		</div>
		<div class="column">
			<div class="segment-wrap big">
				<div class="segment-title">ID kupona</div>
				<div class="segment-info">
					{{ orderID }}
				</div>
			</div>
			<div class="segment-wrap big">
				<div class="segment-title">zeljeni datumi</div>
				<div class="segment-info">
					{{ dates }}
				</div>
			</div>
			<div class="segment-wrap big">
				<div class="segment-title">napomena</div>
				<div class="segment-info">
					{{ note }}
				</div>
			</div>
		</div>
		<div v-if="showReservationBtn" class="column last">
			<button class="custom-btn" @click="handleReservation">
				{{ !orderBooked?.ord_ordertimes ? "Zakaži pregled" : "Promeni termin" }}
			</button>
			<span v-if="orderBooked?.ord_ordertimes">
				{{ getBookedText() }}
			</span>
		</div>
	</div>
</template>
<style scoped lang="scss">
.extra-info-wrapper {
	padding-left: 6px;
	width: 100%;
	height: 220px;
	display: flex;
	gap: 15px;
	padding-top: 10px;

	.column {
		display: flex;
		flex-direction: column;
		gap: 15px;

		&.last {
			width: 100%;
			align-items: flex-end;
			padding-right: 50px;
		}

		.custom-btn {
			width: 150px;
			height: 48px;
			border-radius: 20px;
			border-color: transparent;
			background: #fff;
			outline: none;
			box-shadow: 3px 4px 10px rgb(0 0 0 / 15%);
			font-weight: bold;
			font-size: 15px;
			cursor: pointer;
		}
	}

	.segment-wrap {
		max-width: 220px;
		width: 220px;
		display: flex;
		flex-direction: column;

		.segment-title {
			color: $alt-gray;
			font-family: Archivo, sans-serif;
			font-size: 11px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 9px;
		}

		.segment-info {
			color: $table-text;
			font-size: 13px;
			font-weight: 600;
			font-family: Archivo, sans-serif;
		}
	}
}
</style>
