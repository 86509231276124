import sendImg from "@/assets/image/btn-icon/send.svg";
import loginImg from "@/assets/image/btn-icon/login.svg";
import addImg from "@/assets/image/btn-icon/add.svg";
import editImg from "@/assets/image/btn-icon/edit.svg";
import deleteImg from "@/assets/image/btn-icon/delete.svg";
import confirmImg from "@/assets/image/btn-icon/confirm.svg";
import closeImg from "@/assets/image/btn-icon/close.svg";
import pauseImg from "@/assets/image/btn-icon/pause.svg";
import blockImg from "@/assets/image/btn-icon/block.svg";
import searchImg from "@/assets/image/btn-icon/search.svg";
import logoutImg from "@/assets/image/btn-icon/logout.svg";
import exportImg from "@/assets/image/btn-icon/export.svg";
import saveImg from "@/assets/image/btn-icon/save.svg";
import completeImg from "@/assets/image/btn-icon/complete.svg";

import downArrow from "@/assets/image/downArrow.svg";
import deleteBlack from "@/assets/image/deleteBlack.png";

import scheduleBlack from "@/assets/image/tab-icon/schedule.svg";
import servicesBlack from "@/assets/image/tab-icon/services.svg";
import usersBlack from "@/assets/image/tab-icon/users.svg";
import adminBlack from "@/assets/image/tab-icon/admin.svg";
import technitiansBlack from "@/assets/image/tab-icon/technitians.svg";
import activitiesBlack from "@/assets/image/tab-icon/activities.svg";
import reportsBlack from "@/assets/image/tab-icon/reports.svg";
import notificationsBlack from "@/assets/image/tab-icon/notifications.svg";
import packagesBlack from "@/assets/image/tab-icon/packages.svg";
import systemNotificationsBlack from "@/assets/image/tab-icon/system-notifications.svg";
import atHomeBlack from "@/assets/image/tab-icon/at-home.svg";

import blockAction from "@/assets/image/table-actions/block.svg";
import deleteAction from "@/assets/image/table-actions/delete.svg";
import editAction from "@/assets/image/table-actions/edit.svg";
import holdAction from "@/assets/image/table-actions/hold.svg";
import playAction from "@/assets/image/table-actions/play.svg";

import modalClose from "@/assets/image/modal-close.svg";

import addWhite from "@/assets/image/addWhite.svg";

import tableCheck from "@/assets/image/table-check.svg";

import checkSmall from "@/assets/image/checkSmall.svg";

export default {
	sendImg,
	loginImg,
	addImg,
	editImg,
	deleteImg,
	confirmImg,
	closeImg,
	pauseImg,
	blockImg,
	searchImg,
	logoutImg,
	exportImg,
	saveImg,
	completeImg,
	downArrow,
	scheduleBlack,
	servicesBlack,
	usersBlack,
	adminBlack,
	technitiansBlack,
	activitiesBlack,
	reportsBlack,
	notificationsBlack,
	packagesBlack,
	systemNotificationsBlack,
	atHomeBlack,
	blockAction,
	deleteAction,
	editAction,
	holdAction,
	playAction,
	modalClose,
	deleteBlack,
	checkSmall,
	addWhite,
	tableCheck,
};
