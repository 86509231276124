import api from "@/api";
import type {
	TNameIDPair,
	IAuthParams,
	TTableUsers,
	// Item,
	TLooseObject,
} from "@/assets/js/types";
import { useDefaultStore } from ".";
import {
	downloadBlob,
	mergePairsByKey,
	setLocalStorageReac,
	splitObjectsByKey,
} from "@/assets/js/helpers";
import router from "@/router";
import dayjs from "dayjs";
import { useToastStore } from "./toast";

const callLogin = async (params: IAuthParams) => {
	try {
		const response = await api.login(params);
		if (
			response.data.permissions["can-use-web"] &&
			!response.data.permissions["can-use-admin-panel"]
		) {
			setLocalStorageReac("sid", response.data.sid);
			useDefaultStore().setUser({
				fullname: response.data.user.usr_fullname,
				phone: response.data.user.usr_phone,
				email: response.data.user.usr_email,
				address: response.data.user.usr_address,
			});
			useDefaultStore().setAllPermissions(response.data.permissions);
			return 3;
		}
		if (
			response.data.permissions["can-use-web"] &&
			response.data.permissions["can-use-admin-panel"]
		) {
			// set sid
			setLocalStorageReac("sid", response.data.sid);
			useDefaultStore().setUser({
				fullname: response.data.user.usr_fullname,
				phone: response.data.user.usr_phone,
				email: response.data.user.usr_email,
				address: response.data.user.usr_address,
			});
			useDefaultStore().setAllPermissions(response.data.permissions);
			return response;
		} else {
			useToastStore().openToastError("Nemate pristup web aplikaciji.");
			return false;
		}
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
		return false;
	}
};

const callResetPassword = async (email: string) => {
	try {
		const result = await api.resetPassword({ email });
		useToastStore().openToastSuccess("Email je uspešno poslat.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callCheckSession = async () => {
	try {
		const result = await api.checkSession();
		useDefaultStore().setUser({
			fullname: result.data.user.usr_fullname,
			phone: result.data.user.usr_phone,
			email: result.data.user.usr_email,
			address: result.data.user.usr_address,
		});
		useDefaultStore().setAllPermissions(result.data.permissions);
		return result;
	} catch (error) {
		console.warn(error);
		setLocalStorageReac("sid", "");
		// console.log(router.currentRoute.value.name);
		if (
			router.currentRoute.value.name !== "LoginPage" &&
			router.currentRoute.value.name !== "ForgotPasswordPage" &&
			router.currentRoute.value.name !== "ChangePasswordPage" &&
			router.currentRoute.value.name !== "VerifyEmailPage" &&
			router.currentRoute.value.name !== "DeleteAccountForm"
		) {
			void router.push({ name: "LoginPage" });
		}

		// NOTE - check this void if its funky
	}
};

const callLogout = async () => {
	try {
		const result = await api.logoutApi();
		useDefaultStore().setUser({
			fullname: "",
			phone: "",
			email: "",
			address: "",
		});
		setLocalStorageReac("sid", "");
		return result;
	} catch (error) {
		console.warn(error);
	}
};
// NOTE - not used
const callGetAllUsers = async () => {
	try {
		const formatedData: TTableUsers[] = [];
		const result = await api.getAllUsers();
		for (const el of result.data.data) {
			formatedData.push({
				id: el.usr_id,
				name: el.usr_fullname,
				phone: el.usr_phone,
				email: el.usr_email,
				address: el.usr_address,
				area: el.area_name,
				userstatus: el.ust_id,
				dob: "12.12.2021",
				genda: "M",
			});
		}
		return formatedData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callPatchUser = async (id: number, params: any) => {
	try {
		const result = await api.patchUser(id, params);
		useToastStore().openToastSuccess("Korisnik je uspešno ažuriran.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callDeleteUser = async (id: number) => {
	try {
		const result = await api.deleteUser(id);
		useToastStore().openToastSuccess("Korisnik je uspešno obrisan.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom brisanja korisnika.",
		);
	}
};

const callGetAdmins = async (params?: any) => {
	try {
		const formatedData: TTableUsers[] = [];
		const result = await api.getAdmins(params);
		for (const el of result.data.data) {
			formatedData.push({
				id: el.usr_id,
				name: el.usr_fullname,
				phone: el.usr_phone,
				email: el.usr_email,
			});
		}
		return formatedData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callPostAdmin = async (params: any) => {
	try {
		const result = await api.postUser({ ...params, role: 900 });
		useToastStore().openToastSuccess("Administrator je uspešno kreiran.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(
			error.response.data.message || "Došlo je do greške.",
		);
	}
};

const callGetTechnicians = async (params?: any) => {
	try {
		const formatedData: TLooseObject[] = [];
		const result = await api.getTechnicians(params);
		for (const el of result.data.data) {
			formatedData.push({
				id: el.usr_id,
				loc_id: el.loc_id,
				fullname: el.usr_fullname,
				// FIXME - remove this bcs its just for testing
				phone: el.usr_phone,
				email: el.usr_email,
				location: el.loc_name,
			});
		}
		return formatedData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callPostTechnician = async (params: any) => {
	try {
		const result = await api.postUser({ ...params, role: 800 });
		useToastStore().openToastSuccess("Tehničar je uspešno kreiran.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callGetPatients = async (params?: any, isActive?: boolean) => {
	try {
		const formatedData: TLooseObject[] = [];
		// console.log(isActive);

		const result = await api.getPatients(params, isActive);
		for (const el of result.data.data) {
			formatedData.push({
				id: el.usr_id,
				fullname: el.usr_fullname,
				phone: el.usr_phone,
				dob: dayjs(el.usr_birthdate).format("DD.MM.YYYY"),
				gender: el.usr_gender,
				email: el.usr_email,
				address: el.usr_address,
				area: el.area_name,
				userstatus: el.ust_id,
			});
		}
		return formatedData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callGetAvailabilities = async (params: any, returnSingle = true) => {
	try {
		params.withUnavailable = true;
		const result = await api.getAvailabilities(params);
		// const test = mergePairsByKey(result.data.data, "from");
		// console.log(test);
		// console.log(result.data.data);

		const returnVal = returnSingle
			? splitObjectsByKey(result.data.data, "svc_id")
			: result.data.data;

		// return [];
		return returnVal;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

// REVIEW - review and change this
const getCancer = async (params: any) => {
	try {
		const result = await api.test(params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callGetAreas = async () => {
	try {
		const result: TNameIDPair[] = [];
		const tempResult = await api.getAreas();
		for (const el of tempResult.data.data) {
			result.push({
				id: el.area_id,
				label: el.area_name,
			});
		}

		useDefaultStore().setAreas(result);
		// return result;
	} catch (error) {
		console.warn(error);
	}
};

const callGetServices = async () => {
	try {
		const result: TNameIDPair[] = [];
		const tempResult = await api.getServices();
		for (const el of tempResult.data.data) {
			result.push({
				id: el.svc_id,
				label: el.svc_name,
			});
		}
		useDefaultStore().setServices(result);
	} catch (error) {
		console.warn(error);
	}
};

const callGetGroups = async (params: { service: number }) => {
	try {
		const result: TNameIDPair[] = [];
		const tempResult = await api.getGroups(params);
		for (const el of tempResult.data.data) {
			result.push({
				id: el.itg_id,
				label: el.itg_name,
			});
		}
		useDefaultStore().setGroups(result);
	} catch (error) {
		console.warn(error);
	}
};

const callPatchAvailability = async (id: number, params: any) => {
	try {
		const result = await api.patchAvailability(id, params);
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja termina.",
		);
	}
};

const callGetUnsortedOrders = async () => {
	try {
		const result = await api.getUnsortedOrders();
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callGetItems = async (params: any) => {
	try {
		let returnData: TLooseObject[] = [];
		switch (params.service) {
			case 1: {
				const result = await api.getItems(params);
				for (const el of result.data.data) {
					returnData.push({
						id: el.itm_id,
						isActive: !el.itm_hidden,
						itemgroup: el.itg_id,
						name: el.itm_name,
						price: el.itm_price,
						description: el.itm_description,
					});
				}
				break;
			}
			case 2: {
				const result = await callGetPromotions(params);
				returnData = result;
				break;
			}
			case 3: {
				const result = await callGetCoupons(params);
				returnData = result;
				break;
			}
			case 4: {
				const result = await callGetAtHome(params);
				returnData = result;
				break;
			}

			default: {
				break;
			}
		}
		return returnData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

/* 
	things needed:
	service -> svc_id
	name - user -> orderuser_fullname
	nameOfPatient - patient -> ord_fullname
	date -> ord_utccreated
	phone -> ord_phone
	email - patient -> ord_email
	dob (needs to be send on order create) -> null
	isCouponUsed -> null
	isPaid -> ord_paid
	gender (needs to be sent when created) -> null
	itemID -> null
	price -> null
	location - area prehaps? -> area_name/loc_name
	address -> ord_address
	description -> null
	note -> ord_comment
*/
// console.log(el);
const callGetPackages = async (
	serviceArr: number[],
	page: number,
	search?: string,
	date?: string,
	items?: number,
) => {
	try {
		const returnData: any[] = [];
		const paramsForApi = serviceArr.join(",");
		const result = await api.getPackages(
			paramsForApi,
			page,
			search,
			date,
			items,
		);

		for (const el of result.data.data) {
			const objToPush = {
				// FIXME - fix this when added
				id: el.ord_id,
				orderID: el.ord_refid,
				// service: el.svc_id,
				service: "",
				name: el.orderuser_fullname,
				nameOfPatient: el.ord_fullname,
				date: dayjs(el.ord_utccreated).format("DD.MM.YYYY"),
				phoneOfPatient: el.ord_phone,
				phone: el.orderuser_phone,
				emailOfPatient: el.ord_email,
				email: el.orderuser_email,
				dob: dayjs(el.ord_birthdate).format("DD.MM.YYYY"),
				isCouponUsed: el.ord_confirmedbyadmin,
				isPaid: el.ord_paid,
				payment: el.pyt_id,
				gender: el.ord_gender,
				itemID: "",
				price: "",
				location: el.loc_name || el.area_name,
				address: el.ord_address,
				description: el.ord_description,
				note: el.ord_comment,
			};
			objToPush.service =
				el.orderitems.length > 0 && el.svc_id === 4
					? el.orderitems[0].itm_id
					: el.svc_id;
			// objToPush.ordDate = el.orderitems.length > 0 ? el.orderitems[0].
			// console.log(el.svc_id === 4 ? el.orderitems[0].itm_id : el.svc_id);
			// console.log(objToPush);

			returnData.push(objToPush);
		}
		// console.log(returnData);

		return returnData;
	} catch (error) {
		// console.log("usao sam ovdes");

		console.warn(error);
		return [];
	}
};

const callGetPromotions = async (params: any) => {
	try {
		const returnData: TLooseObject[] = [];
		const result = await api.getItems(params);
		// result.data.data.forEach((el: any) => {
		// 	returnData.push({
		// 		id: el.itm_id,
		// 		name: el.itm_name,
		// 		price: el.itm_price,
		// 		originalPrice: el.itm_oldprice,
		// 		endDate: el.itm_expiry,
		// 		description: el.itm_description,
		// 		isActive: el.itm_active,
		// 		items: el.descriptionList,
		// 	});
		// });
		for (const el of result.data.data) {
			returnData.push({
				id: el.itm_id,
				name: el.itm_name,
				price: el.itm_price,
				originalPrice: el.itm_oldprice,
				endDate: dayjs(el.itm_expiry).format("DD.MM.YYYY"),
				description: el.itm_description,
				isActive: !el.itm_hidden,
				items: el.itm_descriptionlist,
			});
		}
		return returnData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callGetCoupons = async (params: any) => {
	try {
		const returnData: TLooseObject[] = [];
		const result = await api.getItems(params);
		for (const el of result.data.data) {
			returnData.push({
				id: el.itm_id,
				name: el.itm_name,
				condition: el.itm_subtitle,
				price: el.itm_price,
				originalPrice: el.itm_oldprice,
				endDate: el.itm_expiry,
				description: el.itm_description,
				isActive: !el.itm_hidden,
				items: el.itm_descriptionlist,
			});
		}
		return returnData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callGetAtHome = async (params: any) => {
	try {
		const returnData: TLooseObject[] = [];
		const result = await api.getItems(params);
		for (const el of result.data.data) {
			returnData.push({
				id: el.itm_id,
				name: el.itm_name,
				price: el.itm_price,
				description: el.itm_description,
				items: [el.itm_description],
				isActive: !el.itm_hidden,
			});
		}
		return returnData;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callPatchItemActive = async (id: number, params: any) => {
	try {
		const result = await api.patchItemActive(id, params);
		useToastStore().openToastSuccess("Usluga je uspešno ažurirana.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja usluge.",
		);
	}
};

const callGetAllGroups = async () => {
	try {
		const tempData: TNameIDPair[] = [];
		const result = await api.getAllGroups();
		for (const el of result.data.data) {
			tempData.push({
				id: el.itg_id,
				label: el.itg_name,
			});
		}
		useDefaultStore().setAllGroups(tempData);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callDeleteItem = async (id: number) => {
	try {
		const result = await api.deleteItem(id);
		useToastStore().openToastSuccess("Usluga je uspešno obrisana.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom brisanja usluge.",
		);
	}
};

const callPostService = async (params: { name: string }) => {
	try {
		const result = await api.postService(params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callPatchService = async (id: number, params: { name: string }) => {
	try {
		const result = await api.patchService(id, params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callDeleteService = async (id: number) => {
	try {
		const result = await api.deleteService(id);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callPostItemGroup = async (params: { name: string; service: number }) => {
	try {
		const result = await api.postItemGroup(params);
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callPatchItemGroup = async (id: number, params: { name: string }) => {
	try {
		const result = await api.patchItemGroup(id, params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callDeleteItemGroup = async (id: number) => {
	try {
		const result = await api.deleteItemGroup(id);
		useToastStore().openToastSuccess("Grupa je uspešno obrisana.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callGetUserStatuses = async () => {
	try {
		const tempData: TNameIDPair[] = [];
		const result = await api.getUserStatuses();
		for (const el of result.data.data) {
			tempData.push({
				id: el.ust_id,
				label: el.ust_name,
			});
		}
		useDefaultStore().setUserStatuses(tempData);
	} catch (error) {
		console.warn(error);
	}
};

const callBlockUser = async (id: number) => {
	try {
		const result = await api.patchUserStatus(id, 3);
		useToastStore().openToastSuccess("Korisnik je uspešno ažuriran.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja korisnika.",
		);
	}
};

const callPauseUser = async (id: number) => {
	try {
		const result = await api.patchUserStatus(id, 2);
		useToastStore().openToastSuccess("Korisnik je uspešno ažuriran.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja korisnika.",
		);
	}
};

const callRestoreUser = async (id: number) => {
	try {
		const result = await api.patchUserStatus(id, 1);
		useToastStore().openToastSuccess("Korisnik je uspešno ažuriran.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja korisnika.",
		);
	}
};

const callGetNotifications = async (page: number) => {
	try {
		const result = await api.getNotifications(page);
		return result.data;
	} catch (error) {
		console.warn(error);
	}
};

const callPostNotification = async (params: any) => {
	try {
		const result = await api.postNotification(params);
		useToastStore().openToastSuccess("Notifikacija je uspešno kreirana.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callPatchNotification = async (id: number, params: any) => {
	try {
		const result = await api.patchNotification(id, params);
		useToastStore().openToastSuccess("Notifikacija je uspešno ažurirana.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callDeleteNotification = async (id: number) => {
	try {
		const result = await api.deleteNotification(id);
		useToastStore().openToastSuccess("Notifikacija je uspešno obrisana.");
		return result;
	} catch (error: any) {
		console.warn(error);
		useToastStore().openToastError(error.response.data.message);
	}
};

const callGetLoyalty = async (params?: any) => {
	try {
		const result = await api.getLoyalty(params);
		return result.data.data;
	} catch (error) {
		console.warn(error);
	}
};

const callGetUserTicketTypes = async () => {
	try {
		const result = await api.getUserTicketTypes();
		const returnArr: TNameIDPair[] = [];
		for (const el of result.data.data) {
			returnArr.push({
				id: el.utt_id,
				label: el.utt_name,
			});
		}
		useDefaultStore().setUserTicketTypes(returnArr);
		return returnArr;
	} catch (error) {
		console.warn(error);
	}
};

const callPostUserTicketType = async (params: any) => {
	try {
		const result = await api.postUserTicketType(params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callPatchOrderAvailability = async (id: number, params: any) => {
	try {
		const result = await api.patchOrderAvailability(id, params);
		return result;
	} catch (error: any) {
		useToastStore().openToastError(error.response.data.message);
		console.warn(error);
	}
};

const callGetOrder = async (id: number) => {
	try {
		const result = await api.getOrder(id);
		return result.data.data;
	} catch (error) {
		console.warn(error);
	}
};

const callGetAvailableTechnicians = async (id: number) => {
	try {
		const result = await api.getAvailableTechnicians(id);
		return result.data.data;
	} catch (error) {
		console.warn(error);
	}
};

const callPostUserTicket = async (params: any) => {
	try {
		const result = await api.postUserTicket(params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callPostItem = async (params: any) => {
	try {
		const result = await api.postItem(params);
		useToastStore().openToastSuccess("Usluga je uspešno kreirana.");
		return result;
	} catch (error: any) {
		useToastStore().openToastError(error.response.data.message);
		console.warn(error);
	}
};

const callGetTechnicianLocations = async () => {
	try {
		const result = await api.getTechnicianLocations();
		const tempArr: TNameIDPair[] = [];
		for (const loc of result.data.data) {
			tempArr.push({
				id: loc.loc_id,
				label: loc.loc_name,
			});
		}
		useDefaultStore().setTechnicianLocations(tempArr);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callPatchOrderCoupon = async (id: number) => {
	try {
		const result = await api.patchOrderCoupon(id, true);
		useToastStore().openToastSuccess("Porudžbina je uspešno ažurirana.");
		return result;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja porudžbine.",
		);
	}
};

const callGetPaymentTypes = async () => {
	try {
		const result = await api.getPaymentTypes();
		const tempArr: TNameIDPair[] = [];

		for (const el of result.data.data) {
			tempArr.push({
				id: el.pyt_id,
				label: el.pyt_name,
			});
		}
		useDefaultStore().setPaymentTypes(tempArr);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

const callVerifyEmail = async (t: any) => {
	try {
		const response = await api.verifyEmail(t);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom verifikacije email-a.",
		);
	}
};

const callSubmitResetPassword = async (params: any) => {
	try {
		const response = await api.submitResetPasword(params);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom promene lozinke.",
		);
	}
};

const callDeleteUserTicket = async (id: number) => {
	try {
		const response = await api.deleteUserTicket(id);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom brisanja tiketa.",
		);
	}
};

const callUpdateSortOrder = async (params: any) => {
	try {
		const response = await api.patchSortOrder(params);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja redosleda.",
		);
	}
};

const callPatchUserTicketStatus = async (id: number) => {
	try {
		const response = await api.patchUserTicketStatus(id);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja statusa tiketa.",
		);
	}
};

const callPatchOrderApprove = async (id: number) => {
	try {
		const response = await api.patchOrderApprove(id);
		return response.data;
	} catch (error) {
		console.warn(error);
		useToastStore().openToastError(
			"Došlo je do greške prilikom ažuriranja statusa tiketa.",
		);
	}
};

const callGetAtHomeServiceNames = async () => {
	try {
		const result = await api.getAtHomeServiceNames();
		const temoResult: TNameIDPair[] = [];
		for (const el of result.data.data) {
			temoResult.push({
				id: el.itm_id,
				label: el.itm_name,
			});
		}
		return temoResult;
	} catch (error) {
		console.warn(error);
		const idkanymore: TNameIDPair[] = [];
		return idkanymore;
	}
};

const callGetSystemNotifications = async (page: number) => {
	try {
		const result = await api.getSystemNotifications(page);
		return result.data.data;
	} catch (error) {
		console.warn(error);
		return [];
	}
};

const callGetUnseenNotificationCount = async (messageType: string) => {
	try {
		const result = await api.getUnseenNotificationCount(messageType);
		useDefaultStore().setHasUnreadMessages(result.data.data > 0);
		return result.data.data;
	} catch (error) {
		console.warn(error);
		return 0;
	}
};

const callGetOrdersMetaData = async (service: string | number) => {
	try {
		const result = await api.getOrdersMetaData(service);
		console.log(result.data.data.pagination);

		return result.data.data.pagination;
	} catch (error) {
		console.warn(error);
		return {};
	}
};

const callPostMarkAllAsRead = async (messageType: number[]) => {
	try {
		const result = await api.postMarkAllAsRead(messageType);
		return result.data;
	} catch (error) {
		console.warn(error);
	}
};

const callPostDeleteAccountForm = async (params: any) => {
	try {
		const result = await api.postDeleteAccountForm(params);
		return result;
	} catch (error) {
		console.warn(error);
	}
};

export const useApiStore = defineStore("ApiStore", () => {
	return {
		getCancer,
		callLogin,
		callResetPassword,
		callCheckSession,
		callLogout,
		callGetAllUsers,
		callPatchUser,
		callGetAdmins,
		callPostAdmin,
		callGetTechnicians,
		callGetPatients,
		callGetAvailabilities,
		callPatchAvailability,
		callGetAreas,
		callGetServices,
		callGetGroups,
		callGetUnsortedOrders,
		callGetItems,
		callPatchItemActive,
		callGetAllGroups,
		callDeleteItem,
		callPostService,
		callPatchService,
		callDeleteService,
		callPostItemGroup,
		callPatchItemGroup,
		callDeleteItemGroup,
		callGetUserStatuses,
		callBlockUser,
		callPauseUser,
		callRestoreUser,
		callGetNotifications,
		callPostNotification,
		callPatchNotification,
		callDeleteNotification,
		callDeleteUser,
		callPostTechnician,
		callGetLoyalty,
		callGetPackages,
		callGetUserTicketTypes,
		callPostUserTicketType,
		callPatchOrderAvailability,
		callGetOrder,
		callGetAvailableTechnicians,
		callPostUserTicket,
		callPostItem,
		callGetTechnicianLocations,
		callPatchOrderCoupon,
		callGetPaymentTypes,
		callVerifyEmail,
		callSubmitResetPassword,
		callDeleteUserTicket,
		callUpdateSortOrder,
		callPatchUserTicketStatus,
		callPatchOrderApprove,
		callGetAtHomeServiceNames,
		callGetSystemNotifications,
		callGetUnseenNotificationCount,
		callGetOrdersMetaData,
		callPostMarkAllAsRead,
		callPostDeleteAccountForm,
	};
});
