import { useModalStore } from "@/store/modal";
import { type IGlobalModal } from "@/components/Shared/modal/ModernModal.vue";
import EditAdminUI from "@/components/ModalInterfaces/editAdminUI.vue";
import AddAdminUI from "../ModalInterfaces/addAdminUI.vue";
import AlertUI from "../ModalInterfaces/alertUI.vue";
import EditGroupsUI from "../ModalInterfaces/editGroupsUI.vue";
import EditItemsUI from "../ModalInterfaces/editItemsUI.vue";
import EditPromoUI from "../ModalInterfaces/editPromoUI.vue";
import EditUserUI from "../ModalInterfaces/editUserUI.vue";
import AddTechnitianUI from "../ModalInterfaces/addTechnitianUI.vue";
import EditTechnicianUI from "../ModalInterfaces/editTechnicianUI.vue";
import AddPromoUI from "../ModalInterfaces/addPromoUI.vue";
import EditAtHomeUI from "../ModalInterfaces/editAtHomeUI.vue";
import EditPackageUI from "../ModalInterfaces/editPackageUI.vue";

function openModal(
	comp: any,
	config?: Record<string, any>,
	formStyle?: Record<string, any>,
) {
	const modalStore = useModalStore();
	modalStore.updateModalData({
		comp,
		config,
		formStyle,
	} as IGlobalModal);
}

// example usecase
export function openEditAdminModal(payload = {}, callback: any) {
	const config = {
		title: "Izmena profila",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditAdminUI, config);
}

export function openAddAdminModal(callback: any) {
	const config = {
		title: "Admin",
		slotProps: {
			onCloseModal: () => callback(),
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(AddAdminUI, config);
}

export function openEditTechnicianModal(payload = {}, callback: any) {
	const config = {
		title: "Izmena profila",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditTechnicianUI, config);
}

export function openAddTechnitianModal(callback: any) {
	const config = {
		title: "Tehničar",
		slotProps: {
			onCloseModal: () => callback(),
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(AddTechnitianUI, config);
}

export function openAlertModal(
	payload = {},
	callback?: any,
	id?: number,
	service?: number,
) {
	const config = {
		isShowCloseBtn: false,
		slotProps: {
			onCloseModal: (didConfirm: any) => {
				if (didConfirm) callback(id, service);
			},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(AlertUI, config);
}

export function openGroupsModal(payload = {}, callback: any) {
	const config = {
		title: "Laboratorijske usluge",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditGroupsUI, config);
}

export function openItemsModal(payload = {}, title: string, callback: any) {
	const config = {
		title,
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditItemsUI, config);
}

export function openPromoModal(payload = {}, title: string, callback: any) {
	const config = {
		title,
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditPromoUI, config);
}

export function openEditUserModal(payload = {}, callback: any) {
	const config = {
		title: "Izmena profila korisnika",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditUserUI, config);
}

export function openAddPromoModal(payload = {}, title: string) {
	const config = {
		title,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(AddPromoUI, config);
}

export function openEdtiAtHomeModal(payload = {}, callback: any) {
	const config = {
		title: "Izmeni pregled",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditAtHomeUI, config);
}

export function openEditPackageModal(payload = {}, callback: any) {
	const config = {
		title: "Zakazivanje pregleda",
		slotProps: {
			onCloseModal: () => callback(),
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(EditPackageUI, config);
}
