<template lang="pug">
.my-toast(:class="[type, { 'dark-theme': isDarkTheme }]")
	slot
		.line
		.content
			.icon
				template(v-if="type === 'error'")
					svg(
						xmlns="http://www.w3.org/2000/svg",
						width="61",
						height="61",
						viewbox="0 0 61 61",
						fill="none"
					)
						circle(
							cx="30.5256",
							cy="30.4348",
							r="29.9309",
							fill="#E44E75",
							fill-opacity="0.1"
						)
						path(
							d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068",
							stroke="#E44E75",
							stroke-width="4",
							stroke-linecap="round",
							stroke-linejoin="round"
						)
				template(v-else-if="type === 'success'")
					svg(
						xmlns="http://www.w3.org/2000/svg",
						width="61",
						height="61",
						viewbox="0 0 61 61",
						fill="none"
					)
						circle(
							cx="30.5256",
							cy="30.4348",
							r="29.9309",
							fill="#33B980",
							fill-opacity="0.1"
						)
						path(
							d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068",
							stroke="#33B980",
							stroke-width="4",
							stroke-linecap="round",
							stroke-linejoin="round"
						)
				template(v-else-if="type === 'info'")
					svg(
						width="64px",
						height="64px",
						viewBox="0 0 24.00 24.00",
						fill="none",
						xmlns="http://www.w3.org/2000/svg"
					)
						g#SVGRepo_bgCarrier(stroke-width="0")
						g#SVGRepo_tracerCarrier(stroke-linecap="round", stroke-linejoin="round")
						g#SVGRepo_iconCarrier 
						path(
							d="M12 17V11",
							stroke="#172841",
							stroke-width="1.5",
							stroke-linecap="round"
						)
						circle(
							cx="1",
							cy="1",
							r="1",
							transform="matrix(1 0 0 -1 11 9)",
							fill="#172841"
						)
						path(
							d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7",
							stroke="#172841",
							stroke-width="1.5",
							stroke-linecap="round"
						)

			.text-content
				.title {{ parsedTitleMsg }}
				.text
					| {{ msg }}
</template>

<script setup lang="ts">
const props = defineProps({
	type: {
		type: String,
		default: "error",
	},
	isDarkTheme: Boolean,
	titleMsg: {
		type: String,
		default: "",
	},
	msg: {
		type: String,
		default: "",
	},
});

const parsedTitleMsg = computed(() => {
	return props.titleMsg || props.type === "error"
		? "Greška"
		: props.type === "success"
			? "Uspeh!"
			: "Informacija";
});
</script>

<style lang="scss">
.Vue-Toastification__toast.my-custom-toast-class {
	$content-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
	$border-radius: 6px;

	min-height: 80px;
	background-color: transparent;
	user-select: none;
	border: 0;
	border-radius: $border-radius;
	box-sizing: border-box;
	padding: 0;
	backdrop-filter: blur(20px);
	box-shadow: $content-shadow;
}
</style>
<style lang="scss" scoped>
$content-back: #fff;
$title-color: $primary-text;
$content-text: $primary-text;
$border-color: #47474790;
$green-accent: green;
$red-accent: $main-highlight;
$dark-theme-back: #33353b;
$dark-theme-title: white;
$dark-theme-text: #d3d3d3;
$content-font: "Open Sans", sans-serif;
$icon-size: 40px;

// $green-accent: #47eaaf;

.my-toast {
	display: flex;
	height: 100%;
	width: 100%;
	background-color: $content-back;

	.line {
		height: 100%;
		width: 5px;
	}

	.content {
		padding: 8px 20px;
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		overflow: hidden;
		font-family: $content-font;
		cursor: pointer;

		.text-content {
			font-size: 16px;
			font-style: normal;
			display: flex;
			flex-direction: column;
			gap: 5px;

			.title {
				color: $title-color;
				font-weight: 600;
				line-height: 17px;
			}

			.text {
				color: $content-text;
			}
		}

		.icon {
			width: $icon-size;
			height: $icon-size;
			display: grid;
			place-content: center;

			svg {
				transform: scale(0.6);
			}
		}
	}

	&.success {
		.line {
			background: $green-accent;
		}
	}

	&.error {
		.line {
			background: $red-accent;
		}
	}

	&.info {
		.line {
			background: $primary-text;
		}
	}

	&.dark-theme {
		.content {
			background-color: $dark-theme-back;
			border: 0;

			.title {
				color: $dark-theme-title;
			}

			.text {
				color: $dark-theme-text;
			}
		}
	}
}
</style>
