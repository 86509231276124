<script setup lang="ts">
import images from "@/assets/js/images";
import { type TLooseObject } from "@/assets/js/types";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const store = useDefaultStore();
const apiStore = useApiStore();

const props = defineProps<{
	id: number;
	name: string;
	phone: string;
	dob: string; // dayjs object
	genda: string;
	email: string;
	address: string;
	area?: number;
}>();

const emit = defineEmits(["close-modal"]);

const newName = ref<string>("");
const newPhone = ref<string>("");
const newDob = ref<any>(dayjs());
const selectedGenda = ref<TLooseObject | string>("");
const newEmail = ref<string>("");
const newAddress = ref<string>("");
const selectedArea = ref<TLooseObject | number>(0);

onMounted(() => {
	if (props.name) newName.value = props.name;
	if (props.phone) newPhone.value = props.phone;
	if (props.dob) newDob.value = dayjs(props.dob, "DD.MM.YYYY");
	if (props.genda) selectedGenda.value = props.genda;
	if (props.email) newEmail.value = props.email;
	if (props.address) newAddress.value = props.address;
	if (props.area) selectedArea.value = props.area;
});

const areAllFieldsFilled = computed(() => {
	return (
		newName.value &&
		newPhone.value &&
		newDob.value &&
		selectedGenda.value &&
		newEmail.value
	);
});

const handleEditUser = async () => {
	const areaToSend =
		typeof selectedArea.value === "object"
			? selectedArea.value.id
			: selectedArea.value;
	const gendaToSend =
		typeof selectedGenda.value === "object"
			? selectedGenda.value.id
			: selectedGenda.value;

	const paramsObj: TLooseObject = {};

	if (newName.value !== props.name) paramsObj.fullname = newName.value;
	if (newPhone.value !== props.phone) paramsObj.phone = newPhone.value;
	if (newDob.value !== props.dob)
		paramsObj.birthdate = newDob.value.format("YYYY-MM-DD");
	if (newEmail.value !== props.email) paramsObj.email = newEmail.value;
	if (newAddress.value !== props.address) paramsObj.address = newAddress.value;
	if (areaToSend !== 0) paramsObj.area = areaToSend;
	if (gendaToSend !== 0) paramsObj.gender = gendaToSend;

	await apiStore.callPatchUser(props.id, paramsObj);

	// FIXME - this is a hack, hhahahahahahahah
	// window.location.reload();

	emit("close-modal");
};

// NOTE - might need to be re-added, idk
// const gendaOptions = computed(() => store.getGenda);
// const areaOptions = computed(() => store.getAreas);
</script>
<template>
	<div class="edit-user-wrapper">
		<div class="inputs-container">
			<CustomInput
				type="text"
				label="Ime i prezime *"
				placeholder="Ime i prezime *"
				:input-value="newName"
				@update:input-value="newName = $event"
			/>
			<CustomInput
				type="text"
				label="Kontakt telefon *"
				placeholder="Kontakt telefon *"
				:input-value="newPhone"
				@update:input-value="newPhone = $event"
			/>
			<!-- <CustomDatepicker v-model="newDob" label="Datum rođenja *" /> -->
			<ModernDatepicker
				label="Datum rođenja *"
				placeholder="Datum rođenja *"
				:input-value="newDob"
				@update:model-value="newDob = $event"
			/>
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Pol *</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedGenda"
						label="label"
						placeholder="Pol *"
						:options="store.getGenda"
						:value-prop="'id'"
					/>
				</div>
			</div>
			<CustomInput
				type="text"
				label="Email *"
				placeholder="Email *"
				:input-value="newEmail"
				@update:input-value="newEmail = $event"
			/>
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Opština *</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedArea"
						label="label"
						placeholder="Opština"
						:options="store.getAreas"
						:value-prop="'id'"
					/>
				</div>
			</div>
			<CustomInput
				type="text"
				label="Adresa"
				placeholder="Adresa"
				:input-value="newAddress"
				@update:input-value="newAddress = $event"
			/>
		</div>
		<div class="btn-wrapper">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				small
				:disabled="!areAllFieldsFilled"
				@btn-click="handleEditUser"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-user-wrapper {
	.inputs-container {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.dropdown-wrap {
			width: 100%;
		}
	}

	.btn-wrapper {
		width: 100%;
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
