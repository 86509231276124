<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const props = defineProps<{
	service: number;
	id?: number;
	nameProp?: string;
	conditionProp?: string;
	lowballProp?: string;
	actualpriceProp?: string;
	dateProp?: any;
	descriptionsProp?: string[];
	isEditing?: boolean;
}>();

const emit = defineEmits(["close-modal"]);

const apiStore = useApiStore();

const name = ref<string>("");
const condition = ref<string>("");
const lowball = ref<string>("");
const actualprice = ref<string>("");
const date = ref<any>();
const descriptions = ref<string[]>([""]);

onMounted(() => {
	if (props.nameProp) name.value = props.nameProp;
	if (props.conditionProp) condition.value = props.conditionProp;
	if (props.lowballProp) lowball.value = cleanPrice(props.lowballProp);
	if (props.actualpriceProp)
		actualprice.value = cleanPrice(props.actualpriceProp);
	// if (props.dateProp) date.value = dayjs(props.dateProp, "DD.MM.YYYY");
	if (props.dateProp) date.value = dayjs(props.dateProp, "DD.MM.YYYY");
	if (props.descriptionsProp) descriptions.value = props.descriptionsProp;
});

const handleSubmit = async () => {
	const data: any = {
		service: props.service,
		name: name.value,
		price: parseInt(lowball.value),
		oldprice: parseInt(actualprice.value),
		expiry: date.value.format("YYYY-MM-DD"),
		descriptionlist: descriptions.value,
	};

	if (props.service === 3) data.subtitle = condition.value;
	if (props.isEditing && props.id) {
		await apiStore.callPatchItemActive(props.id, data);
		emit("close-modal");
		return;
	}
	const result = await apiStore.callPostItem(data);
	if (result) emit("close-modal");
};

const addDesc = () => {
	descriptions.value.push("");
};

const deleteDesc = (index: number) => {
	descriptions.value.splice(index, 1);
};

const cleanPrice = (formattedNumber: string) => {
	const clean = formattedNumber.replaceAll(".", "").replace(",", ".");
	// return parseFloat(clean);
	return clean;
};
</script>
<template>
	<div class="edit-promo-wrapper">
		<div class="input-container">
			<CustomInput
				type="text"
				:label="`Naziv ${service === 2 ? 'promocije' : 'paketa'}`"
				:placeholder="`Naziv ${service === 2 ? 'promocije' : 'paketa'}`"
				:input-value="name"
				@update:input-value="name = $event"
			/>
			<CustomInput
				v-if="service === 3"
				type="text"
				label="Uslov"
				placeholder="Uslov"
				:input-value="condition"
				@update:input-value="condition = $event"
			/>
			<CustomInput
				type="number"
				label="Snižena cena"
				placeholder="Snižena cena"
				:input-value="lowball"
				@update:input-value="lowball = $event"
			/>
			<CustomInput
				type="number"
				label="Prvobitna cena"
				placeholder="Prvobitna cena"
				:input-value="actualprice"
				@update:input-value="actualprice = $event"
			/>
			<!-- <CustomDatepicker v-model="date" label="Trajanje promocije" /> -->
			<ModernDatepicker
				:label="`Trajanje ${service === 2 ? 'promocije' : 'paketa'}`"
				:placeholder="`Trajanje ${service === 2 ? 'promocije' : 'paketa'}`"
				:input-value="date"
				@update:model-value="date = $event"
			/>
			<div
				v-for="(desc, index) of descriptions"
				:key="index"
				class="description-wrap"
			>
				<CustomInput
					type="textarea"
					:label="`Opis ${service === 2 ? 'promocije' : 'paketa'}`"
					:placeholder="`Opis ${service === 2 ? 'promocije' : 'paketa'}`"
					:input-value="desc"
					@update:input-value="descriptions[index] = $event"
				/>
				<div v-if="index > 0" class="close" @click="deleteDesc(index)">
					<img :src="images.deleteBlack" alt="" />
				</div>
			</div>
			<div class="add-btn" @click="addDesc">
				<div class="add-img">
					<img :src="images.addImg" alt="" />
				</div>
				<span>Dodaj opis</span>
			</div>
		</div>
		<div class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				small
				@btn-click="handleSubmit"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-promo-wrapper {
	margin-top: 30px;
	max-height: 790px;
	overflow: hidden;

	.input-container {
		max-height: 700px;
		overflow-y: auto;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		display: flex;
		flex-direction: column;
		gap: 10px;

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}

		.description-wrap {
			position: relative;

			.close {
				top: 0;
				right: 0;
				position: absolute;
				cursor: pointer;
			}
		}

		.add-btn {
			display: flex;
			align-items: center;
			cursor: pointer;

			.add-img {
				height: 31px;
				width: 31px;
				border-radius: 50%;
				background: $primary-text;
				box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 50%;
					height: 50%;
				}
			}

			span {
				color: $primary-text;
				font-family: Poppins, sans-serif;
				font-size: 12px;
				font-weight: 600;
			}
		}
	}

	.btn-wrap {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
		padding-right: 10px;
		margin-bottom: 10px;
	}
}
</style>
