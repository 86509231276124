<script setup lang="ts">
import images from "@/assets/js/images";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import { vOnClickOutside } from "@vueuse/components";
import dayjs from "dayjs";
import locale_rs from "dayjs/locale/sr";

dayjs.locale(locale_rs);

const props = defineProps<{
	status: "locked" | "unlocked";
	canComplete?: boolean;
	// FIXME - this shoulddn't be any
	// FIXME 2 - this should be mandatory
	// but is optional for now until i get
	// the data structure from the backend
	// FIXME 3 - also the data in the template
	// should be handled differently based on
	// the backend data structure or reformated
	// to match the shit i wrote here
	orderId: number;
	ticketId?: number;
}>();
const emit = defineEmits(["close-modal"]);

const apiStore = useApiStore();

const order = ref<any>({});

onMounted(async () => {
	const result = await apiStore.callGetOrder(props.orderId);
	// console.log(JSON.parse(result.ord_old_ordertimes));

	order.value = result;
});

const getItemsTotal = (items: any) => {
	let total = 0;
	for (const item of items) {
		total += item.oit_price;
	}
	return total;
};

const handleClose = () => {
	emit("close-modal");
};

const formatTime = (from: string, to: string) => {
	if (to && from) return `${from.slice(0, 2)}h - ${to.slice(0, 2)}h`;
};

const completeTicket = async () => {
	// useToastStore().openToastError("Nista se desilo nije : (");
	if (props.ticketId) await apiStore.callPatchUserTicketStatus(props.ticketId);
	emit("close-modal", true);
};
</script>
<template>
	<div class="background">
		<div v-on-click-outside="handleClose" class="card-info-modal-wrapper">
			<div
				class="strip"
				:class="{ blue: status === 'locked', red: status === 'unlocked' }"
			></div>
			<div class="content-wrap">
				<div class="close-btn" @click="$emit('close-modal')">
					<img :src="images.modalClose" alt="" />
				</div>
				<div class="card-title">{{ order.ord_fullname }}</div>
				<div class="subsection-wrap">
					<div class="subsection-title">Analize</div>
					<div
						v-for="(item, index) in order.orderitems"
						:key="index"
						class="subsection-item"
					>
						<div class="item-wrap">
							<span>
								{{ item.itm_name }}
							</span>
							<span> {{ item.oit_price }} rsd </span>
						</div>
					</div>
					<hr />
					<div v-if="order.orderitems" class="total">
						<span>Ukupno</span>
						<span>{{ getItemsTotal(order.orderitems) }}</span>
					</div>
				</div>
				<div class="subsection-wrap">
					<div class="subsection-title">Lični podaci pacijenta</div>
					<div class="subsection-item">{{ order.ord_fullname }}</div>
					<div class="subsection-item">{{ order.ord_phone }}</div>
					<div class="subsection-item">{{ order.ord_email }}</div>
					<div class="subsection-item">
						{{ order.ord_address }}, {{ order.area_name }}
					</div>
				</div>
				<div class="subsection-wrap">
					<div class="subsection-title">Način plaćanja</div>
					<div class="subsection-item">
						{{ order.pyt_name }}
					</div>
				</div>
				<div v-if="order.orderitems" class="subsection-wrap">
					<div class="subsection-title">Izabrani termin</div>
					<div class="subsection-item">
						{{ dayjs(order.date).format("dddd") }},
						{{ dayjs(order.date).format("DD. MMM") }}
					</div>
					<div
						v-for="(time, index) in order.orderitems"
						:key="index"
						class="subsection-item"
					>
						{{ formatTime(time.avl_timefrom, time.avl_timeto) }}
					</div>
				</div>
				<div v-if="canComplete" class="btn-wrap">
					<CustomButton
						text="Završeno"
						:icon="images.completeImg"
						@btn-click="completeTicket"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgb(0 0 0 / 30%);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;

	.card-info-modal-wrapper {
		min-width: 236px;
		border-radius: 5px;
		background: #fff;
		box-shadow: 0 4px 40px 0 rgb(0 0 0 / 11%);
		display: flex;
		position: relative;

		.strip {
			width: 10px;
			flex: 0 0 10px;

			// height: 100%;
			max-height: 5000px;

			// background: $primary-text;
			border-radius: 5px 0 0 5px;

			&.blue {
				background: $primary-text;
			}

			&.red {
				background: $main-highlight;
			}
		}

		.content-wrap {
			padding: 10px 10px 20px 15px;
			width: 100%;
			display: flex;
			flex-direction: column;

			.close-btn {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				cursor: pointer;
			}

			.card-title {
				font-size: 14px;
				font-weight: 700;
				line-height: 20px;
				color: $primary-text;
				margin-bottom: 20px;
			}

			.subsection-wrap {
				margin-bottom: 10px;
				display: flex;
				flex-direction: column;

				.subsection-title {
					font-size: 14px;
					font-weight: 600;
					color: $primary-text;
					margin-bottom: 10px;
				}

				.subsection-item {
					font-size: 14px;
					color: $primary-text;

					.item-wrap {
						display: flex;
						justify-content: space-between;
					}
				}

				.total {
					display: flex;
					justify-content: space-between;
					color: $primary-text;
					font-size: 14px;
					font-weight: 600;
				}
			}

			.btn-wrap {
				margin-top: 20px;
			}
		}
	}
}
</style>
