<script setup lang="ts">
import images from "@/assets/js/images";
import type { TTableColumn } from "@/components/Shared/modernTable.vue";
import {
	openAddTechnitianModal,
	openAlertModal,
	openEditTechnicianModal,
} from "@/components/modals/modalDefinitions";
import { useApiStore } from "@/store/api";
import dayjs from "dayjs";

const apiStore = useApiStore();

const tableColumns = ref<TTableColumn[]>([
	{
		id: "fullname",
		label: "Ime i prezime",
	},
	{
		id: "phone",
		label: "Kontakt telefon",
	},
	{
		id: "email",
		label: "Email",
	},
	{
		id: "location",
		label: "Lokacija",
	},
	{
		id: "actions",
		label: "",
	},
]);
const technicians = ref<any[]>([]);
const filteredTechnicians = ref<any[]>([]);
const searchValue = ref<string>("");
const currentPage = ref<number>(1);
const base = 20;

watch(searchValue, async () => {
	const params = {
		search: searchValue.value,
	};
	const result = await apiStore.callGetTechnicians(params);
	filteredTechnicians.value = result;
});

onMounted(async () => {
	await getTechnicians();
});

const cutFilteredItems = computed(() => {
	return filteredTechnicians.value.slice(
		base * (currentPage.value - 1),
		base * currentPage.value,
	);
});

const getTechnicians = async () => {
	const result = await apiStore.callGetTechnicians();
	if (result) {
		technicians.value = result;
		filteredTechnicians.value = result;
	}
};

const handleAdd = () => {
	openAddTechnitianModal(getTechnicians);
};

const handleEdit = (technician: any) => {
	openEditTechnicianModal(
		{
			id: technician.id,
			fullname: technician.fullname,
			phone: technician.phone,
			email: technician.email,
			location: technician.loc_id,
		},
		getTechnicians,
	);
};

const handleDelete = (technicianID: any) => {
	openAlertModal(
		{
			title: "Brisanje tehničara",
			text: "Da li želite da obrišete izabranog tehničara?",
			action: "delete",
		},
		async () => {
			await apiStore.callDeleteUser(technicianID);
			await getTechnicians();
		},
	);
};

const changePage = (evt: any) => {
	currentPage.value = evt;
};
</script>
<template>
	<div class="technitian-page-wrap">
		<div class="input-header">
			<CustomInput
				type="search"
				placeholder="Pretraga..."
				:input-value="searchValue"
				@update:input-value="searchValue = $event"
			/>
			<CustomButton text="Dodaj" :icon="images.addImg" @btn-click="handleAdd" />
		</div>
		<div class="list-wrap">
			<ModernTable
				:columns="tableColumns"
				:rows="cutFilteredItems"
				:is-infinite-scroll="false"
				:has-border="false"
			>
				<template #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'actions'">
						<div class="btns">
							<div class="action-btn" @click="handleEdit(slotProps.row)">
								<img :src="images.editAction" alt="" />
							</div>
							<div class="action-btn" @click="handleDelete(slotProps.row.id)">
								<img :src="images.deleteAction" alt="" />
							</div>
						</div>
					</td>
				</template>
			</ModernTable>
		</div>
		<PaginationComp
			:page="currentPage"
			:pages="Math.ceil(filteredTechnicians.length / base)"
			prev-text="Nazad"
			next-text="Dalje"
			@update:page="changePage"
		/>
	</div>
</template>
<style scoped lang="scss">
.technitian-page-wrap {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.input-header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		:deep(.button-wrapper) {
			min-width: 8%;
		}

		:deep(.input-wrapper) {
			min-width: 300px;
			width: 300px;

			&:first-child {
				margin-right: 20px;
			}
		}
	}

	.list-wrap {
		width: 100%;
		height: calc(100% - 110px);
		margin-bottom: 20px;

		td {
			height: 30px;

			.btns {
				display: flex;
				align-items: center;
				justify-content: center;

				.action-btn {
					width: 15px;
					height: 15px;
					margin-right: 10px;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.chk {
				display: flex;
				align-items: center;
				justify-content: start;

				input {
					margin-left: 10px;
				}
			}
		}
	}

	:deep(.pagination-wrapper) {
		width: 100%;
		justify-content: center;
	}
}
</style>
