<script setup lang="ts">
import type { TItem } from "@/assets/js/types";

const props = defineProps<{
	data: any[];
	labelPart?: string;
	preSelectedItem?: number;
	isInCard?: boolean;
}>();
const emit = defineEmits(["selectItem"]);

const selectedItem = ref<number>(0);

onMounted(() => {
	if (props.preSelectedItem) {
		selectedItem.value = props.preSelectedItem;
	}
});

const handleSelectItem = (element: TItem) => {
	selectedItem.value = element.id;
	emit("selectItem", element);
};
</script>
<template>
	<div class="list-wrapper">
		<div v-if="labelPart" class="label-wrap">Postojeće {{ labelPart }}</div>
		<div class="options-wrap" :class="{ 'card-list': isInCard }">
			<div
				v-for="item of data"
				:key="item.id"
				class="item-wrap"
				:class="{ active: item.id === selectedItem }"
				@click="handleSelectItem(item)"
			>
				<div class="highlight"></div>
				<span>{{ item.name }}</span>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.list-wrapper {
	width: 100%;
	height: 100%;

	.label-wrap {
		color: #949bad;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 6px;
	}

	.options-wrap {
		height: 100%;
		max-height: 200px;
		overflow: hidden auto;

		&::-webkit-scrollbar,
		&::-webkit-scrollbar-thumb,
		&::-webkit-scrollbar-track {
			width: 6px;
			border: none;
			background: transparent;
		}

		&::-webkit-scrollbar-button,
		&::-webkit-scrollbar-track-piece,
		&::-webkit-scrollbar-corner,
		&::-webkit-resizer {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #c0c0c0;
		}

		&::-webkit-scrollbar-track {
			background-image: url("@/assets/image/scrolltrack.png");
			background-repeat: repeat-y;
			background-size: contain;
		}

		.item-wrap {
			width: calc(100% - 6px);
			height: 34px;
			color: #4a4e66;
			font-size: 13px;
			font-weight: 400;
			line-height: 15px; /* 115.385% */
			// margin-right: 6px;
			display: flex;
			align-items: center;
			cursor: pointer;
		}

		.highlight {
			height: 100%;
			width: 2px;
			border-radius: 1px 0 0 1px;
			margin-right: 23px;
			background: transparent;
		}

		.active {
			border-radius: 1px;

			// background: #ecf4ff;
			background: transparent;
			box-shadow: 0 1px 3px 0 #dfdfe8;
			color: $main-blue;
			font-size: 13px;
			font-weight: 600;
			line-height: 15px; /* 115.385% */

			.highlight {
				background: $main-blue;
			}
		}

		&.card-list {
			max-height: 100px;
		}
	}
}
</style>
