<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";
import { useInfiniteScroll } from "@vueuse/core";

const store = useDefaultStore();
const apiStore = useApiStore();

const searchInput = ref<string>("");
const systemNotifications = ref<any[]>([]);
const notifications = ref<HTMLElement | null>(null);
const hack = ref<boolean>(true);
const currentPage = ref<number>(1);
const shouldLoadMore = ref<boolean>(false);

onMounted(async () => {
	await init();
});

useInfiniteScroll(
	notifications,
	async () => {
		if (hack.value) {
			hack.value = false;
			return;
		}
		currentPage.value++;
		await getNextSystemNotifications();
	},
	{
		distance: 15,
		interval: 500,
		canLoadMore: () => shouldLoadMore.value,
	},
);

const init = async () => {
	await getSystemNotifications();
};

const getSystemNotifications = async () => {
	const result = await apiStore.callGetSystemNotifications(1);
	systemNotifications.value = result;
	shouldLoadMore.value = result.length > 0;
};

const getNextSystemNotifications = async () => {
	const result = await apiStore.callGetSystemNotifications(currentPage.value);
	systemNotifications.value = [...systemNotifications.value, ...result];
	shouldLoadMore.value = result.length > 0;
};

const handleMarkAllAsRead = async () => {
	await apiStore.callPostMarkAllAsRead(
		store.getPermissions["can-use-admin-panel"] ? [2] : [3, 4],
	);
	await apiStore.callGetUnseenNotificationCount("2,3,4");
	await init();
};
</script>
<template>
	<div class="system-notifications-page-wrapper">
		<div class="inputs-header">
			<!-- <CustomInput
				type="search"
				placeholder="Pretraga"
				:input-value="searchInput"
				@update:input-value="searchInput = $event"
			/> -->
			<button class="custom-button" @click="handleMarkAllAsRead">
				Označi sve kao pročitano
			</button>
		</div>
		<div class="content-wrap">
			<div ref="notifications" class="notifications-list">
				<SystemNotificationCard
					v-for="(notification, index) in systemNotifications"
					:key="index"
					:data="notification"
				/>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.system-notifications-page-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.inputs-header {
		margin-bottom: 20px;
		width: 286px;

		.custom-button {
			width: 150px;
			height: 48px;
			border-radius: 20px;
			border-color: transparent;
			background: #fff;
			outline: none;
			box-shadow: 3px 4px 10px rgb(0 0 0 / 15%);
			font-weight: bold;
			font-size: 15px;
			cursor: pointer;
		}
	}

	.content-wrap {
		width: 100%;
		height: 100%;
		display: flex;

		.notifications-list {
			width: 60%;
			display: flex;
			flex-direction: column;
			padding-right: 5px;
			max-height: 740px;
			overflow: hidden auto;

			&::-webkit-scrollbar,
			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-track {
				width: 6px;
				border: none;
				background: transparent;
			}

			&::-webkit-scrollbar-button,
			&::-webkit-scrollbar-track-piece,
			&::-webkit-scrollbar-corner,
			&::-webkit-resizer {
				display: none;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #c0c0c0;
			}

			&::-webkit-scrollbar-track {
				background-image: url("@/assets/image/scrolltrack.png");
				background-repeat: repeat-y;
				background-size: contain;
			}
		}
	}
}
</style>
