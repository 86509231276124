<script setup lang="ts">
import images from "@/assets/js/images";
import type { TAvailabilitiesParams } from "@/assets/js/types";
import { useApiStore } from "@/store/api";

const props = defineProps<{
	name: string;
	serviceName: string;
	areaID: number;
	orderID: number;
}>();
const emit = defineEmits(["close-modal"]);

const apiStore = useApiStore();

const selectedDate = ref<any>();
const selectedSlot = ref<any>({
	id: 0,
	label: "",
});
const slotOptions = ref<any[]>([]);

watch(selectedDate, async () => {
	await getAvailabilities();
});

const getAvailabilities = async () => {
	const paramObj: TAvailabilitiesParams = {
		defaultTimes: false,
		dateFrom: selectedDate.value.format("YYYY-MM-DD"),
		dateTo: selectedDate.value.format("YYYY-MM-DD"),
		service: 4,
		area: props.areaID,
	};

	const result = await apiStore.callGetAvailabilities(paramObj, false);

	for (const avl of result) {
		if (avl.maxcapacity - avl.usedcapacity > 0) {
			slotOptions.value.push({
				id: avl.avl_id,
				label: avl.from.slice(0, 2) + " - " + avl.to.slice(0, 2),
			});
		}
	}
	console.log(slotOptions.value);
};

const handleClick = async () => {
	console.log("clicked");
	await apiStore.callPatchOrderAvailability(props.orderID, {
		availabilities: [selectedSlot.value.id],
	});
	emit("close-modal");

	// await getAvailabilities();
	// const audio = new Audio(
	// 	// "https://944z121.mars-e1.mars-hosting.com/vizim-sound.mp3",
	// 	// "../../assets/audio/vizim-sound.mp3",
	// 	// FIXME: make this dynamic
	// 	"https://dev.aplikacija.vizim.co.rs/api/assets/front/audio/vizim-notification-sound.mp3",
	// );
	// await audio.play();
};
</script>
<template>
	<div class="edit-package-wrapper">
		<div class="info-section">
			<div class="info-title">Ime i prezime</div>
			<div class="info-content">
				{{ name }}
			</div>
		</div>
		<div class="info-section">
			<div class="info-title">Naziv pregleda</div>
			<div class="info-content">
				{{ serviceName }}
			</div>
		</div>
		<div class="date-wrap">
			<ModernDatepicker
				label="Datum rezervacije"
				placeholder="Datum"
				:input-value="selectedDate"
				@update:model-value="selectedDate = $event"
			/>
		</div>
		<div class="dropdown-wrap">
			<div class="dropdown-label">
				<span>Termin rezervacije</span>
			</div>
			<div class="dropdown">
				<CustomDropdown
					v-model="selectedSlot"
					:label="'label'"
					:placeholder="'Odaberite termin'"
					:options="slotOptions"
					:value-prop="'id'"
				/>
			</div>
		</div>
		<div class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				:disabled="selectedSlot.id === 0"
				small
				@btn-click="handleClick"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.edit-package-wrapper {
	width: 286px;
	display: flex;
	flex-direction: column;
	padding-top: 20px;

	> div {
		margin-bottom: 25px;
	}

	.info-section {
		.info-title {
			font-size: 14px;
			color: $subtitle-text;
			margin-bottom: 10px;
		}

		.info-content {
			font-size: 14px;
			color: $primary-text;
		}
	}

	.date-wrap {
		&:deep(.datepicker-wrapper) {
			width: 100%;

			.datepicker-wrap {
				width: 100%;
			}
		}
	}

	.dropdown-wrap {
		width: 100%;
	}

	.btn-wrap {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 0;
	}
}
</style>
