<script setup lang="ts">
import images from "@/assets/js/images";
import router from "@/router";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();
const route = useRoute();

onMounted(async () => {
	// NOTE - a fix can be found in the ChangePasswordPage.vue file
	// @ts-expect-error
	await apiStore.callVerifyEmail({ t: route.params.id as string });
});
</script>
<template>
	<div class="change-password-wrapper">
		<div class="change-password-modal">
			<div class="head-logo">
				<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
			</div>
			<div class="change-password-title">Verifikacija emaila</div>
			<div class="change-password-subtitle">
				Email je uspešno verifikovan! <br />
				Možete se ulogovati putem mobilne aplikacije.
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.change-password-wrapper {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background:
		no-repeat url("@/assets/image/login-bg.png"),
		lightgray 50%;
	background-size: cover;

	.change-password-modal {
		height: 400px;
		width: 380px;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 0 40px 0 rgb(118 148 255 / 5%);
		padding: 70px 50px;
		display: flex;
		flex-direction: column;

		// justify-content: center;
		align-items: center;

		.change-password-title {
			color: $primary-text;
			font-size: 18px;
			font-weight: 600;
			margin: 40px 0;
		}

		.change-password-subtitle {
			color: $primary-text;
			font-family: Poppins, sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 26px;
			text-align: center;
		}
	}
}
</style>
