<script setup lang="ts">
import images from "@/assets/js/images";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

defineEmits(["edit", "delete"]);
const props = withDefaults(
	defineProps<{
		title: string;
		body: string;
		isDraft: boolean;
		date: string;
	}>(),
	{
		isDraft: false,
	},
);

const formatedDate = computed(
	() =>
		dayjs.utc(props.date).tz("Europe/Belgrade").format("HH:mm") +
		"h " +
		dayjs.utc(props.date).tz("Europe/Belgrade").format("DD.MM.YYYY"),
);
</script>
<template>
	<div class="notification-card-wrapper">
		<div class="notification-header">
			<div class="notification-title">
				<span>{{ title }}</span>
			</div>
			<div class="notification-delete" @click="$emit('delete')">
				<img :src="images.deleteAction" alt="" />
			</div>
		</div>
		<div class="notification-body">
			<span>{{ body }}</span>
		</div>
		<div class="notification-footer">
			<div class="edit-notification">
				<CustomButton
					v-if="isDraft"
					text="Izmeni"
					:icon="images.editImg"
					:small="true"
					@btn-click="$emit('edit')"
				/>
			</div>
			<div class="notification-date">
				<span>{{ formatedDate }}</span>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.notification-card-wrapper {
	width: 640px;
	min-height: 110px;
	padding: 14px 15px 10px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.notification-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 5px;

		.notification-title {
			font-size: 15px;
			font-weight: 500;
			color: $primary-text;
		}

		.notification-delete {
			width: 8px;
			height: 8px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.notification-body {
		width: 100%;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 13px;
		color: $primary-text;
		letter-spacing: 0.39px;
		opacity: 0.5;
	}

	.notification-footer {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: end;

		.edit-notification {
			margin-top: 23px;
		}

		.notification-date {
			color: $primary-text;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 0.33px;
		}
	}
}
</style>
