import { useToast, TYPE } from "vue-toastification";
import { defineStore } from "pinia";
import ToastComp from "@/components/Shared/toast/ToastComp.vue";
import type { ToastComponent } from "vue-toastification/dist/types/types";

type TSuccessOpt = Parameters<typeof toastInstance.success>[1];
type TErrorOpt = Parameters<typeof toastInstance.error>[1];
type TInfoOpt = Parameters<typeof toastInstance.info>[1];

const toastInstance = useToast();
export const useToastStore = defineStore("toasts", {
	state: () => ({}),
	getters: {},
	actions: {
		openToastCore<T>(msg: string, type: TYPE, opt: T) {
			switch (type) {
				case "error": {
					toastInstance.error(
						{
							component: ToastComp,
							props: {
								type,
								msg,
								...opt,
							},
						} as ToastComponent,
						opt as TErrorOpt,
					);

					break;
				}
				case "success": {
					toastInstance.success(
						{
							component: ToastComp,
							props: {
								type,
								msg,
								...opt,
							},
						} as ToastComponent,
						opt as TSuccessOpt,
					);

					break;
				}
				case "info": {
					toastInstance.info(
						{
							component: ToastComp,
							props: {
								type,
								msg,
								...opt,
							},
						} as ToastComponent,
						opt as TInfoOpt,
					);

					break;
				}
				// No default
			}
		},
		openToastSuccess(msg: string, opt: TSuccessOpt = {}) {
			this.openToastCore<TSuccessOpt>(msg, TYPE.SUCCESS, opt);
		},
		openToastError(msg: string, opt: TErrorOpt = {}) {
			this.openToastCore<TErrorOpt>(msg, TYPE.ERROR, opt);
		},
		openToastInfo(msg: string, opt: TInfoOpt = {}) {
			this.openToastCore<TInfoOpt>(msg, TYPE.INFO, opt);
		},
	},
});
