<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();

defineProps<{
	title: string;
	icon: string;
	routeTo: string;
	isActive: boolean;
	showDot?: boolean;
}>();

const goTo = (route: string) => {
	void router.push(route);
};
</script>
<template>
	<div class="tab-wrapper" :class="{ active: isActive }" @click="goTo(routeTo)">
		<div v-if="isActive" class="active-line"></div>
		<div class="icon-wrap">
			<img :src="icon" alt="" />
			<div v-if="showDot" class="dot"></div>
		</div>
		<div class="title-wrap">
			<span>{{ title }}</span>
		</div>
	</div>
</template>
<style scoped lang="scss">
.tab-wrapper {
	height: 45px;
	width: 140px;
	display: flex;
	align-items: center;
	cursor: pointer;

	.active-line {
		height: 100%;
		width: 3px;
		background: $main-blue;
		margin-right: -3px;
	}

	.icon-wrap {
		margin: 0 25px 0 8px;
		height: 20px;
		width: 20px;
		filter: invert(12%) sepia(9%) saturate(6309%) hue-rotate(201deg)
			brightness(97%) contrast(93%);

		img {
			height: 20px;
			width: 20px;
		}

		.dot {
			height: 12px;
			width: 12px;
			background: red;
			border-radius: 50%;
			position: absolute;
			top: -6px;
			right: -6px;
			filter: hue-rotate(159deg) brightness(103%) contrast(107%);
		}
	}

	.title-wrap {
		color: $alt-dark;
		font-size: 12px;
		font-weight: 600;
	}
}

.active {
	// border-left: 4px solid #095eff;
	border-radius: 1px;
	background: #ecf4ff;
	box-shadow: 0 1px 3px 0 #dfdfe8;

	// ik this is stupid but i WILL NOT add inline svg
	// that shit gay af
	.icon-wrap {
		filter: invert(21%) sepia(99%) saturate(2630%) hue-rotate(218deg)
			brightness(103%) contrast(104%);
	}

	.title-wrap {
		color: $main-blue;
	}
}
</style>
