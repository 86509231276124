<script setup lang="ts">
import type { TAvailabilitiesParams, TLooseObject } from "@/assets/js/types";
import { openAlertModal } from "@/components/modals/modalDefinitions";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import dayjs from "dayjs";
import draggable from "vuedraggable";

const apiStore = useApiStore();

const selectedDate = ref<any>(dayjs());
const selectedTechnitian = ref<any>("");
const availabilities = ref<any[]>([]);
const availableTechnicians = ref<any[]>([]);
const didCallDefaults = ref<boolean>(false);
const showInfo = ref<boolean>(false);
const orderToShow = ref<any>({});

onMounted(async () => {
	await init();
});

const showPauseButton = computed(() => {
	for (const el of availabilities.value) {
		for (const el2 of el.usertickets) {
			if (el2.utt_id === 1) {
				return false;
			}
		}
	}
	return true;
});

watch(selectedTechnitian, async (newVal) => {
	await getAvailabilities();
});

watch(selectedDate, async () => {
	if (selectedTechnitian.value) {
		await getAvailabilities();
	}
});

const init = async () => {
	const result = await apiStore.callGetTechnicians();
	availableTechnicians.value = result;
};

const getAvailabilities = async (groupID?: number) => {
	const paramObj: TLooseObject = {
		defaultTimes: false,
		dateFrom: selectedDate.value.format("YYYY-MM-DD"),
		dateTo: selectedDate.value.format("YYYY-MM-DD"),
		// service: returnIDFromLabel(selectedService.value, store.getServices),
		user: selectedTechnitian.value.id,
	};

	let result = await apiStore.callGetAvailabilities(paramObj, false);

	if (result.length === 0) {
		paramObj.defaultTimes = true;
		didCallDefaults.value = true;
		delete paramObj.user;
		result = await apiStore.callGetAvailabilities(paramObj, false);
	} else {
		didCallDefaults.value = false;
	}

	availabilities.value = result;
};

const checkMove = (evt: any) => {
	return evt.from === evt.to;
	// if (
	// 	!showDeliveryButton(destination.usertickets) &&
	// 	evt.draggedContext.element.utt_id === 2
	// ) {
	// 	return false;
	// }

	// return true;
};

const removeTicket = async (IDofuserticket: number) => {
	openAlertModal(
		{
			title: "Brisanje termina",
			text: "Da li želite da obrišete izabrani termin?",
			action: "delete",
		},
		handleRemoveUserTicket,
		IDofuserticket,
	);
};

const removeSlot = async (indexOfAvailability: number) => {
	// TODO - change the function that is called
	// when deleting pause and delivery
	openAlertModal(
		{
			title: "Brisanje termina",
			text: "Da li želite da obrišete izabrani termin?",
			action: "delete",
		},
		handleRemoveSlot,
		indexOfAvailability,
	);
};

const handleRemoveSlot = async (indexOfAvailability: number) => {
	const paramObj = {
		datetimes: [
			{
				date: selectedDate.value.format("YYYY-MM-DD"),
				from: availabilities.value[indexOfAvailability].from,
				to: availabilities.value[indexOfAvailability].to,
				maxcapacity: availabilities.value[indexOfAvailability].maxcapacity - 1,
			},
		],
	};
	await apiStore.callPatchAvailability(
		availabilities.value[indexOfAvailability].avl_id,
		paramObj,
	);
	await getAvailabilities();
};

const handleRemoveUserTicket = async (usetticketID: number) => {
	await apiStore.callDeleteUserTicket(usetticketID);
	await getAvailabilities();
};

const addSlot = async (time: any) => {
	await (didCallDefaults.value ? addSlotAndInitDay(time) : handleSlotAdd(time));

	await getAvailabilities();
};

const addPause = async (indexOfAvailability: any) => {
	const paramObj = {
		user: selectedTechnitian.value.id,
		usertickettype: 1,
		availability: availabilities.value[indexOfAvailability].avl_id,
	};

	await apiStore.callPostUserTicketType(paramObj);
	await getAvailabilities();
};

const addDelivery = async (indexOfAvailability: any) => {
	if (
		availabilities.value[indexOfAvailability].usertickets.length + 1 >
		availabilities.value[indexOfAvailability].maxcapacity
	) {
		return;
	}
	const paramObj = {
		user: selectedTechnitian.value.id,
		usertickettype: 2,
		availability: availabilities.value[indexOfAvailability].avl_id,
	};

	await apiStore.callPostUserTicketType(paramObj);
	await getAvailabilities();
};

const addSlotAndInitDay = async (indexOfItem: number) => {
	const tempArray: any[] = [];
	const paramObj = {
		user: selectedTechnitian.value.id,
		datetimes: tempArray,
	};

	for (const el of availabilities.value) {
		paramObj.datetimes.push({
			date: selectedDate.value.format("YYYY-MM-DD"),
			from: el.from,
			to: el.to,
			maxcapacity: 0,
		});
	}

	paramObj.datetimes[indexOfItem].maxcapacity++;

	await apiStore.getCancer(paramObj);
};

const handleSlotAdd = async (indexOfAvailability: number) => {
	const paramObj = {
		datetimes: [
			{
				date: selectedDate.value.format("YYYY-MM-DD"),
				from: availabilities.value[indexOfAvailability].from,
				to: availabilities.value[indexOfAvailability].to,
				maxcapacity: availabilities.value[indexOfAvailability].maxcapacity + 1,
			},
		],
	};

	await apiStore.callPatchAvailability(
		availabilities.value[indexOfAvailability].avl_id,
		paramObj,
	);
};

const showDeliveryButton = (usertickets: any[]) => {
	for (const el of usertickets) {
		if (el.utt_id === 2) {
			return false;
		}
	}
	return true;
};

const updateOrder = async (evt: any, index: number) => {
	if (evt.moved) {
		const arrToSend: any[] = [];
		for (let i = 0; i < availabilities.value[index].usertickets.length; i++) {
			arrToSend.push({
				userticket: availabilities.value[index].usertickets[i].utk_id,
				sortingorder: i,
			});
		}
		await apiStore.callUpdateSortOrder({
			usertickets: arrToSend,
		});
		await getAvailabilities();
	}
};

const toggleInfo = (order: any) => {
	orderToShow.value = order;
	showInfo.value = true;
};
</script>
<template>
	<div class="activities-page-wrapper">
		<CardInfoModal
			v-if="showInfo"
			status="locked"
			:order-id="orderToShow.ord_id"
			@close-modal="showInfo = false"
		/>
		<div class="input-header">
			<div class="dropdown-wrap">
				<div class="dropdown-label">
					<span>Tehničar</span>
				</div>
				<div class="dropdown">
					<CustomDropdown
						v-model="selectedTechnitian"
						label="fullname"
						placeholder="Tehničar"
						:options="availableTechnicians"
						:value-prop="'id'"
					/>
				</div>
			</div>
			<!-- <CustomDatepicker v-model="selectedDate" label="Datum" /> -->
			<ModernDatepicker
				label="Datum"
				placeholder="Datum"
				:input-value="selectedDate"
				@update:model-value="selectedDate = $event"
			/>
		</div>
		<div class="table-wrap">
			<div
				v-for="(availability, index) in availabilities"
				:key="index"
				class="drop-zone-wrap"
			>
				<span>{{
					availability.from.slice(0, 2) +
					"-" +
					availability.to.slice(0, 2) +
					"h"
				}}</span>
				<!-- TODO - change :list to usertickets and add type BREAK/DELIVERY to cards -->
				<draggable
					:id="index"
					class="drop-zone"
					:list="availability.usertickets"
					:move="checkMove"
					group="slots"
					item-key="ord_id"
					@change="updateOrder($event, index)"
				>
					<!-- @end="checkEnd" -->
					<template #item="{ element }">
						<SlotCard
							:name="element.ord_fullname"
							:phone="element.ord_phone"
							:locked="element.utt_id === 3"
							:card-type="element.utt_id"
							:show-btn="false"
							:service-i-d="2"
							:card-tag="element.orderitems[0]?.itm_tag"
							@open-modal="toggleInfo(element)"
							@delete="removeTicket(element.utk_id)"
						/>
					</template>
					<template #footer>
						<!-- TOOD - also change .orders to .usertickets -->
						<SlotCard
							v-for="jindex in availability.maxcapacity -
							availability.usertickets.length"
							:key="jindex"
							free
							@delete="removeSlot(index)"
						/>
						<div class="btn-wrap">
							<div class="add-time-slot-btn" @click="addSlot(index)">
								<img src="@/assets/image/btn-icon/addBlue.svg" alt="" />
								<span>Otvoriti termin</span>
							</div>
							<!-- TOOD - add logic here so that if there is a break here -->
							<!-- remove the break button in the whole page -->
							<!-- remove the delivery button for the row only -->
							<div
								v-if="
									!didCallDefaults &&
									availability.usertickets.length < availability.maxcapacity
								"
								class="add-other-slot-btn-wrap"
							>
								<div
									v-if="showPauseButton"
									class="option"
									@click="addPause(index)"
								>
									<img src="@/assets/image/btn-icon/addBlue.svg" alt="" />
									<span>Pauza</span>
								</div>
								<div
									v-if="showDeliveryButton(availability.usertickets)"
									class="option"
									@click="addDelivery(index)"
								>
									<img src="@/assets/image/btn-icon/addBlue.svg" alt="" />
									<span>Isporuka</span>
								</div>
							</div>
						</div>
					</template>
				</draggable>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.activities-page-wrapper {
	width: 100%;
	height: 100%;
	padding: 17px 68px 35px 27px;

	.input-header {
		display: flex;
		gap: 15px;
		margin-bottom: 10px;

		.dropdown-wrap {
			width: 300px;

			.dropdown-label {
				margin-right: 1rem;
			}
		}
	}

	.table-wrap {
		width: 100%;
		max-height: 718px;
		overflow-y: auto;

		// height: 100%;

		.drop-zone-wrap {
			min-height: 160px;
			width: 100%;
			border-top: 1px solid #dfdfe8;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 40px 0;

			&:last-child {
				border-bottom: 1px solid #dfdfe8;
			}

			span {
				color: $alt-dark;
				font-family: Archivo, sans-serif;
				font-size: 15px;
				font-weight: 500;
				margin: 0 40px 0 25px;
				min-width: 50px;
			}

			.drop-zone {
				display: flex;
				flex-wrap: wrap;

				.btn-wrap {
					display: flex;
					flex-direction: column;

					// min-height: 90px;

					.add-time-slot-btn {
						width: 235px;
						height: 43px;
						border-radius: 5px;
						background-color: #fff;
						box-shadow: 0 4px 48px 0 rgb(0 0 0 / 11%);
						display: flex;
						align-items: center;
						padding-left: 25px;
						cursor: pointer;

						span {
							color: $alt-dark;
							font-family: Archivo, sans-serif;
							font-size: 15px;
							font-weight: 500;
							margin-left: 10px;
						}

						&:first-child {
							margin-bottom: 4px;
						}
					}

					.add-other-slot-btn-wrap {
						width: 235px;
						display: flex;
						justify-content: space-between;

						.option {
							height: 43px;
							border-radius: 5px;
							background: #fff;
							box-shadow: 0 4px 48px 0 rgb(0 0 0 / 11%);
							cursor: pointer;

							// padding: 0 10px;
							padding-left: 15px;
							display: flex;
							align-items: center;

							span {
								color: $alt-dark;
								font-family: Archivo, sans-serif;
								font-size: 15px;
								font-weight: 500;
								margin-right: 20px;
								margin-left: 10px;
							}

							&:only-of-type {
								width: 100%;
								padding-left: 25px;
							}
						}
					}
				}

				:deep(.slot-wrapper) {
					margin-bottom: 15px;
					margin-right: 15px;
				}
			}

			.test-class {
				display: flex;
				justify-content: space-around;
				align-items: center;
				width: 500px;
				height: 100px;
				background-color: red;

				.test-element {
					height: 50px;
					width: 60px;
					background: yellow;
				}
			}
		}
	}
}
</style>
