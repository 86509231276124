<script setup lang="ts">
import images from "@/assets/js/images";
import router from "@/router";
import { useApiStore } from "@/store/api";

const apiStore = useApiStore();
const route = useRoute();

const password = ref<string>("");

// interface RouteParams {
//   id: string;
// }
// const routes: Array<RouteRecordRaw> = [
//   // your routes here
// ]
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
// export default router
// const id = (route.params as RouteParams).id
// NOTE - this is the fix for the error below

const handleChangePassword = async () => {
	await apiStore.callSubmitResetPassword({
		// @ts-expect-error
		t: route.params.id as string,
		password: password.value,
	});
};
</script>
<template>
	<div class="change-password-wrapper">
		<div class="change-password-modal">
			<div class="head-logo">
				<img src="@/assets/image/vizim-logo-svg.svg" alt="" />
			</div>
			<div class="change-password-title">Nova lozinka</div>
			<div class="change-password-subtitle">Unesite novu lozinku</div>
			<div class="inputs-wrap">
				<CustomInput
					type="password"
					label="Nova lozinka"
					placeholder="Lozinka"
					:input-value="password"
					@update:input-value="password = $event"
				/>
			</div>
			<div class="btn-wrap">
				<CustomButton
					text="Sačuvaj"
					:icon="images.checkSmall"
					@btn-click="handleChangePassword"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.change-password-wrapper {
	min-height: 100vh;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background:
		no-repeat url("@/assets/image/login-bg.png"),
		lightgray 50%;
	background-size: cover;

	.change-password-modal {
		height: 600px;
		width: 380px;
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 0 40px 0 rgb(118 148 255 / 5%);
		padding: 70px 50px;
		display: flex;
		flex-direction: column;

		// justify-content: center;
		align-items: center;

		.head-logo {
			width: 140px;

			img {
				width: 100%;
			}
		}

		.change-password-title {
			color: $primary-text;
			font-size: 18px;
			font-weight: 600;
			margin: 40px 0;
		}

		.change-password-subtitle {
			color: $primary-text;
			font-family: Poppins, sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 26px;
		}

		.inputs-wrap {
			width: 100%;
			margin: 40px 0;
			margin-bottom: 120px;
		}

		.login-wrap {
			margin-top: 40px;
			color: $primary-text;
			font-size: 14px;
			font-family: Poppins, sans-serif;
			font-weight: 500;

			.login-link {
				font-size: 14px;
				font-family: Poppins, sans-serif;
				font-weight: 500;
				color: $main-highlight;
				text-decoration: none;
			}
		}
	}
}
</style>
