<script setup lang="ts">
import { openAlertModal } from "@/components/modals/modalDefinitions";
import { useDefaultStore } from "@/store";
import { useApiStore } from "@/store/api";

const route: { name?: string } = useRoute();
const store = useDefaultStore();
const apiStore = useApiStore();

const showUnread = ref(false);

onMounted(async () => {
	await getInfo();
	await getUnseen();
	setInterval(
		() => {
			void getUnseen();
		},
		1000 * 60 * 3,
	);
});

const getUnseen = async () => {
	const result = await apiStore.callGetUnseenNotificationCount("2,3,4");
};

const getInfo = async () => {
	await apiStore.callGetAreas();
	await apiStore.callGetServices();
	await apiStore.callGetAllGroups();
	await apiStore.callGetUserStatuses();
	await apiStore.callGetUserTicketTypes();
	await apiStore.callGetTechnicianLocations();
	await apiStore.callGetPaymentTypes();
};

const getActive = computed(() => {
	// return route.name;
	return route.name as string;
});

const getTitle = computed(() => {
	switch (route.name) {
		case "SchedulePage": {
			return "Termini";
		}
		case "ServicesPage": {
			return "Usluge";
		}
		case "UsersPage": {
			return "Korisnici";
		}
		case "AdminPage": {
			return "Admin";
		}
		case "TechniciansPage": {
			return "Tehničari";
		}
		case "ActivitiesPage": {
			return "Aktivnosti tehničara";
		}
		case "ReportsPage": {
			return "Izveštaji";
		}
		case "NotificationsPage": {
			return "Notifikacije";
		}
		case "PackagesPage": {
			return "Paketi";
		}
		case "SystemNotificationsPage": {
			return "Sistemske notifikacije";
		}
		case "AtHomeServicesPage": {
			return "Pregledi u Vašem domu";
		}
		default: {
			// return "oh no, its bwoken :3";
			return "Dashboard";
		}
	}
});
</script>

<template>
	<div class="home-wrapper">
		<SidebarComp
			v-if="store.getPermissions['can-use-admin-panel']"
			:active="getActive"
			:has-unread="store.getHasUnreadMessages"
		/>
		<div class="right-wrap">
			<HeaderComp
				:page-title="getTitle"
				:show-logo="!store.getPermissions['can-use-admin-panel']"
			/>
			<div class="main-content">
				<router-view />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.home-wrapper {
	display: flex;

	.right-wrap {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;

		.main-content {
			height: 100%;
		}
	}
}
</style>
