<script setup lang="ts">
import images from "@/assets/js/images";
import { type TLooseObject, type TItem } from "@/assets/js/types";
import { useApiStore } from "@/store/api";
import { useToastStore } from "@/store/toast";
import { openAlertModal } from "../modals/modalDefinitions";

const props = defineProps<{
	listData: TItem[];
}>();
const emit = defineEmits(["close-modal"]);

const apiStore = useApiStore();

const nameValue = ref<string>("");
const newNameValue = ref<string>("");
const listOption = ref<TLooseObject>({ id: 0, name: "" });
const isEditing = ref<boolean>(false);

const hasNameValue = computed(() => nameValue.value.length > 0);
const hasListOption = computed(() => listOption.value.id !== 0);
const hasNewNameValue = computed(() => newNameValue.value.length > 0);

const handleListSelect = (ev: any) => {
	listOption.value = ev;
	nameValue.value = ev.name;
};

const handleAdd = async () => {
	if (props.listData.some((item: TItem) => item.name === nameValue.value)) {
		// TODO - better error handling
		useToastStore().openToastError("Grupa sa tim imenom već postoji!");
		return;
	} else {
		// service is 1 bcs thats the lab
		await apiStore.callPostItemGroup({ name: nameValue.value, service: 1 });
	}
	emit("close-modal");
};

const handleConfirm = async () => {
	if (listOption.value.id === 0) return;
	else {
		await apiStore.callPatchItemGroup(listOption.value.id, {
			name: newNameValue.value,
		});
	}
	emit("close-modal");
};

const handleDelete = async () => {
	if (listOption.value.id === 0) return;
	else {
		await apiStore.callDeleteItemGroup(listOption.value.id);
	}
	emit("close-modal");
};
</script>
<template>
	<div class="create-group-wrapper">
		<div class="left-wrap">
			<CustomInput
				type="text"
				label="Naziv grupe"
				placeholder="Unesite naziv grupe"
				:disabled="isEditing"
				:input-value="nameValue"
				@update:input-value="nameValue = $event"
			/>
			<CustomInput
				v-show="isEditing"
				type="text"
				label="Novi naziv"
				placeholder="Unesite novi naziv"
				:input-value="newNameValue"
				@update:input-value="newNameValue = $event"
			/>
			<CustomModalList
				label-part="grupe"
				:data="listData"
				@select-item="handleListSelect"
			/>
		</div>
		<div v-if="!isEditing" class="btn-wrap">
			<CustomButton
				text="Dodaj"
				:icon="images.addImg"
				:disabled="!hasNameValue"
				@btn-click="handleAdd"
			/>
			<CustomButton
				text="Promeni"
				:icon="images.editImg"
				:disabled="!hasListOption"
				@btn-click="isEditing = true"
			/>
			<CustomButton
				text="Obriši"
				:icon="images.deleteImg"
				:disabled="!hasListOption"
				@btn-click="handleDelete"
			/>
		</div>
		<div v-else class="btn-wrap">
			<CustomButton
				text="Sačuvaj"
				:icon="images.confirmImg"
				:disabled="!hasNewNameValue"
				@btn-click="handleConfirm"
			/>
		</div>
	</div>
</template>
<style scoped lang="scss">
.create-group-wrapper {
	display: flex;
	min-height: 270px;
	margin-top: 30px;

	.left-wrap {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-right: 50px;
		width: 300px;
	}

	.btn-wrap {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}
</style>
