import type { TLooseObject, TNameIDPair, TUserInfo } from "@/assets/js/types";
import { defineStore } from "pinia";

export const useDefaultStore = defineStore("main", () => {
	const user = ref<TUserInfo>({
		fullname: "",
		phone: "",
		email: "",
		address: "",
	});

	const permissions = ref<TLooseObject>({});
	const technicianLocations = ref<TNameIDPair[]>([]);
	const areas = ref<TNameIDPair[]>([]);
	const services = ref<TNameIDPair[]>([]);
	const allGroups = ref<TNameIDPair[]>([]);
	const groups = ref<TNameIDPair[]>([]);
	// FIXME - change this so it comes from the backend
	const genda = ref<Record<string, any>[]>([
		{
			id: "M",
			label: "Muski",
		},
		{
			id: "F",
			label: "Zenski",
		},
		{
			id: "U",
			label: "Neodredjeno",
		},
	]);
	const userStatuses = ref<TNameIDPair[]>([]);
	const userTicketTypes = ref<TNameIDPair[]>([]);
	const paymentTypes = ref<TNameIDPair[]>([]);
	const isLoading = ref<boolean>(false);
	const hasUnreadMessages = ref<boolean>(false);
	const hasSoundPermission = ref<boolean>(false);
	const shouldRefresh = ref<boolean>(false);

	const setShouldRefresh = (input: boolean) => {
		shouldRefresh.value = input;
	};

	const getShouldRefresh = computed(() => {
		return shouldRefresh.value;
	});

	const setHasSoundPermission = (input: boolean) => {
		hasSoundPermission.value = input;
	};

	const getHasSoundPermission = computed(() => {
		return hasSoundPermission.value;
	});

	const setHasUnreadMessages = (input: boolean) => {
		hasUnreadMessages.value = input;
	};

	const getHasUnreadMessages = computed(() => {
		return hasUnreadMessages.value;
	});

	const startLoading = () => {
		console.log("start loading");

		isLoading.value = true;
	};

	const stopLoading = () => {
		console.log("stop loading");

		isLoading.value = false;
	};

	const getIsLoading = computed(() => {
		return isLoading.value;
	});

	const setUser = (inputUser: TUserInfo) => {
		user.value = inputUser;
	};

	const setUserKey = <K extends keyof TUserInfo>(
		key: K,
		data: TUserInfo[K],
	) => {
		user.value[key] = data;
	};

	const getUser = computed((): TUserInfo => {
		return user.value;
	});

	const setAreas = (inputAreas: TNameIDPair[]) => {
		areas.value = inputAreas;
	};

	const getAreas = computed(() => {
		return areas.value;
	});

	const setServices = (inputServices: TNameIDPair[]) => {
		services.value = inputServices;
	};

	const getServices = computed(() => {
		return services.value;
	});

	const setGroups = (inputGroups: TNameIDPair[]) => {
		groups.value = inputGroups;
	};

	const getGroups = computed(() => {
		return groups.value;
	});

	const setAllGroups = (inputGroups: TNameIDPair[]) => {
		allGroups.value = inputGroups;
	};

	const getAllGroups = computed(() => {
		return allGroups.value;
	});

	const setUserStatuses = (inputStatuses: TNameIDPair[]) => {
		userStatuses.value = inputStatuses;
	};

	const getUserStatuses = computed(() => {
		return userStatuses.value;
	});

	const getGenda = computed(() => {
		return genda.value;
	});

	const setUserTicketTypes = (inputTicketTypes: TNameIDPair[]) => {
		userTicketTypes.value = inputTicketTypes;
	};

	const getUserTicketTypes = computed(() => {
		return userTicketTypes.value;
	});

	const setTechnicianLocations = (inputLocations: TNameIDPair[]) => {
		technicianLocations.value = inputLocations;
	};

	const getTechnicianLocations = computed(() => {
		return technicianLocations.value;
	});

	const setPaymentTypes = (inputPaymentTypes: TNameIDPair[]) => {
		paymentTypes.value = inputPaymentTypes;
	};

	const getPaymentTypes = computed(() => {
		return paymentTypes.value;
	});

	const setAllPermissions = (inputPermissions: TLooseObject[]) => {
		permissions.value = inputPermissions;
	};

	const getPermissions = computed(() => {
		return permissions.value;
	});

	return {
		setUser,
		setUserKey,
		getUser,
		setAreas,
		getAreas,
		setServices,
		getServices,
		setGroups,
		getGroups,
		setAllGroups,
		getAllGroups,
		setUserStatuses,
		getUserStatuses,
		// setGenda,
		// addGenda,
		getGenda,
		setUserTicketTypes,
		getUserTicketTypes,
		setTechnicianLocations,
		getTechnicianLocations,
		setPaymentTypes,
		getPaymentTypes,
		setAllPermissions,
		getPermissions,
		startLoading,
		stopLoading,
		getIsLoading,
		setHasUnreadMessages,
		getHasUnreadMessages,
		setHasSoundPermission,
		getHasSoundPermission,
		setShouldRefresh,
		getShouldRefresh,
	};
});
